import React from 'react';
import useWindowDimensions from '../utils/WindowSize';
import MobileLayout from './MobileLayout';
import Layout from './Layout';

function MainLayout({
  children,
  active,
  className,
  setMenuSelected,
  menuSelected,
  hideFooter,
  footerMain,
  mobileFooter,
}) {
  const { width, height } = useWindowDimensions();
  return (
    <>
      {width > 768 || width > height ? (
        <Layout
          children={children}
          active={active}
          className={className}
          hideFooter={hideFooter}
        />
      ) : (
        <MobileLayout />
      )}
    </>
  );
}

export default MainLayout;
