import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useCoinContextData } from '../../../context/CoinContext';
import './addAccountStyles.scss';
import { getAppByCode } from '../../../queryHooks';
import { useAppContextDetails } from '../../../context/AppContext';

const AccountHolder = () => {
  const { appCode } = useAppContextDetails();
  const {
    setAddAccountStepName,

    nameOnBankAccount,
    setNameOnBankAccount,
    bankingPhoneNumber,
    setBankingPhoneNumber,
    beneficiaryAddress,
    setBeneficiaryAddress,
  } = useCoinContextData();

  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [appColor, setAppColor] = useState('');

  useEffect(() => {
    getAppByCode(appCode).then((response) => {
      const color = response?.color_codes[0];
      console.log(color, 'ljqwehflk3wbefljk3bwef');
      setAppColor(color);
    });
  }, [appCode]);



  //   const conditionalFields = () => {
  //     if (selectedPaymentType.code === 'GPAY') {
  //       return (
  //         <div>
  //           <div>Name On Bank Account</div>
  //           <div>Phone Number</div>
  //           <div>Beneficiary Address</div>
  //         </div>
  //       );
  //     }
  //   };

  return (
    <>
      <div className="addAccountContainer">
        <div>
          <span
            className="prevStep"
            onClick={(e) => setAddAccountStepName('selectBank')}
          >
            Bank
          </span>{' '}
          {`>`}{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            Account Holder
          </span>
        </div>

        <div className="inputWrapper">
          <div className="inputSection">
            <div className="inputHeader">Name On Bank Account</div>
            <input
              placeholder="Enter Name.."
              className="inputStyle"
              value={nameOnBankAccount}
              onChange={(e) => setNameOnBankAccount(e.target.value)}
            />
          </div>
          <div className="inputSection">
            <div className="inputHeader">Phone Number</div>
            <input
              placeholder="Enter Number.."
              className="inputStyle"
              value={bankingPhoneNumber}
              onChange={(e) => setBankingPhoneNumber(e.target.value)}
            />
          </div>
          <div className="inputSection">
            <div className="inputHeader">Beneficiary Address</div>
            <input
              placeholder="Enter Address.."
              className="inputStyle"
              value={beneficiaryAddress}
              onChange={(e) => setBeneficiaryAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="buttonWrapper">
          <div onClick={(e) => setAddAccountStepName('selectBank')}>Back</div>
          <div
            onClick={(e) =>
              nameOnBankAccount && beneficiaryAddress && bankingPhoneNumber
                ? setAddAccountStepName('accountDetails')
                : null
            }
            style={{
              background: `#${appColor?.primarycolourcode}`,
              color: 'white',
              opacity:
                nameOnBankAccount && beneficiaryAddress && bankingPhoneNumber
                  ? 1
                  : 0.4,
            }}
          >
            Next Step
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountHolder;
