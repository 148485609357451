import React, { useContext } from 'react';
import VaultHeaderCryproForex from './VaultHeaderCryproForex';
import VaultHeaderBonds from './VaultHeaderBonds';
import VaultHeaderMoneyMarkets from './VaultHeaderMoneyMarkets';
import VaultHeaderShares from './VaultHeaderShares';
import VaultControls from './VaultControls';
import VaultControlBonds from './VaultControlBonds';
import VaultControlShares from './VaultControlShares';
import VaultTransactions from './VaultTransactions';
import VaultTransactionsBonds from './VaultTransactionsBonds';
import VaultTransactionsMoneyMarkets from './VaultTransactionsMoneyMarkets';
import VaultTransactionsShares from './VaultTransactionsShares';
import { VaultPageContext } from '../../../context/VaultPageContext';
import { BankContext } from '../../../context/Context';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';

export const VaultContents = () => {
  const { email } = useContext(BankContext);

  const {
    assetClass,
    appSelected,
    setAppSelected,
    vaultSelected,
    setVaultSelected,
    vaultsListFxCrypto: vaultsList,
    vaultsListFxCryptoLoading: loading,
  } = useContext(VaultPageContext);

  switch (assetClass?.name) {
    case 'moneyMarkets':
      return (
        <div className={classNames.contentArea}>
          <VaultHeaderMoneyMarkets
            email={email}
            vaultSelected={vaultSelected}
            setVaultSelected={setVaultSelected}
            loading={loading}
          />
          <VaultControls
            appSelected={appSelected}
            setAppSelected={setAppSelected}
            email={email}
          />
          <VaultTransactionsMoneyMarkets email={email} />
        </div>
      );
    case 'bonds':
      return (
        <div className={classNames.contentArea}>
          <VaultHeaderBonds
            email={email}
            vaultSelected={vaultSelected}
            setVaultSelected={setVaultSelected}
            loading={loading}
          />
          <VaultControlBonds
            appSelected={appSelected}
            setAppSelected={setAppSelected}
            email={email}
          />
          <VaultTransactionsBonds email={email} />
        </div>
      );
    case 'shares':
      return (
        <div className={classNames.contentArea}>
          <VaultHeaderShares
            email={email}
            vaultSelected={vaultSelected}
            setVaultSelected={setVaultSelected}
            loading={loading}
          />
          <VaultControlShares
            email={email}
            appSelected={appSelected}
            setAppSelected={setAppSelected}
          />
          <VaultTransactionsShares email={email} />
        </div>
      );
    default:
      return (
        <div className={classNames.contentArea}>
          <VaultHeaderCryproForex
            appSelected={appSelected}
            setAppSelected={setAppSelected}
            assetClass={assetClass}
            email={email}
            vaultSelected={vaultSelected}
            setVaultSelected={setVaultSelected}
            vaultsList={vaultsList}
            loading={loading}
          />
          <VaultControls
            appSelected={appSelected}
            setAppSelected={setAppSelected}
            email={email}
          />
          <VaultTransactions email={email} loading={loading} />
        </div>
      );
  }
};
