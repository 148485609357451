import Axios from 'axios';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';

function GetStartedSuccess({ logo, mailId, password, loading, setLoading }) {
  const history = useHistory();
  const { userLoginHandler } = useContext(BankContext);
  function loginValidate() {
    setLoading(true);
    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email: mailId,
      password,
    })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          userLoginHandler(mailId, data.accessToken, data.idToken);
          history.push('/transcript');
        }
      })
      .finally(() => setLoading(false));
  }
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">
        You Have Successfully Completed Your Registration
      </div>
      <div className="btns">
        <div className="btnBig" onClick={loginValidate}>
          Login To My Account
        </div>
        <div className="btnNext" onClick={() => history.push('/transcript')}>
          Close
        </div>
      </div>
    </div>
  );
}

export default GetStartedSuccess;
