import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

export const getVaultData = (params) => {
  const { app_code, email, displayCurrency, with_balances, type } = params;
  const data = axios.post(
    `https://comms.globalxchange.io/coin/vault/service/coins/get`,
    {
      app_code,
      email,
      displayCurrency,
      post_app_prices: true,
      orderby_dsc: true,
      with_balances,
      type,
    }
  );
  return data;
};

export const useLoadVaultData = (app_code) => {
  const query = useMutation(getVaultData, { enabled: Boolean(app_code) });
  return query;
};

export const getBondVaults = async ({ queryKey }) => {
  const [_key, { email, balanceType, displayCurrency, orderByDesc }] = queryKey;
  const balanceCheck = balanceType ? { with_balances: balanceType } : {};
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/iced/interest/balances/get?`,
      {
        params: {
          email,
          displayCurrency,
          orderby_dsc: orderByDesc,
          ...balanceCheck,
        },
      }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadBondVaults = (
  email,
  balanceType,
  displayCurrency,
  orderByDesc,
  coinType,
  updateBondVaultData,
  setUpdateBondVaultData
) => {
  const query = useQuery(
    [
      'loadBondVaults',
      {
        email,
        balanceType,
        displayCurrency,
        orderByDesc,
        coinType,
        updateBondVaultData,
      },
    ],
    getBondVaults,
    {
      onSuccess: () => {
        setUpdateBondVaultData(false);
      },
    },
    { enabled: coinType === 'bonds' }
  );
  return query;
};

export const getMoneyMarketVault = async ({ queryKey }) => {
  const [
    _key,
    { email, displayCurrency, appCode, balanceToggle, orderByDesc },
  ] = queryKey;
  const balanceCheck = balanceToggle ? { with_balances: balanceToggle } : {};

  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/user/app/interest/balances/get?`,
      {
        params: {
          email,
          displayCurrency,
          app_code: appCode,
          orderby_dsc: orderByDesc,
          ...balanceCheck,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const useLoadMoneyMarketVault = (
  email,
  displayCurrency,
  appCode,
  balanceToggle,
  orderByDesc,
  coinType,
  updateMMVaultData,
  setUpdateMMVaultData
) => {
  const query = useQuery(
    [
      'loadMoneyMarket',
      {
        email,
        displayCurrency,
        appCode,
        balanceToggle,
        orderByDesc,
        coinType,
        updateMMVaultData,
      },
    ],
    getMoneyMarketVault,
    {
      onSuccess: () => {
        setUpdateMMVaultData(false);
      },
    },
    { enabled: coinType === 'moneyMarkets' }
  );
  return query;
};

const getVaultTypes = async (email, displayCurrency) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/user/total/funds/data/get`,
      {
        params: {
          email,
          displayCurrency,
        },
      }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadVaultTypes = (email, displayCurrency) =>
  useQuery(['getVaultTypes', { email, displayCurrency }], () =>
    getVaultTypes(email, displayCurrency)
  );

const getContractData = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/iced/contract/get`,
      { params: { email } }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadContractData = (email) =>
  useQuery('getContractData', () => getContractData(email));
