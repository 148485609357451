import React, { useEffect, useContext } from 'react';
import guest from '../../static/images/guest.jpg';
import uparrow from '../../static/images/affiliate/uparrow.svg';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import moment from 'moment';

const Exchange = () => {
  const { email, username, name, profileImg } = useContext(BankContext);
  const { exchangeData } = useAppContextDetails();

  return exchangeData ? (
    exchangeData?.map((e, i) => {
      return (
        <div className="ind-detailss" key={i}>
          <div className="type1">
            <div>
              <img
                src={uparrow}
                alt=""
                style={{ height: '50px', width: '50px' }}
              />
            </div>
            <div className="detailss">
              <p>{e?.type}</p>
              <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                {moment(e?.txn?.date).format('MMMM Do YYYY, h:mm a')}
              </p>
            </div>
          </div>
          <div className="type1">
            {/* <div>
              <img
                src={profileImg ? profileImg : guest}
                alt=""
                style={{ borderRadius: '50%', height: '50px' }}
              />
            </div> */}
            <div className="detailss">
              <p>{e?.txn?.name}</p>
              <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                {e?.txn?.email}
              </p>
            </div>
          </div>
          <div className="dd1">{e?.commissions?.ps}</div>
          <div style={{ color: '#50C7AD' }} className="direct1">
            {e?.commissions?.dt_commission?.toFixed(7)}
          </div>
          <div style={{ color: '#50C7AD' }} className="direct1">
            {e?.commissions?.it_commission?.toFixed(7)}
          </div>
          <div className="baln1">--</div>
        </div>
      );
    })
  ) : (
    <></>
  );
};

export default Exchange;
