import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import { useCoinContextData } from '../../context/CoinContext';
import { SIDEBAR_CONTENTS } from './constants';
import { useLocation } from 'react-router-dom';

//images
import settings from '../../static/images/icons/settings.svg';
import close from '../../static/images/icons/close.svg';
import guest from '../../static/images/guest.jpg';

import digifinex from '../../static/images/icons/digifinex.svg';
import coinMarketCap from '../../static/images/icons/coinMarketCap.svg';
import etherScan from '../../static/images/icons/etherScan.svg';
import web3FullLogo from '../../static/images/icons/web3FullLogo.svg';
import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';
import { openAppRetiredApp } from '../../assets/Functions/miniFunctions';

export const DefaultSideBarContent = ({
  active,
  sidebarCollapse,
  chatOn,
  setChatOn,
  appColorCode,
  openSettings,
  isSecondaryMenu,
  toggleSettings,
}) => {
  const {
    email,
    setSidebarCollapse,
    setSelectedTopItemReact,
    username,
    name,
    profileImg,
    web3TotalPoints,
  } = useContext(BankContext);

  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
    isClose,
    setIsClose,
    setSelectedCoin,
    setCoinType,
    senbtnClicked,
    setSendBtnClicked,
  } = useCoinContextData();
  const { appCurrencySymbol } = useAppContextDetails();

  const { pathname } = useLocation();
  const history = useHistory();
  // const sideBarBorder = { borderLeft: `7px solid ${appColorCode}` };
  const sideBarBorder = {
    background: '#FFFFFF',
    border: ' 0.5px solid #E5E5E5',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    height: 'max-content',
    aspectRatio: '1/1',
    margin: '2rem 1rem',
  };
  const applyStyle = (condition) => (condition ? sideBarBorder : {});
  const closeChatScreen = () => (chatOn ? setChatOn(false) : null);
  const [selectedTab, setSelectedTab] = useState('token');
  const [defaultData, setDefaultData] = useState('default');
  const [hoverItem, setHoverItem] = useState('');
  const [tempData, setTempData] = useState([]);
  const [tempDataCad, setTempDataCad] = useState([]);
  const bgColor = { background: '#D9F5F6' };
  const applybgColor = (condition) => (condition ? bgColor : {});

  function hoverFunctionPlayer() {
    setHoverItem('/play');
  }

  function hoverFunctionTranscript() {
    setHoverItem('Transcript');
  }

  function hoverFunctionW3Certified() {
    setHoverItem('W3certified');
  }
  function hoverFunctionTalentAgent() {
    setHoverItem('TalentAgent');
  }

  function hoverFunctionAffiliate() {
    setHoverItem('affiliate');
  }
  function hoverFunctionLibrary() {
    setHoverItem('Library');
  }

  function hoverFunctionVaults() {
    setHoverItem('vaults');
  }

  function hoverFunctionCommercials() {
    setHoverItem('Commercials');
  }
  function hoverFunctionMarket() {
    setHoverItem('market');
  }

  function hoverFunctionFunds() {
    setHoverItem('funds');
  }
  function hoverFunctionRetired() {
    setHoverItem('retired');
  }
  function hoverFunctionTellers() {
    setHoverItem('tellers');
  }

  function hoverLeaveFunc() {
    setHoverItem('');
  }

  useEffect(() => {
    if (pathname.includes('vault')) {
      setSelectedTab('fund');
    } else {
      setSelectedTab('token');
    }
  }, [pathname]);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: sidebarCollapse ? '' : '45%',
          display: isClose ? 'none' : 'flex',
          flexWrap: sidebarCollapse ? 'nowrap' : 'wrap',
          border: sidebarCollapse ? 'none' : '0.5px solid #EBEBEB',
          borderRight: 'none',
          flexFlow: sidebarCollapse ? 'column' : 'wrap',
          position: sidebarCollapse ? 'absolute' : '',
          top: sidebarCollapse ? '50%' : '',
          left: sidebarCollapse ? '50%' : '',
          transform: sidebarCollapse ? 'translate(-50%, -50%)' : '',
          alignContent: sidebarCollapse ? '' : 'flex-start',
        }}
      >
        <div
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === '/transcript' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === '/transcript' &&
                active != '/transcript' &&
                !sidebarCollapse
            ),
            // pointerEvents: 'none',
          }}
          onClick={() => {
            closeChatScreen();

            if (email) {
              // setSelectedTopItemReact('Resources');
              history.push('/transcript');
            } else {
              history.push('/');
            }
          }}
          onMouseEnter={hoverFunctionTranscript}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[1].icon}
            alt="Affiliate Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === '/transcript' || active === '/transcript'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'Play' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[1].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === '/transcript' && active != '/transcript'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[1].name}
          </div>
        </div>

        {/* <Link
          to="/transcript"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'Transcript' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'Transcript' &&
                active != 'Transcript' &&
                !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionTranscript}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[1].icon}
            alt="Transcript Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'Transcript' || active === 'Transcript'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'Transcript' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[1].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'Transcript' && active != 'Transcript'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[1].name}
          </div>
        </Link> */}

        {/* <Link
          to="/vault"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(active === 'vaults' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'vaults' && active != 'vaults' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionVaults}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[6].icon}
            alt="vaults Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'vaults' || active === 'vaults'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'vaults' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[6].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'vaults' && active != 'vaults' ? 'flex' : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[6].name}
          </div>
        </Link> */}

        <Link
          to="/commercials"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'Commercials' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'Commercials' &&
                active != 'Commercials' &&
                !sidebarCollapse
            ),
            // borderRight: 'none',
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionCommercials}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[7].icon}
            alt="vaults Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'Commercials' || active === 'Commercials'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'Commercials' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[7].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display: sidebarCollapse
                ? '30px'
                : hoverItem === 'Commercials' && active != 'Commercials'
                ? 'flex'
                : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[7].name}
          </div>
        </Link>

        {/* <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'TalentAgent' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'TalentAgent' &&
                active != 'TalentAgent' &&
                !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionTalentAgent}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[3].icon}
            alt="TalentAgent Icon"
            style={{
              height:
                hoverItem === 'TalentAgent' || active === 'TalentAgent'
                  ? '50px'
                  : '40px',
            }}
          />
          {!sidebarCollapse && active === 'TalentAgent' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[3].name}</div>
          )}
          <div
            className="sidebar-icon-label1"
            style={{
              display:
                hoverItem === 'TalentAgent' && active != 'TalentAgent'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[3].name}
          </div>
        </Link> */}

        {/* <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(active === 'Library' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'Library' && active != 'Library' && !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionLibrary}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[5].icon}
            alt="Library Icon"
            style={{
              height:
                hoverItem === 'Library' || active === 'Library'
                  ? '50px'
                  : '40px',
            }}
          />
          {!sidebarCollapse && active === 'Library' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[5].name}</div>
          )}
          <div
            className="sidebar-icon-label1"
            style={{
              display:
                hoverItem === 'Library' && active != 'Library'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[5].name}
          </div>
        </Link> */}

        <div
          className="menu-itm active clickable"
          style={{
            // borderRight: 'none',
            ...applyStyle(
              active === 'W3certified' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'W3certified' &&
                active != 'W3certified' &&
                !sidebarCollapse
            ),
            // borderRight: '1px',
          }}
          onClick={() => {
            if (email) {
              setSelectedTopItemReact('Resources');
              history.push('/w3certified');
            } else {
              history.push('/');
            }
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionW3Certified}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[2].icon}
            alt="VaultIcon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'W3certified' || active === 'W3certified'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'W3certified' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[2].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'W3certified' && active != 'W3certified'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[2].name}
          </div>
        </div>

        {/* <Link
          to="/affiliate"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(active === 'affiliate' && !chatOn && sidebarCollapse),
            ...applybgColor(
              hoverItem === 'affiliate' &&
                active != 'affiliate' &&
                !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionAffiliate}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[4].icon}
            alt="Affiliate Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'affiliate' || active === 'affiliate'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'affiliate' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[4].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'affiliate' && active != 'affiliate'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[4].name}
          </div>
        </Link> */}

        <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            display: 'none',
            ...applyStyle(
              active === 'TalentAgent' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'TalentAgent' &&
                active != 'TalentAgent' &&
                !sidebarCollapse
            ),
          }}
          onClick={() => {
            closeChatScreen();
          }}
          onMouseEnter={hoverFunctionTalentAgent}
          onMouseLeave={hoverLeaveFunc}
        >
          <img
            src={SIDEBAR_CONTENTS[3].icon}
            alt="TalentAgent Icon"
            style={{
              height: sidebarCollapse
                ? '30px'
                : hoverItem === 'TalentAgent' || active === 'TalentAgent'
                ? '50px'
                : '40px',
            }}
          />
          {!sidebarCollapse && active === 'TalentAgent' && (
            <div className="sidebar-icon-label">{SIDEBAR_CONTENTS[3].name}</div>
          )}
          <div
            className={
              sidebarCollapse
                ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
                : 'sidebar-icon-label1'
            }
            style={{
              display:
                hoverItem === 'TalentAgent' && active != 'TalentAgent'
                  ? 'flex'
                  : 'none',
            }}
          >
            {SIDEBAR_CONTENTS[3].name}
          </div>
        </Link>
        <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'TalentAgent' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'TalentAgent' &&
                active != 'TalentAgent' &&
                !sidebarCollapse
            ),
          }}
        ></Link>
        <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'TalentAgent' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'TalentAgent' &&
                active != 'TalentAgent' &&
                !sidebarCollapse
            ),
            // borderRight: 'none',
          }}
        ></Link>
        <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'TalentAgent' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'TalentAgent' &&
                active != 'TalentAgent' &&
                !sidebarCollapse
            ),
          }}
        ></Link>
        <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'TalentAgent' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'TalentAgent' &&
                active != 'TalentAgent' &&
                !sidebarCollapse
            ),
            borderBottom: '0',
          }}
        ></Link>
        <Link
          to="#"
          className="menu-itm active clickable"
          style={{
            ...applyStyle(
              active === 'TalentAgent' && !chatOn && sidebarCollapse
            ),
            ...applybgColor(
              hoverItem === 'TalentAgent' &&
                active != 'TalentAgent' &&
                !sidebarCollapse
            ),
            borderBottom: '0',
          }}
        ></Link>
      </div>

      <div
        className="bottomboxx"
        style={{ height: isClose ? '2.5rem' : '55%' }}
      >
        <div className="tabss">
          <div className="tabss1">
            <div
              onClick={() => {
                if (pathname.includes('vault')) {
                  setSelectedTab('fund');
                } else {
                  setSelectedTab('token');
                }
              }}
              style={{
                fontWeight:
                  selectedTab === 'token' ||
                  (selectedTab === 'fund' && !isClose)
                    ? '600'
                    : '100',
                borderBottom:
                  selectedTab === 'token' ||
                  (selectedTab === 'fund' && !isClose)
                    ? '0.5px solid #464b4e'
                    : '',
                opacity:
                  selectedTab === 'token' ||
                  (selectedTab === 'fund' && !isClose)
                    ? '1'
                    : '0.5',
              }}
            >
              <p>Points</p>
            </div>
            <div
              onClick={() => setSelectedTab('profile')}
              // className={selectedTab === 'profile' && !isClose ? 'applyclass' : ''}
              style={{
                fontWeight:
                  selectedTab === 'profile' && !isClose ? '600' : '100',
                borderBottom:
                  selectedTab === 'profile' && !isClose
                    ? '0.5px solid #464b4e'
                    : '',
                opacity: selectedTab === 'profile' && !isClose ? '1' : '0.5',
              }}
            >
              <p>Profile</p>
            </div>
          </div>
          <div
            className="filterrr"
            onClick={(e) => {
              e.stopPropagation();
              if (!isCoinLoadingInAddAction) {
                if (coinActionEnabled) {
                  setCoinActionEnabled(false);
                  setCoinAction(['Menu']);
                } else if (addBankingEnabled) {
                  setAddBankingEnabled(false);
                } else {
                  toggleSettings(!openSettings);
                }
              }
              if (!isClose) {
                setIsClose(true);
              }
            }}
          >
            <img src={isClose ? close : settings} alt="settings" />
          </div>
        </div>
        {selectedTab === 'token' ? (
          <div
            className="tabss-data"
            style={{ display: isClose ? 'none' : '' }}
          >
            <div
              className="contennt"
              style={{
                padding: '2rem 1.7rem',
                // overflowY: 'scroll',
                gap: '1rem',
                // justifyContent: 'center'
              }}
            >
              <div className="tabss-data1">
                <div>
                  <img src={web3FullLogo} alt="web3FullLogo" />
                </div>
                {/* <div className="coinndata">DGPToken</div> */}
              </div>
              <div className="tabss-data2">
                Web3 Points accumulate when you learn, share and build your Web3
                future. They are derived from your Web3 Score and can be cashed
                our for USDT.
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 'calc(100% - 127px)',
                }}
              >
                {/* <div className="tabss-data-custom">
                  <div>Traded On:</div>
                  <div
                    className="tabimg"
                    onClick={(e) =>
                      window.open(
                        'https://www.digifinex.com/en-ww/trade/USDT/DGP',
                        '_blank'
                      )
                    }
                  >
                    <img src={digifinex} />
                  </div>
                </div>
                <div className="tabss-data-custom">
                  <div>Listed On:</div>
                  <div
                    className="tabimg"
                    onClick={(e) =>
                      window.open(
                        'https://coinmarketcap.com/currencies/dgpayment/',
                        '_blank'
                      )
                    }
                  >
                    <img src={coinMarketCap} />
                  </div>
                </div>
                <div
                  className="tabss-data-custom"
                  style={{ borderBottom: '0px' }}
                >
                  <div>Explorer:</div>
                  <div
                    className="tabimg"
                    onClick={(e) =>
                      window.open(
                        'https://etherscan.io/token/0x927159670C50042109d7C0f4aEd0Cee89452433E',
                        '_blank'
                      )
                    }
                  >
                    <img src={etherScan} />
                  </div>
                </div> */}
                <div className="tabss-inputs">
                  <div>Balance</div>
                  <input
                    className="inputBox"
                    placeholder="0.00"
                    value={web3TotalPoints ? web3TotalPoints?.toFixed(2) : ''}
                    style={{ pointerEvents: 'none' }}
                  />
                </div>
                {/* <div className="tabss-inputs">
                  <div>Earnings Till Date</div>
                  <input
                    className="inputBox"
                    placeholder="0.00"
                    value={web3TotalPoints ? web3TotalPoints?.toFixed(2) : ''}
                    style={{ pointerEvents: 'none' }}
                  />
                </div> */}
                <div
                  className="newsapp-btn"
                  onClick={() => window.open('https://web3today.io/', '_blank')}
                >
                  Go to News App
                </div>
              </div>
            </div>
            {/* <div className="tabss-footer">
              <div
                style={{ background: '#4B2A91' }}
                // onClick={() => {
                //   window.open(
                //     'https://www.digifinex.com/en-ww/trade/USDT/DGP',
                //     '_blank'
                //   );
                // }}
              >
                Earn
              </div>
              <div style={{ background: '#212529' }}>Spend</div>
            </div> */}
          </div>
        ) : selectedTab === 'fund' ? (
          <div
            className="tabss-data"
            style={{ display: isClose ? 'none' : '' }}
          >
            {defaultData === 'default' ? (
              <div
                className="contennt"
                style={{
                  justifyContent: 'flex-start',
                  padding: '0 0 0 1.85rem',
                  height: '100%',
                }}
              >
                <div
                  className="vault-contennt"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (tempData?.length > 0) {
                      setDefaultData('fundCrypto');
                    }
                  }}
                  style={{
                    opacity: tempData?.length > 0 ? '1' : '0.5',
                    cursor: tempData?.length > 0 ? 'pointer' : 'not-allowed',
                  }}
                >
                  <div className="heading-vault">Fund With Crypto</div>
                  <div className="subb">Add BTC, ETH, USDT, USDC & More</div>
                </div>
                <div
                  style={{
                    opacity: tempDataCad?.length > 0 ? '1' : '0.5',
                    cursor: tempDataCad?.length > 0 ? 'pointer' : 'not-allowed',
                  }}
                  className="vault-contennt"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (tempDataCad?.length > 0) {
                      setSendBtnClicked('CAD');
                      setCoinActionEnabled(true);
                      setCoinType('fiat');
                      setSelectedCoin(tempDataCad[0]);
                      setCoinAction(['Add']);
                    }
                  }}
                >
                  <div className="heading-vault">Fund With Fiat</div>
                  <div className="subb">Send CAD From Your Bank Account</div>
                </div>
                <div
                  className="vault-contennt"
                  onClick={() => {
                    setCoinType('tracker');
                  }}
                >
                  <div className="heading-vault">Check Transaction Status</div>
                  <div className="subb">Track Deposits & Withdrawals</div>
                </div>
              </div>
            ) : (
              <div
                className="contennt"
                style={{
                  justifyContent: 'flex-start',
                  padding: '0 0 0 1.85rem',
                  height: '100%',
                }}
              >
                {tempData?.map((e, i) => {
                  return (
                    <div
                      className="vault-contennt"
                      onClick={(event) => {
                        event.stopPropagation();
                        setSendBtnClicked(e.coinSymbol);
                        setCoinActionEnabled(true);
                        setCoinType('crypto');
                        setSelectedCoin(e);
                        setCoinAction(['Add']);
                      }}
                    >
                      <div className="fundcrypto">
                        <img src={e?.coinImage} alt="btc" />
                        {e.coinName}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <div
            className="tabss-data"
            style={{ display: isClose ? 'none' : '' }}
          >
            <div
              className="contennt"
              style={{ justifyContent: 'space-evenly', height: '100%' }}
            >
              <div
                className="tabss-data1"
                style={{ justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="profile-icon">
                    <img
                      src={profileImg ? profileImg : guest}
                      alt="dgplogo"
                      style={{
                        marginLeft: '0px',
                        height: '40px',
                        width: '40px',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: '7px',
                      fontSize: '20px',
                      fontWeight: 700,
                    }}
                  >
                    {name ? name : username}
                  </div>
                </div>
                <div
                  className="verificationStatus"
                  onClick={() => {
                    window.open(
                      'https://in.sumsub.com/idensic/l/#/sbx_uni_2hSenyE0Msz9cyjI',
                      '_blank'
                    );
                  }}
                >
                  Not Verified
                </div>
              </div>

              <div style={{ overflowY: 'scroll', height: '32vh' }}>
                <div className="tabss-data-custom">
                  <div>Email:</div>
                  <div>{email}</div>
                </div>
                <div className="tabss-data-custom">
                  <div>Country:</div>
                  <div>--</div>
                </div>
                <div className="tabss-data-custom">
                  <div>State:</div>
                  <div>--</div>
                </div>
                <div className="tabss-data-custom">
                  <div>City:</div>
                  <div>--</div>
                </div>
                <div className="tabss-data-custom">
                  <div>Street:</div>
                  <div>--</div>
                </div>
                <div className="tabss-data-custom">
                  <div>Postal Code:</div>
                  <div>--</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
