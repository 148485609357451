import React, { createContext, useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import * as d3 from 'd3';
import { BankContext } from './Context';
import { useQuery } from 'react-query';
import { FormatCurrency } from '../utils/FunctionTools';
import affiliateBankIcon from '../static/images/assetClassIcons/affiliateBank.svg';
import cryptoCurrenciesIcon from '../static/images/assetClassIcons/cryptoCurrencies.svg';
import fiatCurrenciesIcon from '../static/images/assetClassIcons/fiatCurrencies.svg';
import fixedIncomeIcon from '../static/images/assetClassIcons/fixedIncome.svg';
import investmentsIcon from '../static/images/assetClassIcons/investments.svg';
import loansIcon from '../static/images/assetClassIcons/loans.svg';
import moneyMarketsIcon from '../static/images/assetClassIcons/moneyMarkets.svg';
import { useAppContextDetails } from './AppContext';

export const NetWorthContext = createContext();

const getEqtCoins = async ({ queryKey }) => {
  const [_key, investmentSubType] = queryKey;
  if (investmentSubType) {
    const { data } = await Axios.get(
      `https://comms.globalxchange.io/coin/investment/path/user/vault/balances/get?email=shorupan@gmail.com&investmentType=EQT&investmentSubType=${investmentSubType}`
    );
    return data?.vaultsData || [];
  }
};

function NetWorthContextProvider({ children }) {
  const colors = d3.scaleOrdinal(d3.schemeTableau10);
  const { email, coinListObject } = useContext(BankContext);
  const [totalBalance, setTotalBalance] = useState(0);
  const [fiatBalance, setFiatBalance] = useState(0);
  const [cryptoBalance, setCryptoBalance] = useState(0);
  const [cryptoCount, setCryptoCount] = useState(0);
  const [fiatCount, setFiatCount] = useState(0);
  const [netWorth, setNetworth] = useState(0);
  const [liquidCrypto, setLiquidCrypto] = useState(0);
  const [liquidFiat, setLiquidFiat] = useState(0);

  const { appCurrencyName } = useAppContextDetails();

  // User Apps List

  const [userApps, setUserApps] = useState([]);
  useEffect(() => {
    if (email) {
      Axios.get(
        `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
      ).then(({ data }) => {
        if (data.status) {
          setUserApps(data.userApps);
        }
      });
      return () => {};
    }
  }, [email]);

  //Cards On First page
  const [mainCards, setMainCards] = useState([]);

  // Tab Selected
  const [assetClass, setAssetClass] = useState(null);
  const [assetCoin, setAssetCoin] = useState(null);
  const [liquidity, setLiquidity] = useState(null);
  const [isBondRedeemed, setIsBondRedeemed] = useState(false);
  const [assetClassCode, setAssetClassCode] = useState('');

  useEffect(() => {
    if (assetClass === 'Cryptocurrency') {
      setAssetClassCode('crypto');
    } else if (assetClass === 'Forex Currencies') {
      setAssetClassCode('fiat');
    }
  }, [assetClass]);

  const { data: eqtCoinList } = useQuery(
    ['eqtCoinList', assetCoin],
    getEqtCoins
  );

  // Card List To Populate & Selected Total Balance
  const [cardList, setCardList] = useState([]);
  const [selectedTotalBalance, setSelectedTotalBalance] = useState(0);

  const [tabData, setTabData] = useState({
    key: {
      assetClass,
      assetCoin,
      liquidity,
    },
  });

  const [tabIndex, setTabIndex] = useState('key');
  useEffect(() => {
    setTabData({
      ...tabData,
      [tabIndex]: {
        assetClass,
        assetCoin,
        liquidity,
        isBondRedeemed,
      },
    });
    // eslint-disable-next-line
  }, [assetClass, assetCoin, liquidity, isBondRedeemed]);

  return (
    <NetWorthContext.Provider
      value={{
        //Show Balance In Loading State
        totalBalance,
        fiatBalance,
        cryptoBalance,
        cardList,
        selectedTotalBalance,
        tabData,
        assetClass,
        setAssetClass,
        assetCoin,
        setAssetCoin,
        liquidity,
        setLiquidity,
        isBondRedeemed,
        setIsBondRedeemed,
        userApps,
        // icedValues,
        tabData,
        setTabData,
        tabIndex,
        setTabIndex,
        // userApps,
        // userObjects,
        netWorth,
        liquidCrypto,
        liquidFiat,
      }}
    >
      {children}
    </NetWorthContext.Provider>
  );
}

export default NetWorthContextProvider;
