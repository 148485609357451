import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import {
  useLoadBondVaults,
  useLoadMoneyMarketVault,
  useLoadVaultData,
  useLoadVaultTypes,
} from './api';
import bitcoin from '../../../static/images/vaults/bitcoin.svg';
import bonds from '../../../static/images/login/bond.svg';
import forex from '../../../static/images/vaults/forex.svg';
import add from '../../../static/images/vaults/add.svg';
import portfolioIcon from '../../../static/images/login/portfolioai.svg';
import moneymarket from '../../../static/images/vaults/moneymarket.svg';
import './vaults.scss';
import Toggle from '../../Toggle';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useCoinContextData } from '../../../context/CoinContext';
import { ListComponent } from '../../ListComponent';
import blocksIcon from '../../../static/images/sidebar-icons/blocks.svg';
import axios from 'axios';

import bitcoinBlue from '../../../static/images/vaults/bitcoinBlue.svg';
import spisBlue from '../../../static/images/vaults/spisBlue.svg';
import savingsPoolBlue from '../../../static/images/vaults/savingspoolBlue.svg';

export const Vaults = () => {
  const history = useHistory();
  const [searchedValue, setSearchedValue] = useState('');
  // const [balanceToggle, setBalanceToggle] = useState(false);

  const { email, sidebarCollapse } = useContext(BankContext);
  const {
    appCode,
    appCurrencyName,
    appLogo,
    appCurrencySymbol,
    balanceToggle,
    setBalanceToggle,
  } = useAppContextDetails();
  const {
    coinType,
    setCoinType,
    updateVaultData,
    setUpdateVaultData,
    setPlaceholder,
    updateBondVaultData,
    setUpdateBondVaultData,
    updateMMVaultData,
    setUpdateMMVaultData,
    blocksVaultData,
    setBlocksVaultData,
  } = useCoinContextData();
  const vaultData = useLoadVaultData(appCode);

  const { data: vaultTypeResponse, isLoading: isVaultTypeLoading } =
    useLoadVaultTypes(email, appCurrencySymbol);

  useEffect(() => {
    switch (coinType) {
      case 'portfolio':
        return setPlaceholder('Search Vault Types');
      case 'crypto':
        return setPlaceholder('Search Crypto Vaults');
      case 'fiat':
        return setPlaceholder('Search Forex Vaults');
      case 'moneyMarkets':
        return setPlaceholder('Search Money Market Vaults');
      case 'bonds':
        return setPlaceholder('Search Bond Vaults');
      case 'blocks':
        axios
          .get(
            `https://comms.globalxchange.io/retired/block/user/get/all/contract?email=${email}`
          )
          .then((response) => {
            setBlocksVaultData(response?.data?.data);
          });

        return setPlaceholder('Search Blocks...');
    }
  }, [coinType]);

  const {
    data: moneyMarketVaultData,
    isLoading: isMoneyMarketVaultDataLoading,
  } = useLoadMoneyMarketVault(
    email,
    appCurrencyName,
    appCode,
    balanceToggle,
    true,
    coinType,
    updateMMVaultData,
    setUpdateMMVaultData
  );

  const moneyMarketData = useMemo(
    () =>
      moneyMarketVaultData?.data?.result?.[0]?.balances?.[0]?.liquid_balances,
    [moneyMarketVaultData]
  );

  const { data: bondVaultData, isLoading: isBondVaultLoading } =
    useLoadBondVaults(
      email,
      balanceToggle,
      appCurrencyName,
      true,
      coinType,
      updateBondVaultData,
      setUpdateBondVaultData
    );
  const bondData = useMemo(
    () => bondVaultData?.data?.result?.[0]?.balances,
    [bondVaultData]
  );

  useEffect(() => {
    if (coinType === 'crypto' || coinType === 'fiat')
      vaultData.mutate({
        app_code: appCode,
        email,
        displayCurrency: appCurrencyName,
        with_balances: balanceToggle,
        type: coinType,
      });
    setUpdateVaultData(false);
  }, [appCode, balanceToggle, coinType, updateVaultData]);

  const userVaultData = vaultData.data?.data;
  const isLoading =
    vaultData?.isLoading ||
    isBondVaultLoading ||
    isMoneyMarketVaultDataLoading ||
    isVaultTypeLoading;

  const cachedVaultData = useMemo(
    () => ({
      userName: userVaultData?.username,
      totalVaultValue: userVaultData?.totalVaultsValue_DC,
      coinsData: userVaultData?.coins_data,
    }),
    [vaultData]
  );

  const { userName, totalVaultValue, coinsData } = cachedVaultData;

  // console.log(coinsData, 'checking all data');

  const bondVaultTotalBalance = bondVaultData?.data?.result?.[0]?.totalValue;
  const moneyMarketTotalBalance = moneyMarketVaultData?.data?.totalBalance_dc;
  const portfolioTotalBalance = vaultTypeResponse?.data?.networth_dc;

  const totalBalance = () => {
    switch (coinType) {
      case 'crypto':
      case 'fiat':
        return totalVaultValue;
      case 'bonds':
        return bondVaultTotalBalance;
      case 'moneyMarkets':
        return moneyMarketTotalBalance;
      case 'portfolio':
        return portfolioTotalBalance;
      default:
        return 0;
    }
  };
  const formattedTotalValue = totalBalance()?.toFixed(2) ?? '0.00';

  if (!vaultData) return <LoadingAnimation icon={appLogo} width={200} />;

  return (
    <ListComponent
      isLoading={isLoading}
      leftHeaderData={<h6>Welcome Back {userName}</h6>}
      leftSubHeader={
        <div className="refer" onClick={() => history.push('/affiliate')}>
          <h6>Refer A Friend</h6>
        </div>
      }
      rightHeaderData={
        window.location.pathname.includes('BPlanner') ? (
          <>
            <h6 className="value">
              {appCurrencyName} {formattedTotalValue}
            </h6>
            <h6 className="value-label">Total Networth</h6>
          </>
        ) : (
          <>
            <h6 className="value">
              {appCurrencyName} {formattedTotalValue}
            </h6>
            <h6 className="value-label">Total Balance</h6>
          </>
        )
      }
      listOfIcons={
        <div className="icons">
          {coinType !== 'portfolio' && (
            <div className="toggle">
              <Toggle toggle={balanceToggle} setToggle={setBalanceToggle} />
            </div>
          )}
          {/* <div
            className="iconbox"
            style={{
              borderLeft: coinType === 'portfolio' && '1px solid #e7e7e7',
            }}
          >
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'portfolio' ? 1 : '',
                }}
                src={portfolioIcon}
                alt="Portfolio"
                key="img1"
                onClick={() => setCoinType('portfolio')}
              />
            </div>
          </div> */}
          <div
            className="iconbox"
            style={{
              borderLeft: '1px solid #e7e7e7',
            }}
          >
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'crypto' ? 1 : '',
                }}
                src={bitcoinBlue}
                alt="bitcoinBlue"
                key="img1"
                onClick={() => setCoinType('crypto')}
              />
            </div>
            <div
              className="hoverText"
              style={{
                right: sidebarCollapse ? '16vw' : '11.5vw',
                visibility: coinType === 'crypto' ? 'hidden' : '',
              }}
            >
              Crypto Vaults
            </div>
          </div>
          {/* <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{ opacity: coinType === 'fiat' ? 1 : '' }}
                src={forex}
                alt="Forex"
                key="img2"
                onClick={() => setCoinType('fiat')}
              />
            </div>
          </div> */}
          {/* <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'moneyMarkets' ? 1 : '',
                }}
                src={moneymarket}
                alt="MoneyMarket"
                key="img3"
                onClick={() => setCoinType('moneyMarkets')}
              />
            </div>
          </div> */}
          <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'blocks' ? 1 : '',
                }}
                src={spisBlue}
                alt="spisBlue"
                key="img2"
                onClick={() => setCoinType('blocks')}
              />
            </div>
            <div
              className="hoverText"
              style={{
                right: sidebarCollapse ? '10vw' : '',
                visibility: coinType === 'blocks' ? 'hidden' : '',
              }}
            >
              RetirementSIP’s
            </div>
          </div>
          <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'bonds' ? 1 : '',
                }}
                src={savingsPoolBlue}
                alt="savingsPoolBlue"
                key="img3"
                onClick={() => setCoinType('bonds')}
              />
            </div>
            <div
              className="hoverText"
              style={{
                right: sidebarCollapse ? '4vw' : '0vw',
                visibility: coinType === 'bonds' ? 'hidden' : '',
              }}
            >
              Retirement Saving Pools
            </div>
          </div>
          {/* <div className="iconbox1">
            <div className="icondiv">
              <img className="iconsimg" src={add} alt="Add" key="img5" />
            </div>
          </div> */}
        </div>
      }
      searchedValue={searchedValue}
      setSearchedValue={setSearchedValue}
      moneyMarketData={moneyMarketData}
      bondData={bondData}
      coinsData={coinsData}
      vaultTypeResponse={vaultTypeResponse}
      blocksVaultData={blocksVaultData}
    />
  );
};
