import React, { useState, useEffect, useContext } from 'react';
import { commissionForStaking } from './api';
import { BankContext } from '../../context/Context';
import moment from 'moment';
import uparrow from '../../static/images/affiliate/uparrow.svg';
import Skeleton from 'react-loading-skeleton';

const StakingCommission = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [minDate, setMinDate] = useState(getDefaultMinDate());
  const [maxDate, setMaxDate] = useState(getDefaultMaxDate());
  const { email } = useContext(BankContext);

  function fetchData() {
    setIsLoading(true);
    commissionForStaking(email, minDate, maxDate)
      .then((response) => {
        let result = response?.data?.logsData?.logs;
        // console.log(result, 'result');
        if (result?.length > 0) {
          setData((prevData) => [...prevData, ...result]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function handleScroll(event) {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && !isLoading) {
      setMinDate(getPreviousMonth(minDate));
    }
  }

  function getPreviousMonth(date) {
    const d = new Date(date);
    d.setMonth(d.getMonth() - 1);
    return formatDate(d);
  }

  function getDefaultMinDate() {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return formatDate(d);
  }

  function getDefaultMaxDate() {
    return formatDate(new Date());
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    fetchData();
  }, [minDate, maxDate]);

  return (
    <div
      onScroll={handleScroll}
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      {data && data?.map((e) => (
        <div key={e.id} className="ind-detailss">
          <div className="type1">
            <div>
              <img
                src={uparrow}
                alt=""
                style={{ height: '50px', width: '50px' }}
              />
            </div>
            <div className="detailss">
              <p>Bond Interest Fee</p>
              <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                {moment(e?.txn?.date).format('MMMM Do YYYY, h:mm a')}
              </p>
            </div>
          </div>
          <div className="type1">
            {/* <div>
                <img
                  src={profileImg ? profileImg : guest}
                  alt=""
                  style={{ borderRadius: '50%', height: '50px' }}
                />
              </div> */}
            <div className="detailss">
              <p>--</p>
              <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                {e?.txn?.email}
              </p>
            </div>
          </div>
          <div className="dd1">{e?.commissions?.ps}</div>
          <div style={{ color: '#50C7AD' }} className="direct1">
            {e?.commissions?.dt_commission?.toFixed(4)}
          </div>
          <div style={{ color: '#50C7AD' }} className="direct1">
            {e?.commissions?.it_commission?.toFixed(4)}
          </div>
          <div className="baln1">--</div>
        </div>
      ))}
      {isLoading &&
        Array(10)
          .fill(' ')
          .map((item, index) => {
            return (
              <div className="ind-detailss" key={index}>
                <div className="type1">
                  <div className="detailss">
                    <Skeleton width={75} height={15} />
                    <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                      <Skeleton width={100} height={15} />
                    </p>
                  </div>
                </div>
                <div className="type1">
                  {/* <div>
                          <img
                            src={profileImg ? profileImg : guest}
                            alt=""
                            style={{ borderRadius: '50%', height: '50px' }}
                          />
                      </div> */}
                  <div className="detailss">
                    <p>
                      <Skeleton width={100} height={15} />
                    </p>
                    <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                      <Skeleton width={100} height={15} />
                    </p>
                  </div>
                </div>
                <div className="dd1">
                  <Skeleton width={60} height={25} />
                </div>
                <div style={{ color: '#50C7AD' }} className="direct1">
                  <Skeleton width={60} height={25} />
                </div>
                <div style={{ color: '#50C7AD' }} className="direct1">
                  <Skeleton width={60} height={25} />
                </div>
                <div className="baln1">
                  <Skeleton width={60} height={25} />
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default StakingCommission;
