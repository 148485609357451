import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BankContext } from './context/Context';
import AdminLogin from './pages/AdminLogin';
import GetStartedPage from './pages/GetStartedPage';
import PreRegisterMobile from './pages/PreRegisterMobile';
// import LoginPage from './pages/Login/index';
import LoginPage from './pages/Login/Login';
import { useAppContextDetails } from './context/AppContext';
import FirstPage from './pages/Registration/pages/FirstPage';
import HomePage from './pages/Registration/pages/HomePage';
import AffiliatePage from './pages/AffiliatePage/index';
import Leveldata from './pages/AffiliatePage/Leveldata';
import { Toaster } from 'react-hot-toast';
import AcademyPage from './pages/AcademyPage';
import SettingsPage from './pages/Settings';
import SuperAdminReset from './pages/SuperAdminReset';
import Login from './pages/Login/Login';
import Player from './pages/Player';
import TranscriptPage from './pages/TranscriptPage';
import Vault from './pages/Vault';
import VaultsPage from './pages/VaultsPage';
import Staking from './components/Investment/Staking';
import Commercials from './pages/commerical';
function Routes() {
  const { refreshPage, email } = useContext(BankContext);
  const { appLogo, websiteTitle, websiteDescription, UserIdWeb3TodayAccount, } = useAppContextDetails();
  let history = useHistory()

  console.log(UserIdWeb3TodayAccount)

  useEffect(() => {
    const favIcon = document.getElementById('fav-icon');
    favIcon.href = appLogo;
    if (!email || email === "" || email === null) {
      history.push("/")
    }
  }, [appLogo, email]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteDescription} />
      </Helmet>

      <Toaster />
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            email ? <Redirect to="/transcript" /> : <LoginPage />
          )}
        />
        <Route exact path="/superadminreset" component={SuperAdminReset} />
        <Route
          exact
          path="/register"
          component={window.innerWidth > 600 ? HomePage : Login}
        />

        {/* <Route exact path="/play" component={Player} /> */}
        <Route exact path="/transcript" component={Player} />

        <Route exact path="/commercials" component={Commercials} />
        <Route exact path="/register/affiliate" component={FirstPage} />
        <Route exact path="/register/affiliate/:id" component={FirstPage} />
        <Route exact path="/register/pre-registered" component={FirstPage} />
        <Route
          exact
          path="/register/pre-registered/:id"
          component={FirstPage}
        />
        <Route exact path="/register/by-myself" component={FirstPage} />
        <Route exact path="/register/by-myself/:id" component={FirstPage} />

        {/* <Route
          exact
          path="/transcript"
          component={TranscriptPage}
          key={refreshPage === 'affiliate'.toString()}
        /> */}
        <Route
          exact
          path="/affiliate"
          component={AffiliatePage}
          key={refreshPage === 'affiliate'.toString()}
        />
        <Route
          exact
          path="/affiliate/levels"
          component={Leveldata}
          key={refreshPage === 'affiliate'.toString()}
        />
        <Route
          exact
          path="/w3certified"
          component={AcademyPage}
        // key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/settings"
          component={SettingsPage}
        // key={refreshPage === 'vaults'.toString()}
        />

        <Route exact path="/pre-register" component={PreRegisterMobile} />
        <Route exact path="/signup" component={GetStartedPage} />

        <Route exact path="/reset" component={GetStartedPage} />
        {/* <Route
          exact
          path="/bonds/:contractId"
          component={BondOverview}
          key={refreshPage === 'moneyMarkets'.toString()}
        /> */}
        <Route
          exact
          path="/vault/contracts/:id"
          component={Staking}
        // key={refreshPage === 'vaults'.toString()}
        />

        <Route
          exact
          path="/vault/:assetClassParam?/:appCodeParam?/:coinParam?"
          component={VaultsPage}
          key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/vault/:type/:action"
          component={Vault}
          key={refreshPage === 'vaults'.toString()}
        />
        <Route
          exact
          path="/adminLogin/:email/:token/:userEmail"
          component={AdminLogin}
        />

        {/* <Route exact path="/" component={LoginPage} /> */}
        <Route exact path="*" component={LoginPage} />
      </Switch>
    </HelmetProvider>
  );
}

export default Routes;
