import React from 'react';
import './academy.scss';
import Playlist from './Playlist';
import shorupan from './shorupan.svg';

const Customers = () => {
  return (
    <div className="customerss">
      <div className="leftCont">
        <div className="play-txt">Playlists</div>
        <div className="total-playlist">
          <Playlist />
          <Playlist />
          <Playlist />
          <Playlist />
          <Playlist />
          <Playlist />
        </div>
      </div>
      <div className="rightCont">
        <div className="video-div"></div>
        <div className="video-info">
          <div className="info-head">I Am Completely New</div>
          <div className="info-txt">
            Assets.io Is The Worlds First Wealth Transition Platform Where You
            You Can Digitalize Your Analog. Where You You Can Digitalize Your
            Analog.
          </div>
        </div>
        <div className="owner-card">
          <div>
            <img src={shorupan} alt="" />
          </div>
          <div>
            <div className="owner-txt">Shorupan Pirakaspathy</div>
            <div>CEO of Retired App</div>
          </div>
        </div>
        <div className="related-div">Related Videos</div>
        <Playlist />
        <Playlist />
        <Playlist />
        <Playlist />
        <Playlist />
      </div>
    </div>
  );
};

export default Customers;
