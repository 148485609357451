import React, { createContext, useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { BankContext } from './Context';
import { useAppContextDetails } from './AppContext';

export const InvestmentVaultContext = createContext();

const getCoinBalanceList = async ({ queryKey }) => {
  // const [_key, { profileId, appCode }] = queryKey;
  // const resOne = await Axios.post(
  //   'https://comms.globalxchange.io/coin/vault/service/coins/get',
  //   {
  //     app_code: appCode,
  //     profile_id: profileId,
  //     investmentCoin: true,
  //   }
  // );
  // const dataOne = resOne.data;
  // return dataOne.coins_data;
};

const getCoinAddress = async ({ queryKey }) => {
  // const [_key, { profileId, appCode }] = queryKey;
  // const resTwo = await Axios.post(
  //   'https://comms.globalxchange.io/coin/vault/service/balances/get',
  //   {
  //     app_code: appCode,
  //     profile_id: profileId,
  //   }
  // );
  // const dataTWO = resTwo.data;

  // return dataTWO?.vault?.coinAddress;
};

const getVaultTxns = async ({ queryKey }) => {
  // const [_key, { profileId, appCode }] = queryKey;
  // const resThree = await Axios.post(
  //   'https://comms.globalxchange.io/coin/vault/service/txns/get',
  //   {
  //     app_code: appCode,
  //     profile_id: profileId,
  //   }
  // );
  // const dataThree = resThree.data;

  // return dataThree.txns;
};

function InvestmentVaultContextProvider({ children }) {
  const { profileId, email } = useContext(BankContext);
  const [coinSelected, setCoinSelected] = useState({});
  const { appCode } = useAppContextDetails();

  const coinBalanceList = useQuery(
    ['coinBalanceList', { profileId, appCode }],
    getCoinBalanceList,
    {
      enabled: !!email, // execute the query only if email exists
    }
  );
  const coinAddress = useQuery(
    ['coinAddress', { profileId, appCode }],
    getCoinAddress,
    {
      enabled: !!email, // execute the query only if email exists
    }
  );
  const vaultTxns = useQuery(
    ['vaultTxns', { profileId, appCode }],
    getVaultTxns,
    {
      enabled: !!email, // execute the query only if email exists
    }
  );

  useEffect(() => {
    if (coinBalanceList && !coinSelected?.coinSymbol) {
      setCoinSelected(coinBalanceList[0]);
    }
  }, [coinBalanceList]);

  // For Vault Filter Conrolls

  const [menuTwo, setMenuTwo] = useState({
    key: 'all',
    value: 'All Directions',
  });

  const [dateSelected, setDateSelected] = useState(null);

  const [showNativeValue, setShowNativeValue] = useState(true);

  const updateBalance = () => {
    coinBalanceList.refetch();
    coinAddress.refetch();
    vaultTxns.refetch();
  };

  return (
    <InvestmentVaultContext.Provider
      value={{
        coinBalanceList: coinBalanceList.data,
        coinSelected,
        setCoinSelected,
        vaultTxns: vaultTxns.data,
        updateBalance,
        coinAddress: coinAddress.data,
        loading:
          coinBalanceList.status === 'loading' ||
          coinAddress.status === 'loading' ||
          vaultTxns.status === 'loading',
        menuTwo,
        setMenuTwo,
        dateSelected,
        setDateSelected,
        showNativeValue,
        setShowNativeValue,
      }}
    >
      {children}
    </InvestmentVaultContext.Provider>
  );
}

export default InvestmentVaultContextProvider;
