import React, { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import { useCoinContextData } from '../../context/CoinContext';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import { SelectFooters } from './SelectFooter';
import { SideBarMenu } from './SideBarMenu';
import web3FullLogo from '../../static/images/web3/web3FullLogo.svg';
import web3Logo from '../../static/images/web3/web3Logo.svg';

function LayoutSidebar({
  active,
  chatOn,
  setChatOn,
  adminSidebar,
  setAdminSidebar,
}) {
  const history = useHistory();
  const { email, sidebarCollapse, setSidebarCollapse, closeSidebars, admin } =
    useContext(BankContext);
  const {
    coinActionEnabled,
    setCoinActionEnabled,
    coinAction,
    addBankingEnabled,
    setCoinAction,
    coinType,
  } = useCoinContextData();

  const [openSettings, toggleSettings] = useState(false);
  const [toast, setToast] = useState(false);

  const { appName, appCode, appLogo, appFullLogo, appColorCode } =
    useAppContextDetails();

  const menuEndRef = useRef(null);

  const scrollToBottom = () => {
    if (menuEndRef && menuEndRef.current)
      menuEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (active.includes('transactions')) {
      scrollToBottom();
    }
  }, [active]);

  const togglingIcon = sidebarCollapse ? faAngleRight : faAngleLeft;

  useEffect(() => {
    if (toast) setTimeout(() => setToast(false), 2000);
  }, [toast]);

  const isSecondaryMenu =
    openSettings || coinActionEnabled || addBankingEnabled;

  return (
    <div
      className={`side-bar d-flex flex-column ${
        sidebarCollapse ? 'collapse' : 'expand'
      }`}
    >
      {email ? (
        <div
          className={`profile d-flex ${!adminSidebar && 'clickable'}`}
          style={{
            visibility:
              coinAction[0] === 'Add' && coinType === 'fiat' ? 'hidden' : '',
          }}
        >
          <div
            style={{
              width: '100%',
              // padding: sidebarCollapse ? '' : '10px 10px 10px 1.85rem',
              display: 'flex',
              // justifyContent: 'center',
            }}
          >
            <img
              className="clickable"
              src={
                !sidebarCollapse
                  ? appFullLogo
                  : sidebarCollapse && appLogo
                  ? appLogo
                  : !sidebarCollapse && appFullLogo
                  ? appFullLogo
                  : sidebarCollapse
                  ? web3Logo
                  : web3FullLogo
              }
              onClick={() => {
                if (admin) {
                  setAdminSidebar(true);
                  setChatOn(false);
                } else {
                  history.push('/transcript');
                  setCoinAction(['Menu']);
                  setCoinActionEnabled(false);
                }
              }}
              style={{ maxHeight: '100%' }}
            />
          </div>
          {coinAction[0] === 'Menu' && !addBankingEnabled && (
            <div
              className="toggle"
              style={{
                background: appColorCode,
                pointerEvents: openSettings ? 'none' : '',
                opacity: openSettings ? '0.5' : '',
              }}
              onClick={() => {
                if (!coinActionEnabled) setSidebarCollapse(!sidebarCollapse);
              }}
            >
              <FontAwesomeIcon icon={togglingIcon} />
            </div>
          )}
        </div>
      ) : (
        <div className="profile d-flex">
          <img
            className="clickable"
            src={
              !sidebarCollapse && appFullLogo
                ? appFullLogo
                : sidebarCollapse && appLogo
                ? appLogo
                : appLogo
            }
            alt="Icon Logo"
            onClick={() => {
              closeSidebars();
              history.push(`/${appCode}`);
            }}
            style={{ maxHeight: '100%' }}
          />
          {/* <div className="col my-auto">
            <h5>{appName}&nbsp;</h5>
            <div
              className="getStartedBtn"
              onClick={() => {
                history.push('/signup');
              }}
            >
              Get Started
            </div>
          </div> */}
          <div
            className="toggle"
            onClick={() => {
              setSidebarCollapse(!sidebarCollapse);
            }}
          >
            <FontAwesomeIcon icon={togglingIcon} />
          </div>
        </div>
      )}
      <Scrollbars
        className="menu-scrl"
        autoHide
        renderView={(props) => (
          <div
            {...props}
            className="menu-side"
            id="preventScroll"
            style={{ overflow: sidebarCollapse ? 'visible' : '' }}
          />
        )}
        style={{ overflow: sidebarCollapse ? 'visible' : '' }}
      >
        <div className="spacer" />

        <SideBarMenu
          openSettings={openSettings}
          active={active}
          chatOn={chatOn}
          setChatOn={setChatOn}
          toggleSettings={toggleSettings}
          isSecondaryMenu={isSecondaryMenu}
        />

        {/* <div ref={menuEndRef} className="spacer" /> */}
      </Scrollbars>
      {toast && (
        <ToastMessage
          textOne="Copied the Coin Address"
          onClose={() => setToast(false)}
        />
      )}
      <SelectFooters
        toggleSettings={toggleSettings}
        setToast={setToast}
        isSecondaryMenu={isSecondaryMenu}
        openSettings={openSettings}
        setAdminSidebar={setAdminSidebar}
      />
    </div>
  );
}

export default LayoutSidebar;
