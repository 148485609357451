import React, { useContext, useState } from 'react';
import classNames from './vaultSelectModal.module.scss';
import { useUserBondsList } from '../../queryHooks';
import { FormatCurrency } from '../../utils/FunctionTools';
import Skeleton from 'react-loading-skeleton';
import { VaultPageContext } from '../../context/VaultPageContext';
import { useAppContextDetails } from '../../context/AppContext';

function VaultSelectBondsModal({
  onClose = () => {},
  onSuccess = () => {},
  email,
  vaultSelected,
  setVaultSelected,
}) {
  const [vaultSearch, setVaultSearch] = useState('');
  const { assetClass } = useContext(VaultPageContext);
  const { appCurrencyName } = useAppContextDetails();
  const { data: vaultsList = [], isLoading: vaultListLoading } =
    useUserBondsList(email, appCurrencyName);
  return (
    <div className={classNames.vaultSelectModal}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className={classNames.vaultSelectCard}>
        <div className={classNames.header}>
          <img src={assetClass.icon} alt="" className={classNames.headIcon} />
        </div>
        <input
          type="text"
          name="search"
          className={classNames.search}
          style={{
            paddingLeft: '40px',
          }}
          placeholder="Search Bond Earning Vaults..."
          onChange={(e) => setVaultSearch(e.target.value)}
          value={vaultSearch}
        />
        <div className={classNames.gridView}>
          {vaultListLoading
            ? Array(10)
                .fill('')
                .map((_, i) => (
                  <div key={i} className={classNames.tableItem}>
                    <Skeleton className={classNames.img} circle />
                    <Skeleton className={classNames.name} width={100} />
                    <Skeleton className={classNames.balance} width={80} />
                  </div>
                ))
            : vaultsList?.balances
                ?.filter(
                  (vault) =>
                    vault.coinSymbol
                      .toLowerCase()
                      .includes(vaultSearch.toLowerCase()) ||
                    vault.coinName
                      .toLowerCase()
                      .includes(vaultSearch.toLowerCase())
                )
                .map((vault) => (
                  <div
                    key={vault.coinSymbol}
                    className={`${classNames.tableItem} ${
                      classNames[vault === vaultSelected]
                    }`}
                    onClick={() => {
                      setVaultSelected(vault);
                      onSuccess();
                    }}
                  >
                    <img
                      className={classNames.img}
                      src={vault.coinImage}
                      alt=""
                    />
                    <div className={classNames.name}>{vault.coinName}</div>
                    <div className={classNames.balance}>
                      {FormatCurrency(vault.coinValue, vault.coinSymbol)}
                    </div>
                  </div>
                ))}
          <div className={`${classNames.tableItem} ${classNames.hide}`} />
          <div className={`${classNames.tableItem} ${classNames.hide}`} />
        </div>
      </div>
    </div>
  );
}

export default VaultSelectBondsModal;
