import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoadingAnim from '../components/LoadingAnim/LoadingAnim';
import { BankContext } from '../context/Context';

function AdminLogin() {
  const history = useHistory();
  const { email, token, userEmail } = useParams();
  const { validateToken, setEmail, setIdToken, setAdmin } =
    useContext(BankContext);
  useEffect(async () => {
    const res = await validateToken(email, token);
    if (res) {
      setAdmin(true);
      setEmail(email);
      setIdToken(token);
      history.push('/transcript');
    }
  }, [email, token, userEmail]);
  return <LoadingAnim />;
}

export default AdminLogin;
