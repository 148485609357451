import axios from 'axios';

export const getAssetsBuy = async (toCurrency, appCode) => {
  // try {
  //   const data = await axios.get(
  //     `https://comms.globalxchange.io/coin/vault/service/payment/stats/get?select_type=instant&to_currency=${toCurrency}&paymentMethod=vault&banker=Global X Change&app_code=${appCode}`
  //   );
  //   return data;
  // } catch (error) {
  //   console.log(error);
  // }
};

export const getAssetsSell = async (fromCurrency, appCode) => {
  // try {
  //   const data = await axios.get(
  //     `https://comms.globalxchange.io/coin/vault/service/payment/stats/get?select_type=instant&from_currency=${fromCurrency}&paymentMethod=vault&banker=Global X Change&app_code=${appCode}`
  //   );
  //   return data;
  // } catch (error) {
  //   console.log(error);
  // }
};

export const getPathId = async (fromCurrency, toCurrency, appCode) => {
  // try {
  //   const data = await axios.get(
  //     `https://comms.globalxchange.io/coin/vault/service/payment/stats/get?select_type=instant&from_currency=${fromCurrency}&to_currency=${toCurrency}&paymentMethod=vault&banker=Global X Change&app_code=${appCode}`
  //   );
  //   return data;
  // } catch (error) {
  //   console.log(error);
  // }
};

export const initiateTrade = async (body) => {
  // try {
  //   const data = await axios.post(
  //     `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
  //     body
  //   );
  //   return data;
  // } catch (error) {
  //   console.log(error);
  // }
};
