import portfolioIcon from '../../static/images/sidebar-icons/portfolio.svg';

import Fund from '../../static/images/sidebar-icons/fund.svg';

import vaultsIcon from '../../static/images/sidebar-icons/vaults.svg';
import commerical from '../../assets/images/commerical.svg';
import affiliateIcon from '../../static/images/sidebar-icons/affiliateIcon.svg';
import playerIcon from '../../static/images/sidebar-icons/playerIcon.svg';
import transcriptIcon from '../../static/images/sidebar-icons/transcriptIcon.svg';
import jobsIcon from '../../static/images/sidebar-icons/jobsIcon.svg';
import libraryIcon from '../../static/images/sidebar-icons/libraryIcon.svg';
import supportIcon from '../../static/images/sidebar-icons/supportIcon.svg';

import w3certifiedIcon from '../../static/images/sidebar-icons/w3certified.svg';

import settingsIcon from '../../static/images/settings/settingsIcon.svg';

import logoutIcon from '../../static/images/settings/logoutIcon.svg';

import qrAddress from '../../static/images/actionSidebar/qrAddress.svg';
import connectIcon from '../../static/images/vaultCpanelIcons/connect.svg';
import withdraw from '../../static/images/vaultCpanelIcons/withdraw.svg';
import arrowUp from '../../static/images/clipIcons/arrowUp.svg';
import arrowDown from '../../static/images/clipIcons/arrowDown.svg';
import plus from '../../static/images/actionSidebar/plus.svg';

import { useCoinContextData } from '../../context/CoinContext';

export const SIDEBAR_CONTENTS = [
  {
    id: 1,
    name: 'Play',
    icon: playerIcon,
  },
  {
    id: 2,
    name: 'Transcript',
    icon: transcriptIcon,
  },
  {
    id: 3,
    name: 'W3 Certified',
    icon: w3certifiedIcon,
  },
  {
    id: 4,
    name: 'TalentAgent',
    icon: jobsIcon,
  },
  {
    id: 5,
    name: 'Affiliate',
    icon: affiliateIcon,
  },
  {
    id: 6,
    name: 'Library',
    icon: libraryIcon,
  },
  {
    id: 7,
    name: 'Vaults',
    icon: vaultsIcon,
    subtitle: 'Your Assets',
  },
  {
    id: 8,
    name: 'Commercials',
    icon: commerical,
  },
];

export const SETTINGS_MENU = (
  onLogoutClick,
  onBankingClick,
  // onTaxesClick,
  onSupportClick,
  onShareTokenClick,
  // onKycClick,
  onTellersClick,
  onSettingsClick,
  onHoldClick
) => [
  // {
  //   id: 1,
  //   icon: displayIcon,
  //   text: 'Display Currency',
  //   subText: 'Currently Indian Rupees',
  // },
  // {
  //   id: 2,
  //   icon: themeIcon,
  //   text: 'Theme',
  //   subText: 'Currently Light Mode',
  // },
  {
    id: 3,
    icon: settingsIcon,
    text: 'Settings',
    subText: 'Enhance Your Security',
    onClick: onSettingsClick,
  },
  // {
  //   id: 3,
  //   icon: securityIcon,
  //   text: 'Security',
  //   subText: 'Enhance Your Security',
  // },
  // {
  //   id: 4,
  //   icon: kycIcon,
  //   text: 'KYC',
  //   subText: 'Currently Not Verified',
  //   onClick: onKycClick,
  // },
  // {
  //   id: 5,
  //   icon: bankingIcon,
  //   text: 'Banking',
  //   subText: 'Attach Your Bank Accounts',
  //   onClick: onBankingClick,
  // },
  // {
  //   id: 6,
  //   text: 'Taxes',
  //   icon: taxesIcon,
  //   onClick: onTaxesClick,
  // },
  // {
  //   id: 7,
  //   text: 'Support',
  //   icon: supportIcon,
  //   subText: 'Need Help? Click Here',
  //   onClick: onSupportClick,
  // },
  // {
  //   id: 8,
  //   text: 'ShareToken',
  //   icon: sharesToken,
  //   subText: 'Want To Own web3today? Click Here',
  //   onClick: onShareTokenClick,
  // },
  // {
  //   id: 9,
  //   icon: tellersIcon,
  //   text: 'Tellers',
  //   subText: 'A Revolution In Remittance',
  //   onClick: onTellersClick,
  // },
  {
    id: 4,
    text: 'TalentAgent',
    icon: jobsIcon,
  },
  {
    id: 6,
    text: 'Library',
    icon: libraryIcon,
  },
  {
    id: 10,
    icon: supportIcon,
    text: 'OnHold',
    subText: 'Help Your customers',
    onClick: onHoldClick,
  },
  {
    id: 11,
    icon: logoutIcon,
    text: 'Logout',
    subText: 'Hope To See You Again Soon',
    onClick: onLogoutClick,
  },
];

export const COIN_ACTIONS = (coinName, setCoinAction, isMarketsPage) => {
  const { coinType } = useCoinContextData();
  const showBuyAndSellOnly = [
    {
      id: 5,
      icon: arrowUp,
      text: 'Buy',
      subText: `Buy ${coinName} With Another Currency`,
      action: () => setCoinAction(['Buy']),
    },
    {
      id: 6,
      icon: arrowDown,
      text: 'Sell',
      subText: `Buy ${coinName} For Another Currency`,
      action: () => setCoinAction(['Sell']),
    },
  ];

  const enableActionsByCondition = isMarketsPage
    ? showBuyAndSellOnly
    : coinType === 'crypto'
    ? [
        {
          id: 1,
          icon: qrAddress,
          text: 'Add',
          subText: `Add ${coinName} From External Wallet`,
          action: () => setCoinAction(['Add']),
        },
        {
          id: 2,
          icon: vaultsIcon,
          text: 'Transfer',
          subText: `Transfer ${coinName} Between Your Vaults`,
          action: () => setCoinAction(['Transfer']),
        },
        {
          id: 3,
          icon: connectIcon,
          text: 'Connect',
          subText: `Send ${coinName} To Your Friends`,
        },
        {
          id: 4,
          icon: withdraw,
          text: 'Withdraw',
          subText: `Send ${coinName} To An External Wallet`,
          action: () => setCoinAction(['Withdraw']),
        },
        ...showBuyAndSellOnly,
      ]
    : [
        {
          id: 1,
          icon: plus,
          text: 'Add',
          subText: `Send ${coinName} From Your Bank Account`,
          action: () => setCoinAction(['Add']),
        },
        {
          id: 2,
          icon: vaultsIcon,
          text: 'Transfer',
          subText: `Transfer ${coinName} Between Your Vaults`,
          action: () => setCoinAction(['Transfer']),
        },
        {
          id: 3,
          icon: connectIcon,
          text: 'Connect',
          subText: `Send ${coinName} To Your Friends`,
        },
        {
          id: 4,
          icon: withdraw,
          text: 'Withdraw',
          subText: `Send ${coinName} To Your Bank Account`,
          action: () => setCoinAction(['Withdraw']),
        },
      ];
  return enableActionsByCondition;
};
