import React, { useState, useEffect } from 'react';
import './toggle.scss';

function Toggle({ toggle = false, setToggle = () => null }) {
  const [check, setCheck] = useState(toggle);

  useEffect(() => {
    setToggle(check);
  }, [check]);

  return (
    <div className="togglecomp">
      <div className="toggle-div">
        <input
          className="tgl1"
          type="checkbox"
          id="switch"
          onClick={(e) => setCheck(e.target.checked)}
          checked={check}
        />
        <label className="tgl2" htmlFor="switch" />
      </div>
      <p>{check ? 'With Balances' : 'Without Balances'}</p>
    </div>
  );
}

export default Toggle;
