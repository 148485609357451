import React, { useEffect, useState, useContext } from 'react';
import './analytics.scss';
// import { gettraderData } from './api';
import { BankContext } from '../../../context/Context';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export default function Index({ points,payOut,settablay }) {
  const {
    email,
    web3UserId,
    setAllArticleRead,
    setAllVideosWatched,
    tostShowOn,
    transcriptSearch,
    setTranscriptSearch,
  } = useContext(BankContext);

  const [storeTrader, setstoreTrader] = useState([]);
  const [loader, setloader] = useState(false);
  //   useEffect(() => {
  //     calltrader();
  //   }, []);
  //   const calltrader = async () => {
  //     setloader(true);
  //     await gettraderData(web3UserId).then((response) => {
  //       let result = response?.data?.data;

  //       setstoreTrader(result);
  //       setloader(false);
  //       // console.log('zxc', result);
  //     });
  //   };

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + 'st';
    }
    if (j == 2 && k != 12) {
      return i + 'nd';
    }
    if (j == 3 && k != 13) {
      return i + 'rd';
    }
    return i + 'th';
  }
  const mS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  console.log(points?.signUpData, 'points?.signUpData');
  return (
    <>
      <div className='handlechangeAnlitys'>
        <div
          className="pointBottomCardsAnalysist"
          style={{
            width: `27rem`,
          }}
        >
          <div className="pointsCards">
            <div
              className="time-points"
              style={{
                color: '#63AD86',
                background: 'rgba(99, 173, 134, 0.1)',
              }}
            >
              {payOut?.dailyLogin} W3T Per Login
            </div>
            <div className="numberCard">
              <h1>{points?.totalDailyPoints?.toFixed(2)}</h1>

              <h2>Daily Login</h2>
            </div>
            <div className="sectionBottomPoits">
              <div className="botteMpoinumberCardntDetial">
                <h3>Last 24HR:</h3>
                <h4>{points?.Last24HoursTotalDailyPoints?.toFixed(2)}</h4>
              </div>
              <div className="botteMpoinumberCardntDetial">
                <h3>% Of Total:</h3>
                <h4>
                  {(
                    points?.totalDailyPoints?.toFixed(2) /
                    points?.totalPoints?.toFixed(2)
                  )?.toFixed(2) == 'Infinity'
                    ? '0.00'
                    : (
                        (points?.totalDailyPoints?.toFixed(2) /
                          points?.totalPoints?.toFixed(2)) *
                        100
                      )?.toFixed(2)}
                  %
                </h4>
              </div>
              <div
                onClick={() => settablay('default')}
                className="analytis"
                style={{
                  background: '#63AD86',
                }}
              >
                {' '}
                Analytics
              </div>
            </div>
          </div>
        </div>
        <div className="tableContainerAnaltics">
          <div className="headings">
            <div>Date</div>
            <div>Credit</div>
            <div>Balance</div>
            <div></div>
          </div>
          <div className="contentTable">
            <>
              {points?.signUpData?.length > 0 &&
                points?.signUpData?.map((item) => {
                  return (
                    <div className="eachTableContent">
                      <div>
                        {
                          mS[
                            item?.createdAt?.split('-')?.[1]?.replace('0', '') -
                              1
                          ]
                        }{' '}
                        {ordinal_suffix_of(
                          item?.createdAt?.split('T')?.[0]?.split('-')?.[2]
                        )}{' '}
                        {item?.createdAt?.split('-')?.[0]} @{' '}
                        {item?.createdAt?.split('T')?.[1]?.split('.')?.[0]}{' '}
                        {item?.createdAt?.split('T')?.[1]?.split(':')?.[0] >= 12
                          ? 'PM'
                          : 'AM'}{' '}
                        IST{' '}
                      </div>
                      <div>{item?.points} W3T</div>
                      <div>-- W3T</div>

                      <div>
                        <div>Transcript</div>
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
        </div>
      </div>
    </>
  );
}
