import React, { useEffect, useState, useContext } from 'react';
import './trader.scss';
import { gettraderData } from './api';
import { BankContext } from '../../../context/Context';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export default function Index() {
  const {
    email,
    web3UserId,
    setAllArticleRead,
    setAllVideosWatched,
    tostShowOn,
    transcriptSearch,
    setTranscriptSearch,
  } = useContext(BankContext);

  const [storeTrader, setstoreTrader] = useState([]);
  const [loader, setloader] = useState(false);
  useEffect(() => {
    calltrader();
  }, []);
  const calltrader = async () => {
    setloader(true);
    await gettraderData(web3UserId).then((response) => {
      let result = response?.data?.data;

      setstoreTrader(result);
      setloader(false);
      // console.log('zxc', result);
    });
  };

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + 'st';
    }
    if (j == 2 && k != 12) {
      return i + 'nd';
    }
    if (j == 3 && k != 13) {
      return i + 'rd';
    }
    return i + 'th';
  }
  const mS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="tableContainerTrader">
      <div className="headings">
        <div>Date</div>
        <div>Rate</div>
        <div>Status</div>
        <div>Actions</div>
      </div>
      <div className="contentTable">
        {/* {allContentsFiltered?.length > 0 &&
          allContentsFiltered?.map((eachItem) => {
            return <EachTableContent {...eachItem} />;
          })} */}

        {loader ? (
          <>
            {[4,1,1,1,1].map((item) => {
              return (
                <div className="eachTableContent">
                  <div>
                    <Skeleton
                      width="8rem"
                      height="2.1rem"
                      style={{ borderRadius: '0px' }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width="8rem"
                      height="2.1rem"
                      style={{ borderRadius: '0px' }}
                    />
                  </div>
                  <div>
                    {' '}
                    <Skeleton
                      width="8rem"
                      height="2.1rem"
                      style={{ borderRadius: '0px' }}
                    />
                  </div>

                  <div>
                    <div>
                      {' '}
                      <Skeleton
                        width="8rem"
                        height="2.1rem"
                        style={{ borderRadius: '0px' }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {storeTrader?.length > 0 &&
              storeTrader?.map((item) => {
                return (
                  <div className="eachTableContent">
                    <div>
                      {
                        mS[
                          item?.createdAt?.split('-')?.[1]?.replace('0', '') - 1
                        ]
                      }{' '}
                      {ordinal_suffix_of(
                        item?.createdAt?.split('T')?.[0]?.split('-')?.[2]
                      )}{' '}
                      {item?.createdAt?.split('-')?.[0]}{' '}
                      {item?.createdAt?.split('T')?.[1]?.split('.')?.[0]}{' '}
                      {item?.createdAt?.split('T')?.[1]?.split(':')?.[0] >= 12
                        ? 'PM'
                        : 'AM'}{' '}
                      IST{' '}
                    </div>
                    <div>
                      {item?.points_requested} W3T For {item?.balance_requested}{' '}
                      INR
                    </div>
                    <div>{capitalizeFirstLetter(item?.is_approved)}</div>

                    <div>
                      <div>Cancel</div>
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
}
