import React, { Fragment } from 'react';
import { useCoinContextData } from '../../context/CoinContext';
import { COIN_ACTIONS } from './constants';
import { updateScroll } from '../../config/constants';
import { useLocation } from 'react-router-dom';
import { SwapAction } from './SwapAction/index.js';

export const CoinActions = ({ sidebarCollapse }) => {
  const iconSize = 60;
  const marginLeftSize = {
    marginLeft: sidebarCollapse ? '15px' : '0',
    padding: sidebarCollapse ? '1rem' : '1rem 1rem 1rem 0',
  };
  const {
    selectedCoin,
    coinAction,
    setCoinAction,
    coinType,
    swapMenu,
    setSwapMenu,
  } = useCoinContextData();
  const { pathname } = useLocation();

  const coinActions = COIN_ACTIONS(
    selectedCoin.coinName,
    setCoinAction
    // pathname.includes('/swap')
  );

  const checkForBreadcrumbs = () => {
    if (coinAction[0] === 'Add') {
      return null;
    } else if (coinAction[0] === 'Transfer') {
      return null;
    } else if (coinAction[0] === 'Withdraw') {
      if (coinType === 'fiat') {
        return <div className="d-flex breadCrumbs"></div>;
      }
      return null;
    }
    return (
      <>
        {coinAction[0] === 'Menu' ? (
          <div
            className="breadCrumbs"
            style={{
              fontSize: '1.1rem',
              fontWeight: '600',
              padding: '0 0 0 1.85rem',
              marginTop: '0',
              marginLeft: '0',
              height: '4.8%',
              borderTop: '0.5px solid #e5e5e5',
              borderBottom: '0.5px solid #e5e5e5',
            }}
          >
            {selectedCoin.coinSymbol} Vault Actions
          </div>
        ) : (
          <div className="breadCrumbs">
            {coinAction[0] !== 'Menu' &&
              coinAction.map((menu, index) => (
                <Fragment key={index}>
                  &nbsp;&gt;&nbsp;
                  <span className="gt" onClick={() => setCoinAction([menu])}>
                    {menu}
                  </span>
                </Fragment>
              ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="sidebar-menu" style={{ borderBottom: '0px' }}>
      {pathname.includes('/swap') || swapMenu ? (
        <SwapAction />
      ) : (
        <>
          {!sidebarCollapse && checkForBreadcrumbs()}

          {coinAction[0] === 'Menu' &&
            coinActions.map((actions) => (
              <Fragment key={actions.id}>
                <div
                  className="d-flex sidebar-container clickable-div"
                  onClick={() => {
                    actions.action();
                    updateScroll();
                  }}
                  style={{
                    opacity: actions.action ? 1 : 0.25,
                    cursor: actions.action ? 'pointer' : '',
                    pointerEvents: !actions.action && 'none',
                    paddingLeft: '1.85rem',
                  }}
                >
                  <img
                    className="sidebar-content-icon"
                    src={actions.icon}
                    alt={actions.text}
                    width={iconSize}
                    style={marginLeftSize}
                  />
                  {!sidebarCollapse && (
                    <div className="col my-auto p-0">
                      <h5 className="action-label">{actions.text}</h5>
                      <div className="getStartedBtn action-sublabel">
                        {actions.subText}
                      </div>
                    </div>
                  )}
                </div>
                <div className="sidebar-spacer" />
              </Fragment>
            ))}
        </>
      )}
    </div>
  );
};
