import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { VaultPageContext } from '../../../context/VaultPageContext';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import { FormatCurrency } from '../../../utils/FunctionTools';
import searchIcon from '../../../static/images/search.svg';
import VaultSelectBondsModal from '../../VaultSelectBondsModal';
import { useAppContextDetails } from '../../../context/AppContext';

function VaultHeaderBonds({ email, vaultSelected, setVaultSelected }) {
  const [openVaultModel, setOpenVaultModel] = useState(false);
  const { assetClassParam, coinParam } = useParams();
  const { vaultsListBonds: vaultsList, vaultsListBondsLoading: loading } =
    useContext(VaultPageContext);
  const { appCurrencySymbol } = useAppContextDetails();

  useEffect(() => {
    try {
      const intialVault = vaultsList?.balances?.find((vault) => {
        if (assetClassParam === 'bonds') {
          return vault.coinSymbol === coinParam;
        }
        return vaultsList?.balances?.[0];
      });
      setVaultSelected(intialVault);
    } catch (error) {}
  }, [vaultsList]);

  return (
    <>
      <div className={classNames.vaultHeader}>
        <div className={classNames.vaultNbalance}>
          <div className={classNames.vault}>
            {loading ? (
              <Skeleton width={150} />
            ) : (
              <>{vaultSelected?.coinName} Bond Earnings Vault</>
            )}
          </div>
          <div className={classNames.balance}>
            {loading ? (
              <Skeleton width={250} />
            ) : (
              <>
                {FormatCurrency(
                  vaultSelected?.coinValue,
                  vaultSelected?.coinSymbol
                )}{' '}
                <small>
                  {appCurrencySymbol}&nbsp;
                  {FormatCurrency(
                    vaultSelected?.value_in_displayCurrency,
                    appCurrencySymbol
                  )}
                </small>
              </>
            )}
          </div>
        </div>
        <div className={classNames.coinSelect}>
          {loading ? (
            <>
              <Skeleton
                className={`${classNames.coinWrap} ${classNames.false}`}
              />
              <Skeleton
                className={`${classNames.coinWrap} ${classNames.false}`}
              />
              <Skeleton
                className={`${classNames.coinWrap} ${classNames.false}`}
              />
            </>
          ) : (
            <>
              {vaultsList?.balances
                ?.slice(0, 3)
                .filter(
                  (vault) => vault.coinSymbol === vaultSelected?.coinSymbol
                )[0] ? (
                ''
              ) : (
                <div className={`${classNames.coinWrap} ${classNames.true}`}>
                  <img src={vaultSelected?.coinImage} alt="" />
                </div>
              )}
              {vaultsList?.balances?.slice(0, 3).map((vault) => (
                <div
                  key={vault.coinSymbol}
                  className={`${classNames.coinWrap} ${
                    classNames[vault.coinSymbol === vaultSelected?.coinSymbol]
                  }`}
                  onClick={() => {
                    setVaultSelected(vault);
                  }}
                >
                  <img src={vault?.coinImage} alt="" />
                </div>
              ))}
            </>
          )}
          <div
            className={classNames.coinWrap}
            onClick={() => {
              setOpenVaultModel(true);
            }}
          >
            <img
              src={searchIcon}
              alt=""
              style={{
                width: 26,
                height: 26,
              }}
            />
          </div>
        </div>
      </div>
      {openVaultModel && (
        <VaultSelectBondsModal
          email={email}
          vaultSelected={vaultSelected}
          setVaultSelected={setVaultSelected}
          onSuccess={() => setOpenVaultModel(false)}
          onClose={() => setOpenVaultModel(false)}
        />
      )}
    </>
  );
}

export default VaultHeaderBonds;
