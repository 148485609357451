import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from './MainNavbar.module.scss';
import { FULL_LOGO } from '../../config';
import OnOutsideClick from '../../utils/OnOutsideClick';
import ios from '../../static/images/platforms/ios.svg';
import play from '../../static/images/platforms/play.svg';
import { Link } from 'react-router-dom';
import SendInviteModal from '../SendInviteModal/SendInviteModal';
import SendInviteModalPlatform from '../SendInviteModal/SendInviteModalPlatform';
import LoginModal from '../LoginModalNew';
import { ReactComponent as ArrowRight } from '../../static/images/arrowRight.svg';

function MainNavbar({ className }) {
  const { pathname } = useLocation();
  const [appMenuOpen, setAppMenuOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const ref = useRef();
  const history = useHistory();
  OnOutsideClick(ref, () => {
    setAppMenuOpen(false);
  });
  const [platform, setPlatform] = useState('');
  const [inviteModal, setInviteModal] = useState(false);
  return (
    <>
      <nav className={`${className} ${classNames.mainNavbar}`}>
        <div
          onClick={() => setNavOpen(!navOpen)}
          className={`${classNames.hamburger} ${classNames.hamburgerSqueeze} ${
            navOpen ? classNames.isActive : ''
          }`}
        >
          <span className={classNames.hamburgerBox}>
            <span className={classNames.hamburgerInner}></span>
          </span>
        </div>
        <Link to="/" className={classNames.logo}>
          <img src={FULL_LOGO} alt="" />
        </Link>
        <div className={classNames.navMenu}>
          <Link
            to="/investors"
            className={`${classNames.navItem} ${
              classNames[pathname === '/investors']
            }`}
          >
            For Investors
          </Link>
          <div
            className={`${classNames.navItem} ${
              classNames['' === 'countries']
            }`}
            onClick={() => {}}
          >
            For Startups
          </div>
          <div className={classNames.navItem} onClick={() => {}}>
            For Educators
          </div>
          <div
            ref={ref}
            className={`${classNames.navItem} ${classNames.btn} ${classNames.appMenu}`}
            onClick={() => setAppMenuOpen(!appMenuOpen)}
          >
            <span className={classNames.btnIn}>Apps</span>
            <div className={`${classNames.menu} ${classNames[appMenuOpen]}`}>
              <div
                className={classNames.btnIos}
                onClick={() => {
                  setPlatform('ios');
                  setInviteModal(true);
                }}
              >
                <img src={ios} alt="" className={classNames.icon} />
                <div className={classNames.texts}>
                  <div className={classNames.downOn}>Download On</div>
                  <div className={classNames.sorce}>App Store</div>
                </div>
              </div>
              <div
                className={classNames.btnAndroid}
                onClick={() => {
                  setPlatform('android');
                  setInviteModal(true);
                }}
              >
                <img src={play} alt="" className={classNames.icon} />
                <div className={classNames.texts}>
                  <div className={classNames.downOn}>Download On</div>
                  <div className={classNames.sorce}>Google Play</div>
                </div>
              </div>
              <div
                className={classNames.btnLogin}
                onClick={() => setLoginModalOpen(true)}
              >
                <span>Login</span>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {inviteModal &&
        (platform ? (
          <SendInviteModal
            onClose={() => {
              setPlatform('');
              setInviteModal(false);
            }}
            platform={platform}
          />
        ) : (
          <SendInviteModalPlatform
            onClose={() => {
              setPlatform('');
              setInviteModal(false);
            }}
            setPlatform={setPlatform}
          />
        ))}
      {loginModalOpen && (
        <LoginModal
          onClose={() => setLoginModalOpen(false)}
          onSuccess={() => {
            setLoginModalOpen(false);
            history.push('/transcript');
          }}
        />
      )}
      <AnimatePresence exitBeforeEnter initial={false}>
        {navOpen && (
          <SidebarMobile
            onClose={() => setNavOpen(false)}
            setPlatform={setPlatform}
            setInviteModal={setInviteModal}
          />
        )}
      </AnimatePresence>
    </>
  );
}

function SidebarMobile({ onClose, setPlatform, setInviteModal }) {
  const { pathname } = useLocation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classNames.sidebarMobile}
      transition={{ ease: 'easeOut', duration: 0.5 }}
    >
      <div className={classNames.overlay} onClick={() => onClose()} />
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: 0 }}
        exit={{ x: '-100%' }}
        transition={{ ease: 'easeOut', duration: 0.5 }}
        className={classNames.menuWrap}
      >
        <Link to="/signup" className={classNames.header}>
          Get Started
          <ArrowRight className={classNames.icon} />
        </Link>
        <div className={classNames.navMenu}>
          <Link
            to="/"
            className={`${classNames.navItem} ${classNames[pathname === '/']}`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`${classNames.navItem} ${
              classNames[pathname === '/about']
            }`}
          >
            About
          </Link>
          <Link
            to="/markets"
            className={`${classNames.navItem} ${
              classNames[pathname === '/markets']
            }`}
          >
            Markets
          </Link>
          <Link
            to="/investors"
            className={`${classNames.navItem} ${
              classNames[pathname === '/investors']
            }`}
          >
            For Investors
          </Link>
          <div
            className={`${classNames.navItem} ${
              classNames['' === 'countries']
            }`}
            onClick={() => {}}
          >
            For Startups
          </div>
          <div className={classNames.navItem} onClick={() => {}}>
            For Educators
          </div>

          <div
            className={classNames.btnIos}
            onClick={() => {
              setPlatform('ios');
              setInviteModal(true);
            }}
          >
            <img src={ios} alt="" className={classNames.icon} />
            <div className={classNames.texts}>
              <div className={classNames.downOn}>Download On</div>
              <div className={classNames.sorce}>App Store</div>
            </div>
          </div>
          <div
            className={classNames.btnAndroid}
            onClick={() => {
              setPlatform('android');
              setInviteModal(true);
            }}
          >
            <img src={play} alt="" className={classNames.icon} />
            <div className={classNames.texts}>
              <div className={classNames.downOn}>Download On</div>
              <div className={classNames.sorce}>Google Play</div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default MainNavbar;
