import React, { useEffect } from 'react';
import Layout from '../../Layout/Layout';
import './affiliate.scss';
import fulllogo from '../../static/images/affiliate/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import img1 from '../../static/images/malls/ham.svg';
import ablogo from '../../static/images/affiliate/ablogo.svg';
import loader from '../../static/images/affiliate/loader.svg';
import country from '../../static/images/affiliate/country.svg';
import filter from '../../static/images/affiliate/filter.svg';
import ddown from '../../static/images/malls/ddown.svg';
import leftarr from '../../static/images/affiliate/leftarr.svg';
import guest from '../../static/images/guest.jpg';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { affiliateBalances, levelBasedData } from './api';
import Skeleton from 'react-loading-skeleton';

const Leveldata = () => {
  const { email, username, name, profileImg } = useContext(BankContext);
  const {
    affiliateTab,
    setaffiliateTab,
    levelData,
    setLevelData,
    balances,
    setBalances,
    showleveldata,
    setShowLevelData,
    leveltab,
    setLevelTab,
  } = useAppContextDetails();
  const history = useHistory();

  if (!email) {
    history.push('/');
  }

  useEffect(() => {
    let product = localStorage.getItem('leveldata');
    setShowLevelData(JSON.parse(product));

    let mailId = email;

    affiliateBalances(mailId).then((response) => {
      let result = response?.data;
      setBalances(result);
    });
  }, []);

  return (
    <Layout active="affiliate" className="affiliate" hideFooter>
      <>
        <div className="affiliate-navbar">
          <div>
            <img src={img1} alt="" />
          </div>
          <div className="affiliate-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-affiliate">
            {/* <div className="all">
              <p>All Users</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={profileImg ? profileImg : guest}
                alt=""
                style={{ height: '50px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{name ? name : username}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '45px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>${balances?.gxbroker_balance?.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-space"
          style={{ borderBottom: '0.25px solid #E7E7E7', paddingRight: '0' }}
        >
          <div className="optionss" style={{ width: '100%' }}>
            <div style={{ display: 'flex', gap: '80px', color: '#18191D' }}>
              <div
                className={`each-option ${
                  leveltab === 'levels' ? 'each-option-selected' : ''
                }`}
                // style={{
                //   fontWeight: leveltab === 'levels' ? '600' : '400',
                // }}
              >
                <div>
                  <p>Levels</p>
                </div>
              </div>
            </div>
            <div className="top-icons">
              <div style={{ borderLeft: '0.25px solid #E7E7E7' }}>
                <img src={filter} alt="filter" />
              </div>
              <div>
                <img src={country} alt="country" />
              </div>
              <div>
                <img src={loader} alt="" />
              </div>
              <div>
                <img src={search} alt="search" />
              </div>
            </div>
          </div>
        </div>

        <div className="levell">
          <div
            style={{ display: 'flex', flexDirection: 'row' }}
            className="backarrow"
          >
            <img
              src={leftarr}
              alt=""
              style={{ height: '100%' }}
              onClick={() => history.push('/affiliate')}
            />
          </div>
          <div style={{ width: '20%', alignItems: 'flex-start' }}>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              DDLevel <span>{showleveldata?.level}</span>
            </p>
            <p>
              Users: <span>{showleveldata?.count}</span>
            </p>
          </div>
          <div style={{ width: '12%' }}>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              --
            </p>
            <p>Customers</p>
          </div>
          <div style={{ width: '12%' }}>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              --
            </p>
            <p>Affiliates</p>
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              ${showleveldata?.total_revenue?.toFixed(2)}
            </p>
            <p>Volume</p>
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              ${showleveldata?.total_earnings?.toFixed(2)}
            </p>
            <p>My Revenue</p>
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: '700',
                color: '#18191D',
              }}
            >
              {showleveldata?.total_count_log}
            </p>
            <p>Revenue Txn's</p>
          </div>
        </div>
        <div className="levell-header">
          <div style={{ width: '22%' }}>Name</div>
          <div style={{ width: '12%' }}>Customer</div>
          <div style={{ width: '12%' }}>Affiliate</div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            Volume
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            My Revenue
          </div>
          <div
            style={{
              width: '12%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            Revenue Txn’s
          </div>
        </div>
        <div className="overall-lvl">
          {showleveldata?.users?.map((e, i) => {
            return (
              <div className="levell-data" key={i}>
                <div style={{ display: 'flex', gap: '10px', width: '22%' }}>
                  <img
                    src={e?.profile_img}
                    alt=""
                    style={{
                      height: '50px',
                      width: '50px',
                      borderRadius: '50%',
                    }}
                  />
                  <div>
                    <p
                      style={{
                        fontSize: '1.15rem',
                        fontWeight: '600',
                        color: '#050505',
                      }}
                    >
                      {e?.name}
                    </p>
                    <p>{e?.email}</p>
                  </div>
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#050505',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  --
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#050505',
                  }}
                >
                  {e?.broker_license_active === true ? 'Active' : 'Inactive'}
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#18191D',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  ${e?.comData?.total_revenue?.toFixed(2)}
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#18191D',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  ${e?.comData?.total_earnings?.toFixed(2)}
                </div>
                <div
                  style={{
                    width: '12%',
                    fontSize: '1.15rem',
                    fontWeight: '600',
                    color: '#18191D',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {e?.comData?.total_count_log}
                </div>
              </div>
            );
          })}
        </div>
      </>
    </Layout>
  );
};

export default Leveldata;
