import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { updateScroll } from '../../../config/constants';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';

export const WithdrawActionFooter = () => {
  const {
    setCoinAction,
    step,
    setStep,
    addressTerm,
    fromTerm,
    toTerm,
    checkedOne,
    checkedTwo,
    setInitiateWithdraw,
    setIsWithdrawConfirmed,
    setSendVerificationMail,
    selectedCoin,
    otp,
    coinType,
    withdrawForexStep,
    setWithdrawForexStep,
    withdrawForexEnteredAmount,
    withdrawForexOtcDesk,
    forexPathId,
    setForexPathId,
    setForexQuote,
    checkedOneForex,
    otpForex,
    setUpdatedForexBalance,
    setUpdateVaultData,
    setWithdrawForexEnteredAmount,
    setAddressTerm,
    setFromTerm,
    setToTerm,
    withdrawForexCurrency,
    withdrawForexCountry,
    withdrawForexPaymentMethod,
  } = useCoinContextData();

  const { appCode, appColorCode } = useAppContextDetails();
  const history = useHistory();
  const [isOtp, setIsOtp] = useState(false);
  const { token, email, profileId } = useContext(BankContext);

  const handleBreadCrumb = () => {
    switch (step) {
      case 1: {
        setCoinAction(['Menu']);
        updateScroll();
        break;
      }
      case 2: {
        setStep(1);
        updateScroll();
        setAddressTerm('');
        setFromTerm('');
        setToTerm('');
        break;
      }
      case 3: {
        setStep(2);
        updateScroll();
        break;
      }
      case 4: {
        setStep(3);
        updateScroll();
        break;
      }
      case 5: {
        setStep(4);
        updateScroll();
        break;
      }
      default:
    }
  };

  if (coinType === 'fiat') {
    if (withdrawForexStep === '4')
      return (
        <div className="withdraw-footer">
          <div
            className="back1"
            key="back1"
            onClick={() => {
              setWithdrawForexStep('3');
              updateScroll();
            }}
          >
            <h6>Back</h6>
          </div>
          <div
            className={
              withdrawForexEnteredAmount !== '' ? 'nextbtn' : 'nextbtn1'
            }
            key="nextbtn"
            style={{
              backgroundColor: appColorCode,
              opacity: withdrawForexEnteredAmount !== '' ? 1 : 0.25,
              color: withdrawForexEnteredAmount !== '' ? 'white' : '',
              cursor:
                withdrawForexEnteredAmount !== '' ? 'pointer' : 'not-allowed',
              pointerEvents: withdrawForexEnteredAmount !== '' ? '' : 'none',
            }}
            onClick={() => {
              setWithdrawForexStep('5');
              updateScroll();
            }}
          >
            <h6>Next Step</h6>
          </div>
        </div>
      );
    else if (withdrawForexStep === '5')
      return (
        <div className="withdraw-footer">
          <div
            className="back1"
            key="back1"
            onClick={() => {
              setWithdrawForexStep('4');
              setWithdrawForexEnteredAmount('');
              setForexPathId('');
              updateScroll();
            }}
          >
            <h6>Back</h6>
          </div>
          <div
            className={checkedOneForex === true ? 'nextbtn' : 'nextbtn1'}
            key="nextbtn"
            style={{
              backgroundColor: appColorCode,
              opacity: checkedOneForex === true ? 1 : 0.25,
              color: checkedOneForex === true ? 'white' : '',
              cursor: checkedOneForex === true ? 'pointer' : 'not-allowed',
              pointerEvents: checkedOneForex === true ? '' : 'none',
            }}
            onClick={() => {
              setWithdrawForexStep('6');
              updateScroll();
            }}
          >
            <h6>Confirm</h6>
          </div>
        </div>
      );
    else if (withdrawForexStep === '6')
      return (
        <div className="withdraw-footer">
          <div
            className="back1"
            key="back1"
            onClick={() => {
              setCoinAction(['Menu']);
              setWithdrawForexStep('1');
              setWithdrawForexEnteredAmount('');
              updateScroll();
            }}
          >
            <h6>Cancel</h6>
          </div>
          <div
            className={otpForex.length === 6 ? 'nextbtn' : 'nextbtn1'}
            key="nextbtn"
            style={{
              backgroundColor: appColorCode,
              opacity: otpForex.length === 6 ? 1 : 0.25,
              color: otpForex.length === 6 ? 'white' : '',
              cursor: otpForex.length === 6 ? 'pointer' : 'not-allowed',
              pointerEvents: otpForex.length === 6 ? '' : 'none',
            }}
            onClick={() => {
              setWithdrawForexStep('7');
              updateScroll();
            }}
          >
            <h6>Confirm</h6>
          </div>
        </div>
      );
    else if (withdrawForexStep === '7')
      return (
        <div className="withdraw-footer">
          <div
            className="back1"
            key="back1"
            onClick={() => {
              setCoinAction(['Menu']);
              setWithdrawForexStep('1');
              setWithdrawForexEnteredAmount('');
              updateScroll();
            }}
          >
            <h6>Close</h6>
          </div>
          <div
            className={
              withdrawForexEnteredAmount !== '' ? 'nextbtn' : 'nextbtn1'
            }
            key="nextbtn"
            style={{
              backgroundColor: appColorCode,
              opacity: withdrawForexEnteredAmount !== '' ? 1 : 0.25,
              color: withdrawForexEnteredAmount !== '' ? 'white' : '',
              cursor: withdrawForexEnteredAmount !== '' ? 'pointer' : '',
            }}
            onClick={() => {
              setWithdrawForexStep('1');
              updateScroll();
              history.push(
                `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
              );
            }}
          >
            <h6>Go To Vault</h6>
          </div>
        </div>
      );
    return null;
  }

  switch (step) {
    case 1:
      return (
        <div className="withdraw-footer">
          <div className="back1" key="back1" onClick={() => handleBreadCrumb()}>
            <h6>Back</h6>
          </div>
          <div
            className={addressTerm !== '' ? 'nextbtn' : 'nextbtn1'}
            key="nextbtn"
            style={{
              backgroundColor: appColorCode,
              opacity: addressTerm !== '' ? 1 : 0.25,
              color: addressTerm !== '' ? 'white' : '',
              cursor: addressTerm !== '' ? 'pointer' : '',
            }}
            onClick={() => {
              setStep(2);
              updateScroll();
            }}
          >
            <h6>Next Step</h6>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="withdraw-footer">
          <div className="back1" key="back2" onClick={() => handleBreadCrumb()}>
            <h6>Back</h6>
          </div>
          <div
            className="nextbtn"
            key="nextbtn1"
            style={{
              backgroundColor: appColorCode,
              opacity: fromTerm !== '' && toTerm !== '' ? 1 : 0.25,
              color: fromTerm !== '' && toTerm !== '' ? 'white' : '',
              cursor: fromTerm !== '' && toTerm !== '' ? 'pointer' : 'none',
              pointerEvents: fromTerm !== '' && toTerm !== '' ? '' : 'none',
            }}
            disabled={fromTerm === '' && toTerm === '' ? 'disabled' : ''}
            onClick={() => {
              setStep(3);
              setInitiateWithdraw(true);
              updateScroll();
            }}
          >
            <h6>Next Step</h6>
          </div>
        </div>
      );

    case 3:
      return (
        <div className="withdraw-footer">
          <div className="back1" key="back3" onClick={() => handleBreadCrumb()}>
            <h6>Back</h6>
          </div>
          <div
            className="nextbtn"
            key="nextbtn2"
            style={{
              backgroundColor: appColorCode,
              opacity: checkedOne && checkedTwo ? 1 : 0.25,
              color: checkedOne && checkedTwo ? 'white' : '',
              cursor: checkedOne && checkedTwo ? 'pointer' : '',
            }}
            disabled={checkedOne && checkedTwo ? '' : 'disabled'}
            onClick={() => {
              setSendVerificationMail(true);
              setStep(4);
              updateScroll();
            }}
          >
            <h6>Confirm</h6>
          </div>
        </div>
      );

    case 4:
      return (
        <div className="withdraw-footer">
          <div className="back1" key="back3" onClick={() => handleBreadCrumb()}>
            <h6>Back</h6>
          </div>
          <div
            className="nextbtn"
            key="nextbtn2"
            style={{
              backgroundColor: appColorCode,
              opacity: otp.length === 6 ? 1 : 0.25,
              color: otp.length === 6 ? 'white' : '',
              cursor: otp.length === 6 ? 'pointer' : 'none',
              pointerEvents: otp.length === 6 ? '' : 'none',
            }}
            disabled={otp.length === 6 ? '' : 'disabled'}
            onClick={() => {
              setIsWithdrawConfirmed(true);
              setInitiateWithdraw(true);
              setStep(5);
              updateScroll();
            }}
          >
            <h6>Confirm</h6>
          </div>
        </div>
      );

    case 5:
      return (
        <div className="withdraw-footer">
          <div
            className="back1"
            key="back3"
            onClick={() => {
              setCoinAction(['Menu']);
              setStep(1);
              setAddressTerm('');
              setFromTerm('');
              setToTerm('');
              updateScroll();
            }}
          >
            <h6>Close</h6>
          </div>
          <div
            className="nextbtn"
            key="nextbtn2"
            style={{
              backgroundColor: appColorCode,
              color: 'white',
              cursor: 'pointer',
              opacity: 1,
            }}
            onClick={() => {
              history.push(
                `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
              );
            }}
          >
            <h6>Go to Vault</h6>
          </div>
        </div>
      );

    default:
      return <></>;
  }
};
