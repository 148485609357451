import React, { useContext } from 'react';
import MobileNavbar from './MobileNavbar';
import MobileFooter from './MobileFooter';
import useWindowDimensions from '../utils/WindowSize';
import MobileFooterMain from './MobileFooterMain';
import { BankContext } from '../context/Context';

function MobileLayout({
  children,
  active,
  className,
  setMenuSelected,
  menuSelected,
  footerMain,
  mobileFooter,
}) {
  const { height } = useWindowDimensions();
  const { videoShow } = useContext(BankContext);
  return (
    <div className="mobile-layout" style={{ height, maxHeight: '100vh' }}>
      <MobileNavbar active={active} />
      <div className={`mobile-content  ${className}`}>{children}</div>
      {footerMain ? (
        <MobileFooterMain active={active} />
      ) : (
        mobileFooter || (
          <MobileFooter
            menuSelected={menuSelected}
            setMenuSelected={setMenuSelected}
          />
        )
      )}
    </div>
  );
}

export default MobileLayout;
