import React, { useState, useEffect, useContext } from 'react';
import './ads.scss';
import { getadds, getNews, getRewards,getVideo  } from './api';
import usersinfo from '../../../assets/images/usersinfo.svg';
import closemarks from '../../../assets/images/closemarks.svg';
import { BankContext } from '../../../context/Context';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import Skeleton from 'react-loading-skeleton';
import ReactPlayer from 'react-player';
export default function Index() {
  const [cardInfo, setcardInfo] = useState([]);
  const [tab, settab] = useState('main');
  const [info, setinfo] = useState({});
  const [newdata, setnewdata] = useState([]);
  const [drawerTable, setdrawerTable] = useState('first');
  const [inputvalue, setinputvalue] = useState('');
  const [drawerToggle, setsetdrawerToggle] = useState(false);
  const [sucessData, setsucessData] = useState([]);
  const [loader, setloader] = useState(false);
  const { email, token, tostShowOn, setUserSelectedOnHold } =
    useContext(BankContext);
  useEffect(() => {
    getaddsFunction();
    return () => {};
  }, []);

  const [videodata, setvideodata] = useState({});
  const [storeVideo, setstoreVideo] = useState({});

  useEffect(() => {
    getaddsFunction();
    return () => {};
  }, []);
  const [stickyClass, setStickyClass] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", stickNavbar);

  //   return () => {
  //     window.removeEventListener("scroll", stickNavbar);
  //   };
  // }, []);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      if (size.x > 700) {
        if (windowHeight < 200) {
          setStickyClass(false);
        } else {
          setStickyClass(true);
        }
      }
    }
  };
  window.addEventListener("scroll", stickNavbar);


  const getVideoFunction = async (e) => {
    await getVideo(e).then((response) => {
      let result = response?.data?.users;
      setvideodata(result);
      // console.log("zxc", result);
    });
  };

  const getNewsFunction = async (e) => {
    await getNews(e).then((response) => {
      let result = response?.data?.videoCampaigns;
      //   setcardInfo(result);
      setnewdata(result);
      console.log("1231e23d", result);
    });
  };
  console.log(info, "setinfo");

  const functionMove = (e) => {
    setstoreVideo(e);
    // history.push(`/earn/ads/${info?.username}/${e?.video_nickname}`);
    // settab("videopage");
  };
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  useEffect(() => (window.onresize = updateSize), []);
  const getaddsFunction = async () => {
    setloader(true);
    await getadds().then((response) => {
      let result = response?.data?.users;
      setcardInfo(result);
      setloader(false);
      // console.log('zxc', result);
    });
  };
  const handle = (e) => {
    setinfo(e);
    settab('inner');
    getNewsFunction(e?.email);
    getVideoFunction(e?.email);
  };
  const handleFunction = () => {
    setdrawerTable('second');
  };

  const getrewordFunction = async () => {
    let obj = {
      token: token,
      email: email,
      app_code: 'web3today',
      videoCode: inputvalue,
    };
    await getRewards(obj).then((response) => {
      let result = response?.data;
      if (result?.status) {
        setsucessData(result);
        setdrawerTable('sucess');
      }
      // setcardInfo(result);
      // console.log('zxc', result);
    });
  };


  console.log(info, 'setinfo');

  const drawerFuntion = () => {
    switch (drawerTable) {
      case 'sucess':
        return (
          <>
            <div className="drawerAds">
              <div className="topHeaderDrawerAds">
                <h1>
                  You Have Successfully Claimed {sucessData?.userData?.amount}{' '}
                  {sucessData?.userData?.coin} To Your Web3Today Account
                </h1>
              </div>
              <div className="cardsbottomDrawer">
                <div className="leftcardlistdrawer">
                  <div className="textinside">Previous Balance</div>
                  <div className="textinside">
                    {sucessData?.userData?.current_balance?.toFixed(2)}{' '}
                    {sucessData?.userData?.coin}
                  </div>
                </div>
                <div className="leftcardlistdrawer">
                  <div className="textinside">Updated Balance</div>
                  <div className="textinside">
                    {sucessData?.userData?.updated_balance?.toFixed(2)}{' '}
                    {sucessData?.userData?.coin}
                  </div>
                </div>
                <div className="labelbottom">
                  <label
                    className="submit"
                    onClick={() => setsetdrawerToggle(false)}
                  >
                    Close
                  </label>
                </div>
              </div>
            </div>
          </>
        );

        break;
      case 'second':
        return (
          <>
            <div className="drawerAds">
              <div className="topHeaderDrawerAds">
                <h1> Select Action</h1>
                <img
                  src={closemarks}
                  onClick={() => setsetdrawerToggle(false)}
                />
              </div>
              <div className="cardsbottomDrawer">
                <input
                  value={inputvalue}
                  placeholder="Enter Code"
                  onChange={(e) => setinputvalue(e.target.value)}
                />

                <div className="labelbottom">
                  <label className="submit" onClick={getrewordFunction}>
                    Submit Code
                  </label>
                  <label
                    className="back"
                    onClick={() => setdrawerTable('first')}
                  >
                    Go Back
                  </label>
                </div>
              </div>
            </div>
          </>
        );

      case 'first':
        return (
          <>
            <div className="drawerAds">
              <div className="topHeaderDrawerAds">
                <h1> Select Action</h1>
                <img
                  src={closemarks}
                  onClick={() => setsetdrawerToggle(false)}
                />
              </div>
              <div className="cardsbottomDrawer">
                <div className="leftcardlistdrawer">
                  <div
                    className="textinside"
                    style={{
                      opacity: '0.5',
                    }}
                  >
                    See Campaign
                  </div>
                </div>
                <div className="leftcardlistdrawer" onClick={handleFunction}>
                  <div className="textinside">Claim Direct Reward</div>
                </div>
                <div className="leftcardlistdrawer">
                  <div
                    className="textinside"
                    style={{
                      opacity: '0.5',
                    }}
                  >
                    Claim Social Meida Reward
                  </div>
                </div>
              </div>
            </div>
          </>
        );

        break;

      default:
        break;
    }
  };

  const swtchtabfunction = () => {
    switch (tab) {
      case 'main':
        return (
          <>
            {loader ? (
              <>
                {Array(25)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div class="flip">
                        <Skeleton
                          Width="100%"
                          height="231px"
                          style={{ borderRadius: '0px' }}
                        />
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="ads">
                  {cardInfo?.map((item) => {
                    if (item?.advertiser_profile)
                      return (
                        <div class="flip" onClick={() => handle(item)}>
                          <div class="front">
                            <img src={item?.advertiser_profile?.profile_img} />
                          </div>
                          <div class="back">
                            <img src={item?.advertiser_profile?.profile_img} />
                            <p>{item?.advertiser_profile?.name}</p>
                          </div>
                        </div>
                      );
                  })}
                  <>
                    {Array(25)
                      .fill(' ')
                      .map((item, index) => {
                        return (
                          <div class="flip">
                            <div class="front"></div>
                            <div class="back">
                              <p></p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                </div>
              </>
            )}
          </>
        );
        break;
      case 'inner':
        return (
          <div className="innerAdsDash">
          <div
            className="imageSectionadds"
            style={{
              background: info?.advertiser_profile?.color_code,
            }}
          >
            <img src={info?.advertiser_profile?.whiteinvrersedlogo} />
            <div
              className="allbrands"
              onClick={() => {
                settab("main");
                // history.push(`/earn/ads`);
              }}
            >
              {/* <img src={usersinfo}/> */}
              All Brands
            </div>
          </div>

          <div className="headerDash">
            {stickyClass ? (
              <div className="sticky">
                <div
                  className="cardAdds"
                  style={{
                    background: info?.advertiser_profile?.color_code,
                    height: "100%",
                    width: "11rem",
                  }}
                >
                  <img src={info?.advertiser_profile?.whiteinvrersedlogo} />
                </div>

                <div className="cardAdds">
                  <h1>0</h1>
                  <h2>Total Ads</h2>
                </div>
                <div className="cardAdds">
                  <h1>0.00</h1>
                  <h2>Total Payout</h2>
                </div>
                <div className="cardAdds">
                  <h1>0</h1>
                  <h2>Live Ads</h2>
                </div>
                <div className="cardAdds">
                  <h1>0.00</h1>
                  <h2>Avl Payout</h2>
                </div>
                <div className="cardAdds">
                  <label
                    className="signup"
                    style={{
                      background: info?.advertiser_profile?.color_code,
                    }}
                    onClick={() =>
                      window.open(
                        `http://${info?.advertiser_profile?.call_action_link}`,
                        "_blank"
                      )
                    }
                  >
                    Signup
                  </label>
                  <label
                    className="website"
                    onClick={() =>
                      window.open(
                        `http://${info?.advertiser_profile?.website_link}`,
                        "_blank"
                      )
                    }
                  >
                    Website
                  </label>
                </div>
              </div>
            ) : (
              <div className="topheaderadd">
                {/* <div
                className="cardAdds"
                style={{
                  background: info?.advertiser_profile?.color_code,
                  height: "100%",
                  width: "11rem",
                }}
              >
                <img src={info?.advertiser_profile?.whiteinvrersedlogo} />
              </div>
             */}

                <div className="cardAdds">
                  <h1>0</h1>
                  <h2>Total Ads</h2>
                </div>
                <div className="cardAdds">
                  <h1>0.00</h1>
                  <h2>Total Payout</h2>
                </div>
                <div className="cardAdds">
                  <h1>0</h1>
                  <h2>Live Ads</h2>
                </div>
                <div className="cardAdds">
                  <h1>0.00</h1>
                  <h2>Avl Payout</h2>
                </div>
                <div className="cardAdds">
                  <label
                    className="signup"
                    style={{
                      background: info?.advertiser_profile?.color_code,
                    }}
                    onClick={() =>
                      window.open(
                        `http://${info?.advertiser_profile?.call_action_link}`,
                        "_blank"
                      )
                    }
                  >
                    Signup
                  </label>
                  <label
                    className="website"
                    onClick={() =>
                      window.open(
                        `http://${info?.advertiser_profile?.website_link}`,
                        "_blank"
                      )
                    }
                  >
                    Website
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="contnetaddtop">
            <div className="cardDash">
              {newdata?.map((item) => {
                return (
                  <div
                    className="lefsiteadd"
                    onClick={() => setsetdrawerToggle(true)}
                  >
                    <div
                      className="borderCard"
                      style={{
                        backgroundImage: `url(${item?.video_thumbnail})`,
                      }}
                    >
                      <div className="blacktinit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="58"
                          height="42"
                          viewBox="0 0 58 42"
                          fill="none"
                        >
                          <path
                            d="M57.6926 11.4411C57.5309 9.6608 57.2987 8.07018 56.9967 6.6668C56.6518 5.09245 55.9015 3.7649 54.7474 2.68651C53.5927 1.60812 52.2495 0.982615 50.7176 0.809074C45.9277 0.269316 38.688 0 28.9997 0C19.3109 0 12.0717 0.269316 7.28167 0.809074C5.74927 0.982513 4.41169 1.60812 3.26822 2.68651C2.12414 3.7649 1.37953 5.09245 1.03511 6.6668C0.711355 8.07018 0.46895 9.6608 0.306767 11.4411C0.145197 13.2215 0.0531061 14.6943 0.0318227 15.8598C0.0100277 17.0239 0 18.6427 0 20.7148C0 22.7857 0.0106417 24.4039 0.031925 25.57C0.0532084 26.7341 0.145197 28.2076 0.306869 29.9873C0.469052 31.7675 0.7001 33.3596 1.00257 34.7615C1.3476 36.3384 2.09784 37.6648 3.25195 38.7432C4.40606 39.8228 5.74937 40.4489 7.28167 40.6206C12.0717 41.1604 19.3108 41.4296 28.9997 41.4296C38.688 41.4296 45.9272 41.1604 50.7176 40.6206C52.2495 40.4483 53.5871 39.8228 54.7313 38.7432C55.8746 37.6648 56.6192 36.3384 56.9642 34.7615C57.2879 33.3596 57.5303 31.7675 57.6926 29.9873C57.8541 28.2071 57.9463 26.7341 57.9675 25.57C57.9888 24.4044 58 22.7857 58 20.7148C58 18.6427 57.9888 17.0245 57.9675 15.8598C57.9463 14.6937 57.8541 13.2208 57.6926 11.4411ZM40.4577 22.4619L23.8861 32.819C23.5836 33.035 23.2172 33.1428 22.7858 33.1428C22.462 33.1428 22.1276 33.057 21.7826 32.8836C21.0706 32.4953 20.7142 31.8923 20.7142 31.072V10.3571C20.7142 9.53801 21.0699 8.93246 21.7826 8.54424C22.5159 8.15602 23.2173 8.17915 23.8861 8.60871L40.4577 18.9658C41.1052 19.3341 41.429 19.9164 41.429 20.7142C41.4284 21.5139 41.1046 22.0962 40.4577 22.4619Z"
                            fill="white"
                          />
                        </svg>
                        Watch Video & Earn
                      </div>
                      {/* <ReactPlayer
                        light={`${item?.video_thumbnail}`}
                        alt="Thumbnail"
                        className="react-player"
                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                        width="100%"
                        height="100%"
                      /> */}
                    </div>

                    <h1
                      style={{
                        display: "block",
                        textAlign: "start",
                      }}
                    >
                      {item?.video_title}
                    </h1>
                    <p>{item?.video_description}</p>
                    <div className="labelSection">
                      <label className="payout">
                        {item?.payout_amount} {item?.payOutCoin} Payout
                      </label>
                      <label
                        className="payoutLeft"
                        style={{
                          background: info?.advertiser_profile?.color_code,
                        }}
                      >
                        {item?.left_redeem_count} Payouts Left
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        );

      default:
        break;
    }
  };
  return (
    <>
      {swtchtabfunction()}

      {drawerToggle ? <>{drawerFuntion()}</> : ''}
    </>
  );
}
