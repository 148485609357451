import React, { useEffect } from 'react';
import Layout from '../../Layout/Layout';
import './affiliate.scss';
import affiliateLogo from '../../static/images/affiliateLogo.svg';
import fulllogo from '../../static/images/affiliate/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import searchIcon from '../../static/images/search.svg';
import img1 from '../../static/images/malls/ham.svg';
import img2 from '../../static/images/malls/close.svg';
import ablogo from '../../static/images/affiliate/ablogo.svg';
import ablogo1 from '../../static/images/affiliate/ablogo1.svg';
import heart from '../../static/images/affiliate/heart.svg';
import plus from '../../static/images/affiliate/plus.svg';
import ddown from '../../static/images/malls/ddown.svg';
import crm from '../../static/images/affiliate/crmlogo.svg';
import comp from '../../static/images/affiliate/complogo.svg';
import guest from '../../static/images/affiliate/userlogo.svg';
import copy from '../../static/images/affiliate/copy.svg';
import write from '../../static/images/affiliate/write.svg';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import {
  affiliateBalances,
  affiliateId,
  affiliateStats,
  allUsers,
  levelBasedData,
  uplineData,
} from './api';
import Skeleton from 'react-loading-skeleton';
import AffiliateBank from './AffiliateBank';
import AddUser from './AddUser';
import ChainView from './ChainView';
import Invite from './Invite';
import Withdraw from './Withdraw';
import axios from 'axios';

const AffiliatePage = () => {
  const {
    affiliateTab,
    setaffiliateTab,
    levelData,
    setLevelData,
    balances,
    setBalances,
    showleveldata,
    setShowLevelData,
    adduser,
    setAdduser,
    adduserStep,
    setAdduserStep,
    affiliateid,
    setAffiliateId,
    allaffiliatedata,
    setAllaffiliatedata,
    chainuserData,
    setchainUserData,
    appColorCode,
    affStats,
    setAffStats,
    appName,
    upline,
    setUpline,
    invite,
    setInvite,
    setuserfilteredAff,
    iswithdraw,
    setIswithdraw,
    //update user name
    newUserNameSidebar,
    setnewUserNameSidebar,
    newUserName,
    setnewUserName,
    usernameExist,
    setusernameExist,
    drawOpen,
    setDrawOpen,
    hamMenu,
    setHamMenu,
  } = useAppContextDetails();

  const [isloading, setisloading] = useState(true);
  const [statLoading, setStatLoading] = useState(true);
  const [viewingUser, setViewingUser] = useState('');
  const [updatingUsername, setupdatingUsername] = useState(false);

  const history = useHistory();
  const {
    email,
    token,
    username,
    name,
    profileImg,
    copyToClipboard,
    linkname,
    setLinkname,
  } = useContext(BankContext);

  if (!email) {
    history.push('/');
  }

  useEffect(() => {
    let mailId = email;
    levelBasedData(mailId).then((response) => {
      let result = response?.data?.levelsData;
      // console.log(result, 'levelbased data');
      setLevelData(result);
      setisloading(false);
    });

    affiliateBalances(mailId).then((response) => {
      let result = response?.data;
      setBalances(result);
    });

    affiliateId(mailId).then((response) => {
      let result =
        response?.data?.usersData?.[0]?.hardCoded?.[0]?.data?.affiliate_id;
      // console.log(result, 'affiliate id');
      setAffiliateId(result);
    });

    uplineData(mailId).then((response) => {
      let result = response?.data?.uplines?.[0];
      setUpline(result);
      let username = response?.data?.user?.username;
      setLinkname(username);
    });
  }, [email]);

  useEffect(() => {
    if (affiliateid.length > 5) {
      let id = affiliateid;
      affiliateStats(id).then((response) => {
        let result = response?.data;
        setAffStats(result);
        setStatLoading(false);
      });
    }
  }, [affiliateid, email]);

  const affiliateData = () => {
    let obj = {
      affiliate_id: affiliateid,
    };
    allUsers(obj).then((response) => {
      let result = response?.data;
      setAllaffiliatedata(result);
      setuserfilteredAff(result);
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  //debouncing for username check

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      // console.log('Search term:', newUserName);
      if (newUserName) {
        usernameExistCheck(newUserName);
      } else {
        setusernameExist(false);
      }
      // Perform search or any other action with the debounced value of newUserName
    }, 50);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [newUserName]);

  const handleChange = (event) => {
    setnewUserName(event.target.value);
  };

  function usernameExistCheck(username) {
    setusernameExist(false);
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?username=${username}`
      )
      .then((response) => {
        if (!response?.data?.status || !response?.data?.count) {
          setusernameExist(true);
        } else {
          setusernameExist(false);
        }
        // console.log('username exist', response);
      })
      .catch((error) => {
        console.log('error checking username', error?.message);
      });
  }
  function usernameUpdate() {
    setupdatingUsername(true);
    axios
      .post('https://comms.globalxchange.io/user/details/update', {
        email: email,
        token: token,
        username: newUserName,
      })
      .then((response) => {
        console.log(response, 'updated username');
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log('error checking username', error?.message);
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      });
  }

  return (
    <Layout active="affiliate" className="affiliate" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="affiliate-navbar">
          <div
            onClick={() => setDrawOpen(!drawOpen)}
            // style={{ cursor: 'pointer' }}
            className="hamIcon"
          >
            <img src={drawOpen ? img2 : img1} alt="" />
          </div>
          <div className="affiliate-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-affiliate" style={{ width: '36%' }}>
            {/* <div className="all" style={{ gap: '4rem' }}>
              <p>All Users</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={upline?.profile_img ? upline?.profile_img : guest}
                alt=""
                style={{ height: '50px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{upline?.name}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '45px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>
                  $
                  {balances?.gxbroker_balance
                    ? balances?.gxbroker_balance?.toFixed(2)
                    : '0.00'}
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-btn"
            onClick={() => {
              setIswithdraw(true);
            }}
          >
            Withdraw
          </div>
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '30%' }}>
            <div
              className={`each-option ${
                affiliateTab === 'crm' ? 'each-option-selected' : ''
              }`}
              // style={{
              //   borderBottom: affiliateTab === 'crm' ? '2px solid #9FD6DF' : '',
              //   fontWeight: affiliateTab === 'crm' ? '600' : '',
              // }}
              onClick={() => setaffiliateTab('crm')}
            >
              <div>
                <img src={crm} alt="" />
                <p>CRM</p>
              </div>
            </div>
            <div
              className={`each-option ${
                affiliateTab === 'affiliate' ? 'each-option-selected' : ''
              }`}
              // style={{
              //   borderBottom:
              //     affiliateTab === 'affiliate' ? '2px solid #9FD6DF' : '',
              //   fontWeight: affiliateTab === 'affiliate' ? '600' : '',
              // }}
              onClick={() => setaffiliateTab('affiliate')}
            >
              <div>
                <img src={ablogo1} alt="" />
                <p>AffiliateBank</p>
              </div>
            </div>
            <div
              className={`each-option ${
                affiliateTab === 'chainview' ? 'each-option-selected' : ''
              }`}
              // style={{
              //   borderBottom:
              //     affiliateTab === 'chainview' ? '2px solid #9FD6DF' : '',
              //   fontWeight: affiliateTab === 'chainview' ? '600' : '',
              // }}
              onClick={() => setaffiliateTab('chainview')}
            >
              <div>
                <img src={comp} alt="" />
                <p>ChainView</p>
              </div>
            </div>
          </div>
        </div>
        {affiliateTab === 'crm' ? (
          <div className="affiliate-content">
            <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
              <div className="header-contt">
                <div className="text-contt">
                  <div className="left-contt">
                    <p
                      style={{
                        color: '#FFFFFF',
                        fontWeight: '700',
                        fontSize: '1.75rem',
                        marginBottom: '0.25rem',
                      }}
                    >
                      Welcome {name ? name : username}
                    </p>
                    <div className="linkstyle">
                      https://app.web3today.io/register/affiliate/{linkname}
                      <div>
                        <img
                          src={copy}
                          alt=""
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            copyToClipboard(
                              `https://app.web3today.io/register/affiliate/${linkname}`,
                              `Link Copied`
                            );
                          }}
                        />{' '}
                        |{' '}
                        <img
                          src={write}
                          alt="edit"
                          id="edit"
                          onClick={() => setnewUserNameSidebar(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="right-contt">
                    <button onClick={() => setInvite(true)}>
                      <img src={heart} alt="" />
                      Invite With Gift
                    </button>
                    <button
                      onClick={() => {
                        setAdduser(true);
                        if (affiliateid) {
                          affiliateData();
                        }
                      }}
                    >
                      <img src={plus} alt="" />
                      Add New User
                    </button>
                  </div>
                </div>
                <div className="small-boxes">
                  <div className="sbox">
                    <div style={{ width: '100%', height: '40%' }}>
                      <p className="headd" style={{ marginBottom: '0.25rem' }}>
                        Direct Signups
                      </p>
                      {statLoading ? (
                        <div className="ttl">
                          <Skeleton width={75} height={25} />
                        </div>
                      ) : (
                        <div className="ttl">{affStats?.direct?.length}</div>
                      )}
                    </div>
                    <div className="act-inact">
                      <div className="actt">
                        <p className="countt">--</p>
                        <div>Active</div>
                      </div>
                      <div className="inactt">
                        <p className="countt">--</p>
                        <div>Inactive</div>
                      </div>
                    </div>
                  </div>
                  <div className="sbox">
                    <div style={{ width: '100%', height: '40%' }}>
                      <p className="headd" style={{ marginBottom: '0.25rem' }}>
                        Indirect Users
                      </p>
                      {statLoading ? (
                        <div className="ttl">
                          <Skeleton width={75} height={25} />
                        </div>
                      ) : (
                        <div className="ttl">{affStats?.indirect?.length}</div>
                      )}
                    </div>
                    <div className="act-inact">
                      <div className="actt">
                        <p className="countt">--</p>
                        <div>Active</div>
                      </div>
                      <div className="inactt">
                        <p className="countt">--</p>
                        <div>Inactive</div>
                      </div>
                    </div>
                  </div>
                  <div className="sbox">
                    <div style={{ width: '100%', height: '40%' }}>
                      <p className="headd" style={{ marginBottom: '0.25rem' }}>
                        Total Users
                      </p>
                      {statLoading ? (
                        <div className="ttl">
                          <Skeleton width={75} height={25} />
                        </div>
                      ) : (
                        <div className="ttl">{affStats?.all?.length}</div>
                      )}
                    </div>
                    <div className="act-inact">
                      <div className="actt">
                        <p className="countt">--</p>
                        <div>Active</div>
                      </div>
                      <div className="inactt">
                        <p className="countt">--</p>
                        <div>Inactive</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="statistics-cont">
                <div className="overallcontt">
                  <div className="statt-div">
                    <div>Level</div>
                    <div>Users</div>
                    <div>Active</div>
                    <div>Inactive</div>
                    <div>Volume</div>
                    <div>Commissions</div>
                  </div>
                  <div className="contentt-div">
                    {isloading
                      ? Array(10)
                          .fill(' ')
                          .map((item, index) => {
                            return (
                              <div className="each-contentt" key={index}>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={100} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={100} height={25} />
                                </div>
                              </div>
                            );
                          })
                      : levelData?.map((e, i) => {
                          return (
                            <div
                              className="each-contentt"
                              onClick={() => {
                                localStorage.setItem(
                                  'leveldata',
                                  JSON.stringify(e)
                                );
                                history.push('/affiliate/levels');
                              }}
                              key={i}
                            >
                              <div>{e?.level}</div>
                              <div>{e?.count}</div>
                              <div>--</div>
                              <div>--</div>
                              <div>${e?.total_revenue?.toFixed(2)}</div>
                              <div>${e?.total_earnings?.toFixed(2)}</div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : affiliateTab === 'affiliate' ? (
          <AffiliateBank />
        ) : (
          <ChainView
            viewingUser={viewingUser}
            setViewingUser={setViewingUser}
          />
        )}
        <div
          className="adduserSidebar"
          style={{ right: adduser ? '0' : '-100%' }}
        >
          <AddUser />
        </div>
        <div
          className="adduserSidebar"
          style={{ right: invite ? '0' : '-100%' }}
        >
          <Invite />
        </div>
        <div
          className="adduserSidebar"
          style={{ right: iswithdraw ? '0' : '-100%' }}
        >
          <Withdraw />
        </div>
        {updatingUsername ? (
          <div
            className="editUsername usernameLoading"
            style={{ right: newUserNameSidebar ? '' : '-150%' }}
          >
            <img
              src={affiliateLogo}
              alt="affiliateLogo"
              className="affiliateLogo"
            />
            <div className="updatingName">
              <span>Updating Username To</span>
              <span>{'  '} </span>
              <span> {newUserName}</span>
            </div>
          </div>
        ) : (
          <div
            className="editUsername"
            style={{ right: newUserNameSidebar ? '' : '-150%' }}
          >
            <div className="heading">
              <span>Update Username</span>
              <div onClick={() => setnewUserNameSidebar(false)}>X</div>
            </div>
            <div className="otherFields">
              <div>Current Username</div>
              <div>
                <input
                  value={linkname ? linkname : ''}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </div>
            <div className="otherFields">
              <div>New Username</div>
              <div>
                <input
                  placeholder="Enter Username"
                  type="text"
                  value={newUserName}
                  onChange={handleChange}
                />
                <div
                  className="usernameIndicator"
                  style={{ background: usernameExist ? '#57D6A1' : '' }}
                ></div>
              </div>
            </div>
            <div
              className="confirmBtn"
              style={{
                pointerEvents: usernameExist ? '' : 'none',
                opacity: usernameExist ? '' : '0.4',
              }}
              onClick={usernameUpdate}
            >
              Confirm Update
            </div>
          </div>
        )}
        {drawOpen && (
          <div className="hammenu">
            <div
              className="menu-item"
              style={{ fontWeight: hamMenu === 'Home' ? '600' : '100' }}
              onClick={() => {
                setHamMenu('Home');
              }}
            >
              Home
            </div>
            <div
              className="menu-item"
              style={{ fontWeight: hamMenu === 'CompPlans' ? '600' : '100' }}
              onClick={() => {
                setHamMenu('CompPlans');
              }}
            >
              CompPlans
            </div>
            <div
              className="menu-item"
              style={{ fontWeight: hamMenu === 'Marketing' ? '600' : '100' }}
              onClick={() => {
                setHamMenu('Marketing');
              }}
            >
              Marketing
            </div>
          </div>
        )}
        <div
          className="overlay"
          style={{
            display:
              newUserNameSidebar || updatingUsername || drawOpen ? '' : 'none',
          }}
          onClick={() => {
            if (!updatingUsername) {
              setnewUserNameSidebar(false);
            }
            if (drawOpen) {
              setDrawOpen(false);
            }
          }}
        ></div>
      </div>
    </Layout>
  );
};

export default AffiliatePage;
