import React, { useState } from 'react';
import Layout from '../../Layout/Layout';
import fulllogo from '../../assets/images/fulllogoCommerical.svg';
import searchIcon from '../../static/images/search.svg';

import web from '../../assets/images/web.svg';
import { useAppContextDetails } from '../../context/AppContext';
import Points from './Points';
import './Player.scss';
import Ads from './Ads';
export default function Index() {
  const { academytab, setAcademytab } = useAppContextDetails();
  const [playertoogle, setplayertoogle] = useState(false);
  const [tabName, settabName] = useState('Ads');
  const callFunction = () => {
    setplayertoogle(true);
    setTimeout(function () {
      setplayertoogle(false);
    }, 2000);
  };
  return (
    <>
      <Layout active="Commercials" className="player" hideFooter>
        <div style={{ width: '100%', height: '100%' }}>
          <div className="player-navbar">
            {/* <div>
            <img src={img1} alt="" />
          </div> */}
            <div className="player-inner">
              <div className="academy-icon">
                <img
                  src={fulllogo}
                  alt=""
                  style={{
                    width: 'auto',
                  }}
                />
              </div>
              <div className="search-academy">
                {/* <div className="all">
              <p>All Videos</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
                <div className="im">
                  <input type="text" placeholder="Im Looking For..." />
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div className="spendDetail" onClick={callFunction}>
                Advertise
              </div>
            </div>
          </div>
          <div className="w-space">
            <div className="optionss" style={{ width: '20%' }}>
              {/* <div
              className={`each-option
              
              
              ${tabName=='Points'&&'each-option-selected'}`}
                onClick={() => settabName('Points')}
            >
              <div>
                <p>Points</p>
              </div>
            </div> */}
              <div
                className={`each-option
              
              
                ${tabName == 'Ads' && 'each-option-selected'}`}
                onClick={() => settabName('Ads')}
                // onClick={() => setAcademytab('affiliates')}
              >
                <div>
                  <p>Advertisers</p>
                </div>
              </div>
              {/* <div
              className={`each-option`}

            >
              <div>
                <p>Airdrops</p>
              </div>
            </div> */}
            </div>
            {/* <div
              className={`each-option`}

            onClick={callFunction}
            >
              <div>
                <p> Global Rankings</p>
              </div>
            </div> */}
          </div>
          {tabName == 'Points' && <Points callFunction={callFunction} />}
          {/* */}
          {tabName == 'Ads' && <Ads />}
        </div>
      </Layout>
      {playertoogle ? (
        <div className="modelData">
          <div className="innermodel">
            <img src={web} />
            <h5>This Feature Is Coming Soon</h5>
            <br/>
            <p>This popup will close in 2 seconds</p>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
