import React, { createContext, useState, useContext, useEffect } from 'react';
import { BankContext } from './Context';
import Axios from 'axios';
import { useAppContextDetails } from './AppContext';

export const VaultContext = createContext();

function VaultContextProvider({ children }) {
  const { profileId, token, email } = useContext(BankContext);
  const [coinBalanceList, setCoinBalanceList] = useState([]);
  const [vaultTxns, setVaultTxns] = useState([]);
  const [coinSelected, setCoinSelected] = useState({});
  const [coinAddress, setCoinAddress] = useState({});
  const [loading, setLoading] = useState(0);
  const { appCode } = useAppContextDetails();

  // For Vault Filter Conrolls

  const [menuTwo, setMenuTwo] = useState({
    key: 'all',
    value: 'All Directions',
  });

  const [dateSelected, setDateSelected] = useState(null);

  const [showNativeValue, setShowNativeValue] = useState(true);

  return (
    <VaultContext.Provider
      value={{
        coinBalanceList,
        coinSelected,
        setCoinSelected,
        vaultTxns,
        coinAddress,
        loading: loading < 3,
        menuTwo,
        setMenuTwo,
        dateSelected,
        setDateSelected,
        showNativeValue,
        setShowNativeValue,
      }}
    >
      {children}
    </VaultContext.Provider>
  );
}

export default VaultContextProvider;
