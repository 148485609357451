import axios from 'axios';


export const gettraderData = async (obj) => {
  try {
    const data = await axios.get(
      `https://publications.apimachine.com/pointsrequest/list?user_id=${obj}&publication_id=638dd769b257b3715a8fbe07`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};
