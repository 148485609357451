import React, { useEffect, useState } from 'react';

import classNames from './signInPage.module.scss';
import styles from './login.module.scss';
import { useAppContextDetails } from '../../context/AppContext';
import { useMutation } from 'react-query';
import axios from 'axios';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import toast from 'react-hot-toast';

const SuperAdminReset = () => {
  const {
    appCode,
    appName,
    appLogo,
    appFullLogo,
    appColorCode,
    websiteTitle,
    websiteDescription,
    appDetailsLoading,
    responseData,
    setResponseData,
  } = useAppContextDetails();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [adminStep, setAdminStep] = useState(0);
  const [idToken, setIdToken] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [userData, setUserData] = useState({});

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [showCopy, setShowCopy] = useState(false);
  const [idcopied, setIdCopied] = useState(false);
  const [acccopied, setAccCopied] = useState(false);

  // const [bottom, setBottom] = useState(0);

  // useEffect(() => {
  //   function handleResize() {
  //     const height = window.innerHeight;
  //     const visualViewportHeight = window.visualViewport.height;
  //     const difference = height - visualViewportHeight;
  //     setBottom(difference);
  //   }

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  useEffect(() => {
    function handleClick(event) {
      if (!event.target.closest('#exclude')) {
        setShowCopy(false);
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIdCopied(false);
      setAccCopied(false);
    }, 1000);
  }, [idcopied, acccopied]);

  useEffect(() => {
    if (localStorage.getItem('adminidToken')) {
      setAdminStep(2);
    } else {
      setAdminStep(1);
    }
  }, []);

  const handleLogin = () => {
    setIsLoading(true);
    axios
      .post(`https://gxauth.apimachine.com/gx/user/auth/login`, {
        email: email,
        password: password,
      })
      .then(({ data }) => {
        // console.log(data.idToken, 'jhvjwhvejdhve');
        if (data.status) {
          setIsLoading(false);
          localStorage.setItem('adminidToken', data.idToken);
          localStorage.setItem('adminAccessToken', data.accessToken);
          setIdToken(data.idToken);
          setAdminStep(2);
        } else {
          setIsLoading(false);
          toast.error(data?.message, {
            duration: 4000,
            position: 'top-right',
          });
        }
      });
  };

  const checkStatus = () => {
    setIsLoading(true);
    axios
      .get(
        `https://gxauth.apimachine.com/gx/disabled/users/get?email=${userEmail}`
      )
      .then(({ data }) => {
        if (data.status) {
          setUserData(data.user);
          setAdminStep(3);
          setIsLoading(false);
        } else {
          setAdminStep(7);
          setIsLoading(false);
        }
      });
  };

  const handleLogout = () => {
    localStorage.removeItem('adminidToken');
    setAdminStep(1);
  };

  const handlePasswordChange = () => {
    if (newPassword === confirmPassword) {
      setIsLoading(true);
      axios
        .post(`https://gxauth.apimachine.com/gx/admin/set/user/password`, {
          token: localStorage.getItem('adminidToken'),
          userEmail: userEmail,
          permanent: true,
          password: confirmPassword,
        })
        .then(({ data }) => {
          if (data.status) {
            setAdminStep(6);
            setIsLoading(false);
          } else {
            setAdminStep(2);
            setIsLoading(false);
            toast.error(data?.message, {
              duration: 4000,
              position: 'top-right',
            });
          }
        });
    }
  };

  const conditionalSteps = () => {
    switch (adminStep) {
      case 1:
        return (
          <div className={styles.formContainer}>
            <img
              src={appFullLogo}
              alt="Full Logo"
              className={styles.logo}
              style={{ paddingBottom: '6%' }}
            />
            <input
              type="email"
              placeholder="Email"
              className={styles.input1}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
              }}
            />
            <input
              type="password"
              placeholder="Password"
              className={styles.input2}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <div className={styles.buttonWrapper}>
              <div className={styles.loginButton} onClick={handleLogin}>
                Login
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.formContainer}>
            <img
              src={appFullLogo}
              alt="Full Logo"
              className={styles.logo}
              style={{ paddingBottom: '6%' }}
            />
            <div className={styles.forgotPassword}>
              Enter Troubled Users Email
            </div>
            <input
              type="email"
              placeholder="Email"
              className={styles.input1}
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value.toLowerCase());
              }}
            />
            <div className={styles.buttonWrapper}>
              <div className={styles.loginButton} onClick={checkStatus}>
                Check Status
              </div>
              <div className={styles.logoutButton} onClick={handleLogout}>
                Logout
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.formContainer1}>
            <img
              src={appFullLogo}
              alt="Full Logo"
              className={styles.logo1}
              style={{ paddingBottom: '3%' }}
            />
            <div className={styles.step3Grid}>
              <div>
                <div className={styles.dataLabel}>Email</div>
                <div className={styles.dataValue}>
                  {
                    userData?.UserAttributes?.find((o) => o.Name === 'email')
                      .Value
                  }
                </div>
                <div className={styles.dataLabel}>User Created</div>
                <div className={styles.dataValue}>
                  {userData?.UserCreateDate}
                </div>
                <div className={styles.dataLabel}>
                  Email Verification Status
                </div>
                <div className={styles.dataValue}>
                  {
                    userData?.UserAttributes?.find(
                      (o) => o.Name === 'email_verified'
                    ).Value
                  }
                </div>
              </div>
              <div>
                <div className={styles.dataLabel}>Username</div>
                <div className={styles.dataValue}>{userData?.Username}</div>
                <div className={styles.dataLabel}>Login Status</div>
                <div className={styles.dataValue}>
                  {userData?.Enabled?.toString()}
                </div>
                <div className={styles.dataLabel}>Password Status</div>
                <div className={styles.dataValue}>{userData?.UserStatus}</div>
              </div>
            </div>
            <div className={styles.buttonWrapper3}>
              <div
                className={styles.logoutButton}
                style={{ width: '100%', height: '60px' }}
                onClick={handleLogout}
              >
                Logout
              </div>
              <div
                onClick={(e) => setAdminStep(4)}
                className={styles.loginButton2}
                style={{
                  width: '100%',
                  height: '60px',
                }}
              >
                Admin Password Change
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.formContainer}>
            <img
              src={appFullLogo}
              alt="Full Logo"
              className={styles.logo}
              style={{ paddingBottom: '6%' }}
            />
            <div className={styles.forgotPassword}>Enter New Password</div>
            <input
              type="password"
              placeholder="Enter New Password"
              className={styles.input1}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <div className={styles.buttonWrapper}>
              <div
                className={styles.loginButton}
                onClick={(e) =>
                  newPassword.length > 3 ? setAdminStep(5) : null
                }
              >
                Next
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className={styles.formContainer}>
            <img
              src={appFullLogo}
              alt="Full Logo"
              className={styles.logo}
              style={{ paddingBottom: '6%' }}
            />
            <div className={styles.forgotPassword}>Confirm New Password</div>
            <div
              className={styles.input1}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <input
                style={{ border: 'none', outline: 'none', width: '90%' }}
                type="password"
                placeholder="Enter Password Again"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              {newPassword === confirmPassword &&
              newPassword !== '' &&
              confirmPassword !== '' ? (
                <img
                  src={require('./check.svg')}
                  alt=""
                  width={30}
                  height={30}
                />
              ) : (
                ''
              )}
            </div>
            <div className={styles.buttonWrapper}>
              <div
                className={styles.loginButton}
                onClick={handlePasswordChange}
              >
                Submit
              </div>
            </div>
          </div>
        );
      case 6:
        return (
          <div className={styles.formContainer}>
            <img
              src={appFullLogo}
              alt="Full Logo"
              className={styles.logo}
              style={{ paddingBottom: '3%' }}
            />
            <div>You have successfully reset the password for</div>
            <div style={{ fontWeight: '700', paddingBottom: '5%' }}>
              {userEmail}
            </div>
            <div className={styles.forgotPassword}>New Password</div>

            <input
              disabled
              type="password"
              placeholder="**********"
              className={styles.input1}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <div className={styles.buttonWrapper}>
              <div
                className={styles.loginButton}
                style={{ marginBottom: '-2%' }}
                onClick={() => {
                  navigator.clipboard.writeText(confirmPassword);
                }}
              >
                Copy To Clipboard
              </div>
              <div
                className={styles.loginButton2}
                style={{ marginBottom: '-2%' }}
                onClick={(e) => setAdminStep(2)}
              >
                Reset Another User’s Password
              </div>
              <div className={styles.logoutButton} onClick={handleLogout}>
                Logout
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <div className={styles.formContainer}>
            <img
              src={appFullLogo}
              alt="Full Logo"
              className={styles.logo}
              style={{ paddingBottom: '3%' }}
            />

            <div className={styles.noRegistration}>
              Unfortunately {userEmail} hasn’t been registered. Please register
              them first and then come back to perform the administrative
              password reset.
            </div>
            <div className={styles.buttonWrapper}>
              <div className={styles.logoutButton} onClick={handleLogout}>
                Logout
              </div>
              <div
                className={styles.loginButton2}
                style={{ marginBottom: '-2%' }}
                onClick={(e) => setAdminStep(2)}
              >
                Reset Another User’s Password
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className={styles.loadingComponent}>
            <LoadingAnimation icon={appLogo} width={200} />
          </div>
        );
        break;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {isLoading && (
        <div className={styles.loadingComponent}>
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
      {conditionalSteps()}
      {localStorage.getItem('adminidToken') ? (
        <div
          id="exclude"
          className={styles.cornerButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowCopy(!showCopy);
          }}
        >
          <img src={require('./copyToken.svg')} alt="" />
        </div>
      ) : (
        ''
      )}
      {showCopy ? (
        <div className={styles.copyOptions}>
          <div
            id="exclude"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(
                localStorage.getItem('adminidToken')
              );
              setIdCopied(true);
            }}
          >
            {idcopied ? 'ID Token Copied' : 'Copy ID Token'}
          </div>
          <div
            id="exclude"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(
                localStorage.getItem('adminAccessToken')
              );
              setAccCopied(true);
            }}
          >
            {acccopied ? 'Access Token Copied' : 'Copy Access Token'}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SuperAdminReset;
