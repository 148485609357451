import React, { useState, useRef } from 'react';
import './affiliate.scss';
import { useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Exchange from './Exchange';
import { commissionForExchangeFees, commissionForMM } from './api';
import MoneyMarket from './MoneyMarket';
import Skeleton from 'react-loading-skeleton';
import StakingCommission from './StakingCommission';

//images
import ProductsAffiliateBank from './ProductsAffiliateBank';
import plus from '../../static/images/affiliate/plus1.svg';
import comp from '../../static/images/affiliate/comp.svg';
import filter from '../../static/images/affiliate/filter1.svg';
import products1 from '../../static/images/affiliate/products.svg';
import mm from '../../static/images/affiliate/mm.svg';
import ef from '../../static/images/affiliate/ef.svg';
import rb from '../../static/images/affiliate/rb.svg';
import ai from '../../static/images/affiliate/ai.svg';
import staking1 from '../../static/images/affiliate/staking.svg';
import classroom from '../../static/images/affiliate/classroom.svg';
import funds from '../../static/images/affiliate/funds.svg';
import withdraw from '../../static/images/affiliate/withdraw.svg';
import close from '../../static/images/affiliate/dropdown/close.svg';

const AffiliateBank = () => {
  const { email, username, name, profileImg } = useContext(BankContext);
  const {
    balances,
    setBalances,
    iswithdraw,
    setIswithdraw,
    exchangeData,
    setExchangeData,
    MMData,
    setMMData,
    stakingData,
    setStakingData,
  } = useAppContextDetails();
  const [dropdown, setDropDown] = useState(false);
  const [ddselected, setDdSelected] = useState('Products');
  const [isloading1, setIsloading1] = useState(true);
  const [isloading2, setIsloading2] = useState(true);
  const [ddIcon, setDdIcon] = useState(products1);
  const [collapse, setCollapse] = useState(false);
  const [hidden, setHidden] = useState(false);
  const scrollableDivRef = useRef(null);

  useEffect(() => {
    setIsloading1(true);
    setIsloading2(true);

    commissionForExchangeFees(email).then((response) => {
      let result = response?.data?.logs;
      // console.log(result, 'EF');
      setExchangeData(result);
      setIsloading1(false);
    });

    commissionForMM(email).then((response) => {
      let result = response?.data?.logsData?.logs;
      // console.log(result, 'MM');
      setMMData(result);
      setIsloading2(false);
    });
  }, []);

  // function updateScroll() {
  //   var element = document.getElementById('moveToTop');
  //   console.log(element, 'element');
  //   // element.scrollTop = 0;
  // }

  // const updateScroll = () => {
  //   // scrollableDivRef.current.scrollTop = 0;
  //   console.log(scrollableDivRef, 'scrollableDivRef');
  // };

  const loading = isloading1 && isloading2;

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div className="affiliate-content1">
        <div
          className="topp-section"
          style={{ display: hidden ? 'none' : 'flex' }}
        >
          <div className="left-balances">
            <div className="each-balances each-balances1">
              <div className="heading">Balance</div>
              <div className="amount">
                $
                {balances?.gxbroker_balance
                  ? balances?.gxbroker_balance?.toFixed(2)
                  : '0.00'}
              </div>
            </div>
            <div className="each-balances each-balances2">
              <div className="heading">Withdrawable Balance</div>
              <div className="amount">
                $
                {balances?._2weekDelay_balance
                  ? balances?._2weekDelay_balance?.toFixed(2)
                  : '0.00'}
              </div>
            </div>
          </div>
        </div>
        <div
          className="filter-opts"
          style={{ borderTop: hidden ? '0.5px solid #e5e5e5' : '' }}
        >
          <div className="filters">
            {ddselected === 'Products' ? (
              <>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Product Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Company Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Date Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Customer Filter
                </div>
              </>
            ) : (
              <>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Directional Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Categorical Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Date Filter
                </div>
                <div className="each-filter">
                  <img src={plus} alt="" />
                  Add Vault Filter
                </div>
              </>
            )}
          </div>
          <div className="compplan">
            <img src={comp} alt="compPlan" />
            CompPlan
          </div>
          <div
            className="compplan1"
            style={{ width: '15%' }}
            onClick={() => setDropDown(!dropdown)}
          >
            <div>
              <img src={ddIcon} alt="compPlan" />
              {ddselected}
            </div>
          </div>
          <div
            className="filterIcon"
            onClick={() => {
              setCollapse(!collapse);
              if (dropdown) {
                setDropDown(false);
              }
            }}
          >
            <img src={collapse ? close : filter} alt="" />
          </div>
        </div>
        {!dropdown && !collapse && ddselected === 'Products' ? (
          <div
            className="products-container"
            style={{
              height: hidden ? 'calc(100% - 4rem)' : 'calc(100% - 14rem)',
            }}
          >
            <div className="head-section-products">
              <div>Product</div>
              <div>Customer</div>
              <div>Cost</div>
              <div>Customer Debited</div>
              <div>Direct Earnings</div>
              <div>Indirect Earnings</div>
              <div>Prime Affiliate Earnings</div>
            </div>
            <div className="balance-data-products">
              {loading ? (
                Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div
                        className="ind-detailss products-details"
                        key={index}
                      >
                        <div className="type1">
                          <div className="detailss">
                            <Skeleton width={75} height={15} />
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="type1">
                          {/* <div>
                          <img
                            src={profileImg ? profileImg : guest}
                            alt=""
                            style={{ borderRadius: '50%', height: '50px' }}
                          />
                      </div> */}
                          <div className="detailss">
                            <p>
                              <Skeleton width={100} height={15} />
                            </p>
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="dd1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div className="baln1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div className="baln1">
                          <Skeleton width={60} height={25} />
                        </div>
                      </div>
                    );
                  })
              ) : (
                <ProductsAffiliateBank />
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {dropdown ? (
          <>
            <div
              className="headd-section"
              style={{ justifyContent: 'space-between' }}
            >
              <div
                style={{ fontSize: '0.85rem', fontWeight: '600', width: '91%' }}
              >
                Name
              </div>
              <div
                style={{ fontSize: '0.85rem', fontWeight: '600', width: '9%' }}
              >
                Status
              </div>
            </div>
            <div
              className="dd-options"
              style={{
                height: hidden ? 'calc(100% - 7rem)' : 'calc(100% - 17rem)',
              }}
            >
              <div
                className="each-dd"
                // onClick={() => {
                //   setDdSelected('Withdrawals');
                //   setDdIcon(withdraw);
                //   setDropDown(false);
                //   updateScroll();
                // }}
              >
                <div className="wid">
                  <img
                    src={withdraw}
                    alt="withdraw"
                    className="exchange-icon"
                  />
                  <span>Withdrawals</span>
                </div>
                <div className="wid1">Active</div>
              </div>

              <div
                className="each-dd"
                onClick={() => {
                  setDdSelected('Products');
                  setDdIcon(products1);
                  setDropDown(false);
                  // updateScroll();
                }}
              >
                <div className="wid">
                  <img
                    src={products1}
                    alt="products"
                    className="exchange-icon"
                  />
                  <span>Products</span>
                </div>
                <div className="wid1">Active</div>
              </div>

              <div
                className="each-dd"
                onClick={() => {
                  setDdSelected('Exchange Fees');
                  setDdIcon(ef);
                  setDropDown(false);
                  // updateScroll();
                }}
              >
                <div className="wid">
                  <img
                    src={ef}
                    alt="exchangeFeesIcon"
                    className="exchange-icon"
                  />
                  <span>Exchange Fees</span>
                </div>
                <div className="wid1">Active</div>
              </div>

              <div
                className="each-dd"
                onClick={() => {
                  setDdSelected('Staking');
                  setDdIcon(staking1);
                  setDropDown(false);
                  // updateScroll();
                }}
              >
                <div className="wid">
                  <img
                    src={staking1}
                    alt="stakingIcon"
                    className="exchange-icon"
                  />
                  <span>Staking</span>
                </div>
                <div className="wid1">Active</div>
              </div>

              <div
                className="each-dd"
                onClick={() => {
                  setDdSelected('Money Markets');
                  setDdIcon(mm);
                  setDropDown(false);
                  // updateScroll();
                }}
              >
                <div className="wid">
                  <img src={mm} alt="moneyMarket" className="exchange-icon" />
                  <span>Money Markets</span>
                </div>
                <div className="wid1">Active</div>
              </div>

              <div
                className="each-dd"
                style={{ cursor: 'not-allowed' }}
                // onClick={() => {
                //   // setDdSelected('Funds');
                //   // setDdIcon(funds);
                //   setDropDown(false);
                //   updateScroll();
                // }}
              >
                <div className="wid">
                  <img src={funds} alt="funds" className="exchange-icon" />
                  <span>Funds</span>
                </div>
                <div className="wid1">Coming Soon</div>
              </div>

              <div
                className="each-dd"
                style={{ cursor: 'not-allowed' }}
                // onClick={() => {
                //   // setDdSelected('Retirement Blocks');
                //   // setDdIcon(rb);
                //   setDropDown(false);
                //   updateScroll();
                // }}
              >
                <div className="wid">
                  <img src={rb} alt="retirement" className="exchange-icon" />
                  <span>Retirement Blocks</span>
                </div>
                <div className="wid1">Coming Soon</div>
              </div>

              <div
                className="each-dd"
                style={{ cursor: 'not-allowed' }}
                // onClick={() => {
                //   // setDdSelected('AI Index');
                //   // setDdIcon(ai);
                //   setDropDown(false);
                //   updateScroll();
                // }}
              >
                <div className="wid">
                  <img src={ai} alt="ai" className="exchange-icon" />
                  <span>AI Index</span>
                </div>
                <div className="wid1">Coming Soon</div>
              </div>

              <div
                className="each-dd"
                style={{ cursor: 'not-allowed' }}
                // onClick={() => {
                //   // setDdSelected('Classrooms');
                //   // setDdIcon(classroom);
                //   setDropDown(false);
                //   updateScroll();
                // }}
              >
                <div className="wid">
                  <img
                    src={classroom}
                    alt="classroom"
                    className="exchange-icon"
                  />
                  <span>Classrooms</span>
                </div>
                <div className="wid1">Coming Soon</div>
              </div>
            </div>
          </>
        ) : !dropdown && !collapse && ddselected !== 'Products' ? (
          <>
            <div className="headd-section">
              <div className="type">Type</div>
              <div className="user">User</div>
              <div className="dd">DDLevel</div>
              <div className="direct">Direct</div>
              <div className="direct">Indirect</div>
              <div className="baln">Balance</div>
            </div>

            <div
              className="balance-data"
              style={{
                height: hidden ? 'calc(100% - 7rem)' : 'calc(100% - 17rem)',
              }}
              id="moveToTop"
              ref={scrollableDivRef}
            >
              {loading ? (
                Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="ind-detailss" key={index}>
                        <div className="type1">
                          <div className="detailss">
                            <Skeleton width={75} height={15} />
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="type1">
                          {/* <div>
                          <img
                            src={profileImg ? profileImg : guest}
                            alt=""
                            style={{ borderRadius: '50%', height: '50px' }}
                          />
                      </div> */}
                          <div className="detailss">
                            <p>
                              <Skeleton width={100} height={15} />
                            </p>
                            <p
                              style={{ fontSize: '0.8rem', fontWeight: '400' }}
                            >
                              <Skeleton width={100} height={15} />
                            </p>
                          </div>
                        </div>
                        <div className="dd1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div style={{ color: '#50C7AD' }} className="direct1">
                          <Skeleton width={60} height={25} />
                        </div>
                        <div className="baln1">
                          <Skeleton width={60} height={25} />
                        </div>
                      </div>
                    );
                  })
              ) : ddselected === 'Exchange Fees' ? (
                <Exchange />
              ) : ddselected === 'Money Markets' ? (
                <MoneyMarket />
              ) : (
                <StakingCommission />
              )}
            </div>
          </>
        ) : !dropdown && collapse ? (
          <>
            <div
              className="headd-section"
              style={{ justifyContent: 'flex-start', padding: '1rem 3rem' }}
            >
              <div
                style={{ fontSize: '0.85rem', fontWeight: '600', width: '91%' }}
              >
                Option
              </div>
            </div>
            <div className="dd-options">
              <div
                className="each-dd"
                onClick={() => {
                  // setCollapse(false);
                  // setHidden(true);
                  setCollapse(!collapse);
                  setHidden(!hidden);
                }}
              >
                <div className="wid">{hidden ? 'Collapse' : 'Expand'}</div>
              </div>
              <div className="each-dd">
                <div className="wid" style={{ opacity: '0.25' }}>
                  Download As CSV
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default AffiliateBank;
