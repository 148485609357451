import React, { useState, useContext, useEffect, useRef } from 'react';
import pointsDrawer from '../../../assets/images/pointsDrawer.svg';
import downarrow from '../../../assets/images/darrow.svg';
import Analytics from '../Analytics';
import './points.scss';
import { getplayout, getPointsData, getCovertValues, postConvert } from './api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { BankContext } from '../../../context/Context';
import { useAppContextDetails } from '../../../context/AppContext';
import axios from 'axios';
// import { BankContext } from '../../context/Context';
export default function Index({
  callFunction,
  setpointsDropdown,
  pointsDropdown,
  settablay,
  tablay,
}) {
  const [payOut, setpayOut] = useState({});
  const [points, setpoints] = useState({});
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [topoint, settopoint] = useState('');
  const [frompoint, setfrompoint] = useState(parseFloat(1).toFixed(2));
  const [loader, setloader] = useState(false);
  const [convert, setconvert] = useState({});
  const [convetLoader, setconvetLoader] = useState(false);
  const [referralPoints, setReferralPoints] = useState('');
  const [paymentId, setPaymentId] = useState("")
  const [displayCurrency, setDisplayCurrency] = useState("INR")

  const { academytab, setAcademytab, setTranscriptTab, UserIdWeb3TodayAccount, tabName, settabName } =
    useAppContextDetails();

  const {
    email,
    web3UserId,
    setAllArticleRead,
    setAllVideosWatched,
    tostShowOn,
    transcriptSearch,
    setTranscriptSearch,
  } = useContext(BankContext);
  //   const { email } = useContext(BankContext);
  useEffect(() => {
    setWidth(elementRef?.current?.getBoundingClientRect()?.width);
    getReferralPoints();
  }, []);

  useEffect(() => {
    PointsData();
    palyoutFunction();
  }, [web3UserId]);

  const palyoutFunction = async () => {
    setloader(true);
    await getplayout().then((response) => {
      let result = response?.data?.data;

      setpayOut(result);
      setloader(false);
      // console.log('zxc', result);
    });
  };

  useEffect(() => {
    covertFuction();
  }, []);

  const covertFuction = async () => {
    await getCovertValues().then((response) => {
      let result = response?.data?.data;
      setconvert(result);
      // setpayOut(result);
      // setloader(false);
      // console.log('zxc', result);
    });
  };

  const PointsData = async () => {
    let obj = web3UserId;
    await getPointsData(obj).then((response) => {
      let result = response?.data;
      setpoints(result);
    });
  };

  async function getReferralPoints() {
    let points = await axios.get(
      `https://publications.apimachine.com/affiliatepoints/list?email=${'shorupan@indianinvestor.com'}&publication_id=638dd769b257b3715a8fbe07`
    );

    let pointsObj = points?.data?.data;

    if (pointsObj?.length > 0) {
      setReferralPoints(pointsObj[0]?.points);
    }
  }

  const postConvertFunction = async () => {
    let obj = {
      user_id: web3UserId,
      publication_id: '638dd769b257b3715a8fbe07',
      points_requested: frompoint,
      paymentdetails: paymentId,
      currency:displayCurrency
    };

    setconvetLoader(true);
    await postConvert(obj).then((response) => {
      let result = response?.data;
      if (result?.status) {
        tostShowOn(result.message);
        setconvetLoader(false);
        setpointsDropdown(false);
      } else {
        tostShowOn(result.message);
        setconvetLoader(false);
      }
    });
  };

  const handlefrom = (e) => {
    setfrompoint(e);
  };
  const formatInput = () => {
    setfrompoint(parseFloat(frompoint).toFixed(2));
  };
  // console.log('width', width);

  const calllayout = () => {
    switch (tablay) {
      case 'default':
        return (
          <>
            <div className="points-dashboard">
              <div className="top-inner">
                <div className="top-left" ref={elementRef}>
                  <div>
                    <h1>Rank Up</h1>
                    <h2>To Multiply The Amount Of Points You Earn</h2>
                  </div>

                  <div className="topCardInner">
                    <div
                      className="innerCardTop"
                      style={{
                        background: 'rgba(255, 255, 255, 0.38)',
                        border: '0px ',
                      }}
                    >
                      <h3>Bronze</h3>
                      <h4>1x</h4>
                      <div
                        className="requrement"
                        style={{
                          fontWeight: '700',
                          fontSize: '12px',
                          background: '#fff',
                          color: '#4B2A91',
                        }}
                      >
                        Current
                      </div>
                    </div>

                    <div className="innerCardTop">
                      <h3>Silver</h3>
                      <h4>2x</h4>
                      <div className="requrement">Rank Up</div>
                    </div>

                    <div className="innerCardTop">
                      <h3>Gold</h3>
                      <h4>3x</h4>
                      <div className="requrement">Rank Up</div>
                    </div>

                    <div className="innerCardTop">
                      <h3>Diamond</h3>
                      <h4>4x</h4>
                      <div className="requrement">Rank Up</div>
                    </div>
                  </div>
                </div>
                <div className="top-right">
                  <div className="header">
                    <div className="topheader">
                      <div>
                        <h1>Your Stats</h1>
                        <p>Rank: Bronze</p>
                      </div>
                      <div>
                        <h1>{points?.globalRank}</h1>
                        <p>Global Standing</p>
                      </div>
                    </div>
                    <div>
                      <h2>Lifetime Earnings</h2>
                      <div className="calculate">
                        {points?.totalPoints?.toFixed(2)}
                      </div>
                    </div>
                    <div>
                      <h2>Cashed Out</h2>
                      <div className="calculate">
                        {points?.convertedPoints?.toFixed(2)}
                      </div>
                    </div>
                    {/* <div className="cashout">Cash Out</div> */}
                  </div>
                </div>
              </div>

              <div className="sectionBottomCards">
                <span>Time Based</span>
                <div className="pointBottomCards">
                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#4B2A91',
                      }}
                    >
                      {payOut?.rewardPoints} W3T Per Minute
                    </div>
                    <div className="numberCard">
                      {loader ? (
                        <Skeleton
                          width="8rem"
                          height="2.1rem"
                          style={{ borderRadius: '0px' }}
                        />
                      ) : (
                        <h1>
                          {' '}
                          {points?.totalPerMinuteArticlePoints?.toFixed(2)}
                        </h1>
                      )}
                      <h2>Reading</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalPerMinuteArticlePoints?.toFixed(
                            2
                          )}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalPerMinuteArticlePoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalPerMinuteArticlePoints?.toFixed(
                                2
                              ) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div className="analytis" onClick={() => {
                        settabName('Reading');
                        settablay('Reading');
                        setTranscriptSearch('');
                        setTranscriptTab('reading');
                      }}>
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#17C5CD',
                        background: 'rgba(23, 197, 205, 0.1)',
                      }}
                    >
                      2 W3T Per Minute
                    </div>
                    <div className="numberCard">
                      {loader ? (
                        <Skeleton
                          width="8rem"
                          height="2.1rem"
                          style={{ borderRadius: '0px' }}
                        />
                      ) : (
                        <h1>{points?.totalPerMinuteVideoPoints?.toFixed(2)}</h1>
                      )}

                      <h2>Watching</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalPerMinuteVideoPoints?.toFixed(
                            2
                          )}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalPerMinuteVideoPoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalPerMinuteVideoPoints?.toFixed(2) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        className="analytis"
                        onClick={() => {
                          settabName('Watching');
                          settablay('Watching');
                          setTranscriptSearch('');
                          setTranscriptTab('watching');
                        }}
                        style={{
                          background: '#17C5CD',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                </div>

                <span
                  style={{
                    marginTop: '1.5rem',
                  }}
                >
                  Single Completion
                </span>

                <div className="pointBottomCards">
                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#FFBA32',
                        background: 'rgba(255, 186, 50, 0.1)',
                      }}
                    >
                      {payOut?.articleRead} W3T Per Article
                    </div>
                    <div className="numberCard">
                      {loader ? (
                        <Skeleton
                          width="8rem"
                          height="2.1rem"
                          style={{ borderRadius: '0px' }}
                        />
                      ) : (
                        <h1>{points?.totalArticlePoints?.toFixed(2)}</h1>
                      )}

                      <h2>Article Completion</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalArticlePoints?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalArticlePoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalArticlePoints?.toFixed(2) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        className="analytis"
                        onClick={callFunction}
                        style={{
                          background: '#FFBA32',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>

                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#17C5CD',
                        background: 'rgba(23, 197, 205, 0.1)',
                      }}
                    >
                      {payOut?.videoRead} W3T Per Video
                    </div>
                    <div className="numberCard">
                      <h1>{points?.totalVideoPoints?.toFixed(2)}</h1>
                      <h2>Video Completion</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalVideoPoints?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalVideoPoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalVideoPoints?.toFixed(2) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        className="analytis"
                        onClick={callFunction}
                        style={{
                          background: '#17C5CD',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#F23BF7',
                        background: 'rgba(242, 59, 247, 0.1)',
                      }}
                    >
                      {payOut?.signUpBonus} W3T For Your Signup
                    </div>
                    <div className="numberCard">
                      <h1>{points?.totalSignUpPoints?.toFixed(2)}</h1>
                      <h2>Signup</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3></h3>
                        <h4></h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalSignUpPoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalSignUpPoints?.toFixed(2) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        className="analytis"
                        onClick={callFunction}
                        style={{
                          background: '#F23BF7',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  style={{
                    marginTop: '1.5rem',
                  }}
                >
                  Daily Engagement
                </span>

                <div className="pointBottomCards">
                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#63AD86',
                        background: 'rgba(99, 173, 134, 0.1)',
                      }}
                    >
                      {payOut?.dailyLogin} W3T Per Login
                    </div>
                    <div className="numberCard">
                      {loader ? (
                        <Skeleton
                          width="8rem"
                          height="2.1rem"
                          style={{ borderRadius: '0px' }}
                        />
                      ) : (
                        <h1>{points?.totalDailyPoints?.toFixed(2)}</h1>
                      )}

                      <h2>Daily Login</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalDailyPoints?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalDailyPoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalDailyPoints?.toFixed(2) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        onClick={() => settablay('login')}
                        className="analytis"
                        style={{
                          background: '#63AD86',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  style={{
                    marginTop: '1.5rem',
                  }}
                >
                  Evaluation
                </span>

                <div className="pointBottomCards">
                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#C18678',
                        background: 'rgba(193, 134, 120, 0.1)',
                      }}
                    >
                      {payOut?.articleQuestionPoints} W3T Per Article Question
                    </div>
                    <div className="numberCard">
                      <h1>
                        {points?.totalUserAnswerArticlePoints?.toFixed(2)}
                      </h1>
                      <h2>Questions</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalUserAnswerArticlePoints?.toFixed(
                            2
                          )}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalUserAnswerArticlePoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalUserAnswerArticlePoints?.toFixed(
                                2
                              ) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        onClick={callFunction}
                        className="analytis"
                        style={{
                          background: '#C18678',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>

                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#FF6D3A',
                        background: 'rgba(255, 109, 58, 0.1)',
                      }}
                    >
                      {payOut?.videoQuestionPoints} W3T Per Video Question
                    </div>
                    <div className="numberCard">
                      <h1>{points?.totalUserAnswerVideoPoints?.toFixed(2)}</h1>
                      <h2>Questions</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalUserAnswerVideoPoints?.toFixed(
                            2
                          )}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalUserAnswerVideoPoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalUserAnswerVideoPoints?.toFixed(
                                2
                              ) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        onClick={callFunction}
                        className="analytis"
                        style={{
                          background: '#FF6D3A',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  style={{
                    marginTop: '1.5rem',
                  }}
                >
                  Multi Completion
                </span>
                <div className="pointBottomCards">
                  {/*  */}

                  {/*  */}

                  {/*  */}

                  {/*  */}

                  {/*  */}

                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#8D9CA8',
                        background: 'rgba(141, 156, 168, 0.1)',
                      }}
                    >
                      {payOut?.fiveArticleRead} W3T On Completion
                    </div>
                    <div className="numberCard">
                      {loader ? (
                        <Skeleton
                          width="8rem"
                          height="2.1rem"
                          style={{ borderRadius: '0px' }}
                        />
                      ) : (
                        <h1>{points?.totalBonusPoints?.toFixed(2)}</h1>
                      )}

                      <h2>Five Article Bonus</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalBonusPoints?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalBonusPoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalBonusPoints?.toFixed(2) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        className="analytis"
                        onClick={callFunction}
                        style={{
                          background: '#8D9CA8',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                  {/*  */}

                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#E14248',
                        background: 'rgba(225, 66, 72, 0.1)',
                      }}
                    >
                      {payOut?.fiveVideoRead} W3T On Completion
                    </div>
                    <div className="numberCard">
                      <h1>{points?.totalVideoBonusPoints?.toFixed(2)}</h1>
                      <h2>Five Video Bonus</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>
                          {points?.Last24HoursTotalVideoBonusPoints?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>
                          {(
                            points?.totalVideoBonusPoints?.toFixed(2) /
                            points?.totalPoints?.toFixed(2)
                          )?.toFixed(2) == 'Infinity'
                            ? '0.00'
                            : (
                              (points?.totalVideoBonusPoints?.toFixed(2) /
                                points?.totalPoints?.toFixed(2)) *
                              100
                            )?.toFixed(2)}
                          %
                        </h4>
                      </div>
                      <div
                        className="analytis"
                        onClick={callFunction}
                        style={{
                          background: '#E14248',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>

                  {/*  */}

                  {/*  */}
                </div>

                <span
                  style={{
                    marginTop: '1.5rem',
                  }}
                >
                  Referral
                </span>
                <div className="pointBottomCards">
                  <div
                    className="pointsCards"
                    style={{
                      width: `calc(${width}px / 3 - 1.4vw)`,
                    }}
                  >
                    <div
                      className="time-points"
                      style={{
                        color: '#08152D',
                        background: 'rgba(8, 21, 45, 0.1)',
                      }}
                    >
                      __ W3T Per Referral
                    </div>
                    <div className="numberCard">
                      <h1>{referralPoints ? referralPoints : '--'}</h1>
                      <h2>Referrals</h2>
                    </div>
                    <div className="sectionBottomPoits">
                      <div className="botteMpoinumberCardntDetial">
                        <h3>Last 24HR:</h3>
                        <h4>--</h4>
                      </div>
                      <div className="botteMpoinumberCardntDetial">
                        <h3>% Of Total:</h3>
                        <h4>--</h4>
                      </div>
                      <div
                        className="analytis"
                        onClick={callFunction}
                        style={{
                          background: '#08152D',
                        }}
                      >
                        {' '}
                        Analytics
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {pointsDropdown && (
              <div className="drawerPoints">
                <div className="topHeaderDrawerAds">
                  <h1> Convert Your Points Into {displayCurrency}</h1>
                  <img
                    src={pointsDrawer}
                    onClick={() => setpointsDropdown(false)}
                  />
                </div>

                <div className="TopCurrency">
                  <div className="innerCurrency">
                    <h2>From</h2>
                    <h3>Bal: {points?.updatedPoints?.toFixed(2)}</h3>
                  </div>
                  <div className="innerCurrency">
                    <input
                      type="number"
                      step="0.1"
                      min="0"
                      max="20"
                      value={frompoint}
                      placeholder="0.00"
                      onChange={(e) => handlefrom(e.target.value)}
                      onBlur={formatInput}
                    />
                    <h5>W3T</h5>
                  </div>
                </div>
                <div className="downarrowSection">
                  <img src={downarrow} />
                </div>
                <div className="TopCurrency">
                  <div className="innerCurrency">
                    <h2>To</h2>
                    <h3>Bal: 0.00</h3>
                  </div>
                  <div className="innerCurrency">
                    <span

                    // onChange={(e) => settopoint(e.target.value)}
                    >
                      {displayCurrency === "INR" ? 0.01 : 0.000125 * frompoint}
                    </span>
                    <select name="currency" id="currency" form="currency" value={displayCurrency} onChange={(e) => setDisplayCurrency(e.target.value)}>
                      <option value="INR">INR</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                </div>
                <div className="outerside">
                  <div>Rate:</div>
                  <div>
                    1 {displayCurrency} Equals{' '}
                    {displayCurrency === "INR" ? "100" : "8000"} W3T
                  </div>
                </div>
                <br />
                <div className='TopCurrency'>
                  <div className="innerCurrency">
                    <h2>{displayCurrency === "INR" ? "Enter your UPI ID" : "Enter your TRON ADDRESS"}</h2>
                    <h3></h3>
                  </div>
                  <div className="innerCurrency">
                    <input
                      type="text"
                      value={paymentId}
                      placeholder={displayCurrency === "INR" ? "UPI ID" : "TRON ADDRESS"}
                      onChange={(e) => setPaymentId(e.target.value)}
                    // onBlur={formatInput}
                    />
                    <h5></h5>
                  </div>
                  <br />       
                </div>
                <div className="outerside">
                    <div>charges:</div>
                    <div>
                      {displayCurrency === "INR" ? "100" : "2"} {displayCurrency}
                    </div>
                  </div>
                <div className="labelbottom">
                  <label className="submit" onClick={postConvertFunction}>
                    {convetLoader ? 'Loading...' : 'Convert'}
                  </label>
                </div>
              </div>
            )}
          </>
        );
        break;
      case 'login':
        return (
          <Analytics points={points} payOut={payOut} settablay={settablay} />
        );

      default:
        break;
    }
  };

  return <>{calllayout()}</>;
}
