import React, { useEffect, useState } from 'react';
import classNames from './tablecontainer.module.scss';
import { useAppContextDetails } from '../../../context/AppContext';
import { useContext } from 'react';
import { BankContext } from '../../../context/Context';

const TableContainer = () => {
  const { transcriptTab } = useAppContextDetails();
  const { allArticleRead, allVideosWatched, transcriptSearch } = useContext(BankContext);

  const allContentsReading = [
    {
      activity: 'Individual Articles Opened',
      stat: allArticleRead?.total_article ? allArticleRead?.total_article : '',
    },
    {
      activity: 'Total Articles Opened',
      stat: allArticleRead?.total_article_open_count
        ? allArticleRead?.total_article_open_count
        : '',
    },
    {
      activity: 'Individual Articles Completed',
      stat: '',
    },
    {
      activity: 'Total Articles Completed',
      stat: '',
    },
    {
      activity: 'Total Minutes Reading',
      stat: allArticleRead?.total_article_read_minutes
        ? allArticleRead?.total_article_read_minutes
        : '',
    },
    {
      activity: 'Correctly Answered Questions',
      stat: '',
    },
    {
      activity: 'Incorrectly Answered Questions',
      stat: '',
    },
  ];

  const allContentsWatching = [
    {
      activity: 'Individual Videos Started',
      stat: allVideosWatched?.total_video ? allVideosWatched?.total_video : '',
    },
    {
      activity: 'Total Videos Started',
      stat: allVideosWatched?.total_video_open_count
        ? allVideosWatched?.total_video_open_count
        : '',
    },
    {
      activity: 'Individual Videos Completed',
      stat: '',
    },
    {
      activity: 'Total Videos Completed',
      stat: '',
    },
    {
      activity: 'Total Minutes Watching',
      stat: allVideosWatched?.total_video_watch_minutes
        ? allVideosWatched?.total_video_watch_minutes
        : '',
    },
    {
      activity: 'Correctly Answered Questions',
      stat: '',
    },
    {
      activity: 'Incorrectly Answered Questions',
      stat: '',
    },
  ];

  const [allContents, setAllContents] = useState(allContentsReading);
  const [allContentsFiltered, setAllContentsFiltered] =
    useState(allContentsReading);

  useEffect(() => {
    if (transcriptTab == 'reading') {
      setAllContents(allContentsReading);
      setAllContentsFiltered(allContentsReading);
    } else {
      setAllContents(allContentsWatching);
      setAllContentsFiltered(allContentsWatching);
    }
  }, [transcriptTab, allVideosWatched, allArticleRead]);

  useEffect(() => {
    let filteredItems = allContents.filter((item) =>
      item?.activity?.toLowerCase()?.includes(transcriptSearch?.toLowerCase())
    );
    setAllContentsFiltered(filteredItems);
  }, [transcriptSearch]);

  return (
    <div className={classNames.tableContainer}>
      <div className={classNames.headings}>
        <div>Activity</div>
        <div>All Time Stat</div>
        <div>Last 24H</div>
        <div>Last 30 Days</div>
        <div>Actions</div>
      </div>
      <div className={classNames.contentTable}>
        {allContentsFiltered?.length > 0 &&
          allContentsFiltered?.map((eachItem) => {
            return <EachTableContent {...eachItem} />;
          })}
      </div>
    </div>
  );
};

export default TableContainer;

const EachTableContent = ({
  activity,
  stat,
  amount,
  setSelectedReward,
  fieldd,
}) => {
  return (
    <div className={classNames.eachTableContent}>
      <div>{activity ? activity : ''}</div>
      <div>
        {activity?.includes('Total Minutes') && !stat
          ? '0.00'
          : stat
          ? stat
          : '0'}
      </div>
      <div>
        {activity?.includes('Total Minutes') && !amount
          ? '0.00'
          : amount
          ? amount
          : '0'}
      </div>
      <div>
        {activity?.includes('Total Minutes') && !amount
          ? '0.00'
          : amount
          ? amount
          : '0'}
      </div>
      <div>
        <div>Expand</div>
      </div>
    </div>
  );
};
