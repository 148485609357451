import React, { useEffect } from 'react';
import './settings.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import fulllogo from '../../static/images/settingsFullLogo.svg';
import search from '../../static/images/malls/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import editIcon from '../../static/images/icons/edit.svg';
import CreatePlanBContainer from '../../components/BuyBlocks';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import {
  DateSelectionDiv,
  InputDivs,
} from '../../components/BuyBlocks/components/CreatePlanB';
import { uploadImageFunc } from '../../utils/imageUpload';
import uploading from '../../static/images/uploading.svg';
import dummyProfile from '../../static/images/dummyProfile.svg';
import uploadIcon from '../../static/images/uploadicon.svg';
import Skeleton from 'react-loading-skeleton';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { InputDropdown } from '../../components/BuyBlocks/components/CreatePlanB';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import { getAppByCode } from '../../queryHooks';
import coverPic from '../../static/images/settings/cover.svg';

const SettingsPage = () => {
  const { email } = useContext(BankContext);
  const {
    appCode,
    settingsTab,
    setSettingsTab,
    allOpportunities,
    filteredallOpportunities,
    settFilteredallOpportunities,
    setfiltersearch,
    settingsProfile,
    setSettingsProfile,
    settingsProfileCover,
    setSettingsProfileCover,
    authenticated2FA,
    setAuthenticated2FA,
    changePassword,
    setChangePassword,
    settingsProfileData,
    //settings
    settingsUserType,
    setSettingsUserType,
    settingsCountryCode,
    setSettingsCountryCode,
    settingsUsername,
    setSettingsUsername,
    setSettingsFirstName,
    settingsFirstName,
    setSettingsLastName,
    settingsLastName,
    settingsCountry,
    setSettingsCountry,
    settingsAddress,
    setSettingsAddress,
    settingsAge,
    setSettingsAge,
    settingsPhoneno,
    setSettingsPhoneno,
    settingsBio,
    setSettingsBio,
    allCountries,
    setAllCountries,
    allUserTypes,
    allCountryCode,
    setSettingsUserDropdown,
    settingsCountryCodeDropdown,
    setSettingsCountryCodeDropdown,
    settingsUserTypeDropdown,
    createProfile,
    setCreateProfile,
    //settings profile data
    settingsCountryDropdown,
    setSettingsCountryDropdown,
    createdProfileWithPublication,
    setCreatedProfileWithPublication,

    UserIdWeb3TodayAccount,
    //create profile api
    createProfileSettings,
    loadingSettings,
  } = useAppContextDetails();
  const {
    insideplanBCreate,
    setinsideplanBCreate,
    aiIndexDefaultSelected,
    setaiIndexDefaultSelected,
  } = useContext(planBContext);

  const [appIcon, setAppIcon] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const [userHasProfile, setUserHasProfile] = useState(false);

  useEffect(async () => {
    let response = await axios.get(
      `https://publications.apimachine.com/userprofile?email=${email}`
    );
    if (response?.data?.data?.length > 0) {
      setUserProfile(response?.data?.data[0]);
      setUserHasProfile(response?.data?.status);
      console.log(response?.data, 'response settings');
    }
  }, [email]);

  useEffect(() => {
    getAppByCode(appCode).then((response) => {
      const logo = response?.app_icon;
      // console.log(color, logo, 'ljqwehflk3wbefljk3bwef');
      setAppIcon(logo);
    });
  }, [appCode]);

  return (
    <Layout active="settings" className="settings" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="settings-navbar">
          <div className="settings-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-settings">
            {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div className="temp-alignment-div"></div>
          {/* <div
            className="settings-listBtn"
            onClick={() => {
              setinsideplanBCreate(!insideplanBCreate);
              // setSelectedDropDown(!selectedDropDown);
            }}
          >
            List Your Product
          </div> */}
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '15%' }}>
            <div
              className="each-option"
              style={{
                fontWeight: settingsTab === 'Profile' ? '600' : '',
              }}
              onClick={() => setSettingsTab('Profile')}
            >
              <div>
                <p>Profile</p>
              </div>
            </div>
          </div>
        </div>
        {createProfile ? (
          <div className="complete-profile-container">
            {createdProfileWithPublication ? (
              <div className="complete-profile-successful">
                You Have Successfully Completed Your Profile Registration
              </div>
            ) : (
              <div className="complete-profile">
                <div className="complete-profile-image-parent">
                  <ImageUploadDiv
                    setFunc={setSettingsProfileCover}
                    funcValue={settingsProfileCover}
                    type="cover"
                  />
                  <ImageUploadDiv
                    setFunc={setSettingsProfile}
                    funcValue={settingsProfile}
                    type="completeregistration"
                  />
                </div>
                <div className="details-container-register">
                  <InputDivs
                    heading="What is your first name?"
                    placeholderText="First Name..."
                    setFunc={setSettingsFirstName}
                    funcValue={settingsFirstName}
                    type="profileregister"
                  />
                  <InputDivs
                    heading="What is your last name?"
                    placeholderText="Last Name..."
                    setFunc={setSettingsLastName}
                    funcValue={settingsLastName}
                    type="profileregister"
                  />
                  <InputDivs
                    heading="Create a username"
                    placeholderText="Username..."
                    setFunc={setSettingsUsername}
                    funcValue={settingsUsername}
                    type="profileregister"
                  />
                  <InputDivs
                    heading="Create a bio"
                    placeholderText="Tell Us About Yourself..."
                    setFunc={setSettingsBio}
                    funcValue={settingsBio}
                    type="profileregister"
                  />
                  <InputDivs
                    heading="How old are you?"
                    placeholderText="Age..."
                    setFunc={setSettingsAge}
                    funcValue={settingsAge}
                    type="profileregister"
                  />
                  <InputDropdown
                    heading="What country do you live in?"
                    mapValue={allCountries}
                    setFunc={setSettingsCountry}
                    funcValue={settingsCountry}
                    dropdown={settingsCountryDropdown}
                    dropdownFunc={setSettingsCountryDropdown}
                    type="profileregister"
                  />
                  <InputDivs
                    heading="What is your address?"
                    placeholderText="Address..."
                    setFunc={setSettingsAddress}
                    funcValue={settingsAddress}
                    type="profileregister"
                  />
                  <div className="phoneNumberContainer">
                    <InputDropdown
                      heading="Enter your phone number"
                      mapValue={allCountryCode}
                      setFunc={setSettingsCountryCode}
                      funcValue={settingsCountryCode}
                      dropdown={settingsCountryCodeDropdown}
                      dropdownFunc={setSettingsCountryCodeDropdown}
                      type="profileregister"
                    />
                    <InputDivs
                      heading="hide"
                      placeholderText="000-000-0000"
                      setFunc={setSettingsPhoneno}
                      funcValue={settingsPhoneno}
                      type="profileregister"
                    />
                  </div>
                  <InputDropdown
                    heading="What type of user are you?"
                    mapValue={allUserTypes}
                    setFunc={setSettingsUserType}
                    funcValue={settingsUserType}
                    dropdown={settingsUserTypeDropdown}
                    dropdownFunc={setSettingsUserDropdown}
                  />
                  <div
                    className="settingsProfileBtn"
                    onClick={createProfileSettings}
                  >
                    Complete Profile
                  </div>
                </div>
              </div>
            )}
            <div
              className="overlay-complete-register"
              onClick={() => setCreateProfile(false)}
            ></div>
          </div>
        ) : (
          ''
        )}
        {changePassword ? <SettingsSidebar /> : ''}
        {!userHasProfile ? (
          <div className="completeRegistrationForm">
            <div
              className="completeRegistrationBtn"
              onClick={() => setCreateProfile(true)}
            >
              Complete Your Profile
            </div>
          </div>
        ) : settingsTab == 'Profile' ? (
          <div className="settings-content">
            <div className="left-ai-container">
              <div className="left-ai-div">
                <div className="image-section">
                  <img src={userProfile ? userProfile?.cover_pic : ''} alt="" />
                  <ImageUploadDiv
                    setFunc={setSettingsProfile}
                    funcValue={
                      userProfile ? userProfile?.profile_pic : settingsProfile
                    }
                    type="editable"
                  />
                </div>
                <div className="split-divs">
                  {/* <InputDropdown
                    heading="User Type"
                    mapValue={allUserTypes}
                    setFunc={setSettingsUserType}
                    funcValue={settingsUserType}
                    dropdown={settingsUserTypeDropdown}
                    dropdownFunc={setSettingsUserDropdown}
                    type="editable"
                  /> */}
                  <InputDivs
                    heading="Email"
                    placeholderText="enter email"
                    // setFunc={setSettingsUsername}
                    funcValue={
                      userProfile ? userProfile?.email : settingsUsername
                    }
                  />
                  <InputDivs
                    heading="Username"
                    placeholderText="Username"
                    // setFunc={setSettingsUsername}
                    funcValue={
                      userProfile ? userProfile?.username : settingsUsername
                    }
                    type="editable"
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="First Name"
                    placeholderText="First Name"
                    // setFunc={setSettingsFirstName}
                    funcValue={
                      userProfile ? userProfile?.first_name : settingsFirstName
                    }
                    type="editable"
                  />
                  <InputDivs
                    heading="Last Name"
                    placeholderText="Last Name"
                    // setFunc={setSettingsLastName}
                    funcValue={
                      userProfile ? userProfile?.last_name : settingsLastName
                    }
                    type="editable"
                  />
                </div>
                <div className="split-divs">
                  {/* <InputDivs
                    heading="Country"
                    placeholderText="Country"
                    setFunc={setSettingsCountry}
                    funcValue={settingsCountry}
                  /> */}
                  <InputDropdown
                    heading="Country"
                    mapValue={allCountries}
                    // setFunc={setSettingsCountry}
                    funcValue={
                      userProfile ? userProfile?.country : settingsCountry
                    }
                    // dropdown={settingsCountryDropdown}
                    // dropdownFunc={setSettingsCountryDropdown}
                    type="editable"
                  />
                  <InputDivs
                    heading="Address"
                    placeholderText="Address"
                    // setFunc={setSettingsAddress}
                    funcValue={
                      userProfile ? userProfile?.address : settingsAddress
                    }
                    type="editable"
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="Age"
                    placeholderText="Age"
                    // setFunc={setSettingsAge}
                    funcValue={userProfile ? userProfile?.age : settingsAge}
                    type="editable"
                  />
                  <InputDivs
                    heading="Phone Number"
                    placeholderText="000-000-0000"
                    // setFunc={setSettingsPhoneno}
                    funcValue={
                      userProfile ? userProfile?.phoneNumber : settingsPhoneno
                    }
                    type="editable"
                  />
                </div>
                <InputDivs
                  heading="Bio"
                  placeholderText="Tell Us About Yourself.."
                  // setFunc={setSettingsBio}
                  funcValue={userProfile ? userProfile?.bio : settingsBio}
                  type="editable"
                />
                <div style={{ width: '45%' }}>
                  <InputDropdown
                    heading="User Type"
                    mapValue={allUserTypes}
                    // setFunc={setSettingsUserType}
                    funcValue={
                      userProfile ? userProfile?.user_type : settingsUserType
                    }
                    // dropdown={settingsUserTypeDropdown}
                    // dropdownFunc={setSettingsUserDropdown}
                  />
                </div>
                {/* <div className="dateDiv">
                  <DateSelectionDiv heading="Birthday" />
                </div> */}
                <div
                  className="settingsProfileBtn"
                  // onClick={createProfileSettings}
                  style={{
                    display: 'none',
                    pointerEvents: UserIdWeb3TodayAccount ? 'none' : '',
                  }}
                >
                  Complete Registration
                </div>
              </div>
            </div>
            <div className="right-ai-container">
              {/* <CreatePlanBContainer /> */}
              <div className="change-pass">
                <div className="title">Change Password</div>
                <div className="para">
                  Click here to change your password. You will need to verify
                  your email again to reset your password.
                </div>
                <div
                  className="btn"
                  onClick={() => setChangePassword('changepass1')}
                >
                  Change Password
                </div>
              </div>
              <div className="change-pass">
                <div className="title">
                  {authenticated2FA?.mfa_enabled ? 'Disable 2FA' : 'Enable 2FA'}
                </div>
                <div className="para">
                  {authenticated2FA?.mfa_enabled
                    ? 'You have already secured your account. Click here if you want to disable the 2FA from Google Authenticator.'
                    : 'For an additional layer of security you can enable 2 factor authentication via Google Authenticator.'}
                </div>
                <div
                  className="btn"
                  onClick={() => {
                    if (authenticated2FA?.mfa_enabled) {
                      setChangePassword('twofactorDisable1');
                    } else if (authenticated2FA?.mfa_enabled == false) {
                      setChangePassword('twofactorEnable1');
                    } else {
                    }
                  }}
                  style={{
                    display:
                      authenticated2FA?.mfa_enabled == true ||
                      authenticated2FA?.mfa_enabled == false
                        ? ''
                        : 'none',
                  }}
                >
                  {authenticated2FA?.mfa_enabled == true
                    ? 'Disable'
                    : authenticated2FA?.mfa_enabled == false
                    ? 'Enable'
                    : ''}
                </div>
              </div>
            </div>
          </div>
        ) : settingsTab == 'Identification' ? (
          <div className="settings-content">
            <div className="left-ai-container">
              <div className="left-ai-div">
                {/* <div className="heading">Basic Details</div>
                <ImageUploadDiv
                  setFunc={setSettingsProfile}
                  funcValue={settingsProfile}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="Email"
                    placeholderText="Email"
                    setFunc={setSettingsEmail}
                    funcValue={settingsEmail}
                  />
                  <InputDivs
                    heading="Username"
                    placeholderText="Username"
                    setFunc={setSettingsUsername}
                    funcValue={settingsUsername}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="First Name"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="First Name"
                    placeholderText="Username"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="Upline"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Phone Number"
                    placeholderText="Phone Number"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="dateDiv">
                  <DateSelectionDiv heading="Birthday" />
                </div> */}
                <div className="heading">Address Details</div>
                <div className="split-divs">
                  {/* <InputDivs
                    heading="Country"
                    placeholderText="Country"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  /> */}

                  <InputDropdown
                    heading="Country"
                    mapValue={allCountries}
                    setFunc={setSettingsCountry}
                    funcValue={settingsCountry}
                    dropdown={settingsCountryDropdown}
                    dropdownFunc={setSettingsCountryDropdown}
                  />
                  <InputDivs
                    heading="State"
                    placeholderText="State"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <InputDivs
                  heading="Address "
                  placeholderText="Address "
                  // setFunc={setaidropdownRegLink}
                  // funcValue={aidropdownRegLink}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="City"
                    placeholderText="City"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Postal code"
                    placeholderText="Postal code"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
              </div>
            </div>
            <div className="right-ai-container">
              {/* <CreatePlanBContainer /> */}
              <div className="change-pass">
                <div className="title">Perform KYC</div>
                <div className="para">
                  If you plan on funding your forex Vaults in Web3Today you have
                  to complete the KYC for our exchange partners.
                </div>
                <div
                  className="btn"
                  onClick={() =>
                    window.open(
                      'https://in.sumsub.com/idensic/l/#/sbx_uni_2hSenyE0Msz9cyjI',
                      '_blank'
                    )
                  }
                >
                  Sending CAD
                </div>
                <div
                  className="btn"
                  style={{ pointerEvents: 'none', opacity: '0.5' }}
                >
                  Sending EUR, GBP & USD
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ position: 'relative', width: '100%', height: '88%' }}>
            <div className="malls">
              <div className="malls-content">
                <div
                  style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                >
                  <Subscription />
                </div>
              </div>
            </div>
          </div>
        )}

        {loadingSettings ? (
          <div
            className="loadingIcon"
            style={{ background: 'rgb(0, 0, 0, 0.5)' }}
          >
            <LoadingAnimation icon={appIcon} width={200} />
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default SettingsPage;

export const ImageUploadDiv = ({ heading, setFunc, funcValue, type }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div
      className={`imageUploadDiv ${
        type == 'completeregistration' ? 'completeregistrationProfile' : ''
      }`}
      onClick={() => setSelectedDropDown('')}
    >
      <div className="heading">{heading}</div>
      <label
        htmlFor={'profileUpdateImgPlanB' + type}
        style={{ width: type == 'cover' ? '100%' : '' }}
      >
        <div
          className={`imageDiv ${
            type == 'completeregistration' ? 'imageDivCM' : ''
          }`}
          style={{
            margin: type == 'cover' ? '0' : '',
            borderBottom: type == 'cover' ? '0.5px solid #E7E7E7' : '',
            height: type == 'cover' ? '200px' : '',
            borderRadius: type == 'cover' ? '0' : '',
          }}
        >
          <img
            src={
              funcValue
                ? funcValue
                : type == 'completeregistration' || type == 'cover'
                ? uploadIcon
                : dummyProfile
            }
            alt="planBAccountPic"
            className={`profileImg 
              ${type == 'completeregistration' ? 'profileImgCM' : ''}`}
            htmlFor={'profileUpdateImgPlanB' + type}
            style={{
              height: type == 'cover' ? '35%' : '',
              objectFit: type == 'cover' ? 'contain' : '',
              borderRadius: type == 'cover' ? '0' : '',
            }}
          />
          {planBAccountPicUploading ? (
            <div className="overlayDiv">
              <img src={uploading} alt="uploading" className="uploadingimg" />
            </div>
          ) : (
            ''
          )}
          {type == 'editable' && (
            <div className="editIcon">
              <img src={editIcon} alt="editIcon" />
            </div>
          )}
        </div>
      </label>
      <label htmlFor={'profileUpdateImgPlanB' + type} className="uploadFileDiv">
        <input
          className="uploadNewPicPlanB"
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id={'profileUpdateImgPlanB' + type}
        />
        <div
          style={{
            display:
              type == 'editable' ||
              type == 'completeregistration' ||
              type == 'cover'
                ? 'none'
                : '',
          }}
        >
          {planBAccountPicUploading ? 'Uploading...' : 'Upload Profile Photo'}
        </div>
      </label>
    </div>
  );
};

const SettingsSidebar = () => {
  const {
    changePassword,
    setChangePassword,
    contentData,
    setContentData,
    changePasswordPin,
    setChangePasswordPin,
    changePasswordPass,
    setChangePasswordPass,
    changePasswordCnfmPass,
    setChangePasswordCnfmPass,
    changePasswordLoading,
    setChangePasswordLoading,
    message,
    setMessage,
    qrData2FA,
    setqrData2FA,
    qr2FAPin,
    setqr2FAPin,
    refreshSettings,
    setrefreshSettings,
  } = useAppContextDetails();

  const { email, accessToken } = useContext(BankContext);

  function nextStepChangePass() {
    setMessage('');
    switch (changePassword) {
      case 'changepass1':
        initiatePassReset();
        break;
      case 'changepass2':
        setChangePassword('changepass3');
        break;
      case 'changepass3':
        setChangePassword('changepass4');
        break;
      case 'changepass4':
        cnfmPassword();
        break;
      case 'changepass5':
        setChangePassword('');
        break;
      case 'changepassfailed':
        setChangePassword('');
        break;
      case 'twofactorEnable1':
        initiateEnable2FA();
        break;
      case 'twofactorEnable2':
        setChangePassword('twofactorEnable3');
        break;
      case 'twofactorEnable3':
        verify2FA();
        break;
      case 'twofactorEnable4':
        setChangePassword('');
        break;
      case 'twofactorDisable1':
        disable2FA();
        break;
      case 'twofactorDisable2':
        setChangePassword('');
        break;
      default:
        setChangePassword('');
        break;
    }
  }

  function initiateEnable2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/set', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken web3today'),
        client_app: 'web3today',
      })
      .then((response) => {
        if (response?.data?.status) {
          setqrData2FA(response?.data);
          setChangePassword('twofactorEnable2');
        } else {
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'initiateEnable2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
      });
  }
  function verify2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/set/verify', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken web3today'),
        code: qr2FAPin ? qr2FAPin : '',
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('twofactorEnable4');
        } else {
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
        setrefreshSettings('2FA enabled');
      })
      .catch((error) => {
        console.log(error?.message, 'verify2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
        setrefreshSettings('2FA enabling failed');
      });
  }
  function disable2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/disable', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken web3today'),
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('twofactorDisable2');
        } else {
          setChangePassword('twofactorDisableFailed');
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
        setrefreshSettings('2FA disable');
      })
      .catch((error) => {
        console.log(error?.message, 'verify2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
        setrefreshSettings('2FA disable');
      });
  }

  function cnfmPassword() {
    if (changePasswordPass == changePasswordCnfmPass) {
      changePasswordFunc();
    } else {
      setMessage("Passwords don't match...");
    }
  }

  function changePasswordFunc() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/password/forgot/confirm', {
        email: email,
        code: changePasswordPin,
        newPassword: changePasswordPass,
      })
      .then((response) => {
        setMessage(response?.data?.message);
        if (response?.data?.status) {
          setChangePassword('changepass5');
        } else {
          setChangePassword('changepassfailed');
        }
        setChangePasswordLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'password change failed');
        setChangePassword('changepassfailed');
        setChangePasswordLoading(false);
      });
  }

  function initiatePassReset() {
    setChangePasswordLoading(true);

    axios
      .post('https://gxauth.apimachine.com/gx/user/password/forgot/request', {
        email: email,
        app_code: 'web3today',
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('changepass2');
          setChangePasswordLoading(false);
        } else {
          setMessage(response?.data?.message);
          setChangePasswordLoading(false);
        }
      })
      .catch((error) => {
        console.log(error?.message, 'Initiate forget password failed');
        setChangePasswordLoading(false);
      });
  }

  return (
    <div className="settingsSidebar">
      <div
        onClick={() => {
          // if (changePassword?.includes('changepass')) {
          //   setChangePassword('');
          // }else if (authenticated2FA?.mfa_enabled) {
          // }else{

          // }
          setChangePassword('');
        }}
      ></div>
      <div className="sidebarContainer">
        <div className="contentDiv">
          <div className="title">{contentData ? contentData?.name : ''}</div>
          <div className="content">
            {changePassword == 'changepassfailed' && message
              ? message
              : changePassword == 'twofactorEnable4' && message
              ? message
              : changePassword == 'twofactorDisableFailed' && message
              ? message
              : contentData
              ? contentData?.para
              : ''}
          </div>
          {contentData?.inputDiv == 'pin' ? (
            <OtpInput
              value={
                contentData?.name == 'Enable 2FA' ? qr2FAPin : changePasswordPin
              }
              onChange={
                contentData?.name == 'Enable 2FA'
                  ? setqr2FAPin
                  : setChangePasswordPin
              }
              numInputs={6}
              separator={<span> </span>}
              shouldAutoFocus
              containerStyle="otpInputWrapper"
              inputStyle="otpInput"
            />
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'password' ? (
            <div className="password">
              <input
                placeholder="Enter new password..."
                type="password"
                value={changePasswordPass}
                onChange={(event) =>
                  setChangePasswordPass(event?.target?.value)
                }
              />
            </div>
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'cnfmpassword' ? (
            <div className="password">
              <input
                placeholder="Enter confirm password..."
                type="password"
                value={changePasswordCnfmPass}
                onChange={(event) =>
                  setChangePasswordCnfmPass(event?.target?.value)
                }
              />
            </div>
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'qrdiv' ? (
            <div className="qRContainer">
              {qrData2FA && (
                <div className="qRsingleDiv">
                  <QRCode
                    className="qr-svg"
                    value={qrData2FA ? qrData2FA?.qrcodeValue : ''}
                    renderAs="svg"
                    size={300}
                  />
                </div>
              )}
              <div className="seperateBorder">OR</div>
              <div className="content">
                Enter this secret code into your Google Auth App.
              </div>
              <div className="qrcodes">
                {qrData2FA ? qrData2FA?.SecretCode : ''}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {message &&
          changePassword !== 'changepassfailed' &&
          changePassword !== 'changepass5' &&
          changePassword !== 'twofactorDisableFailed' &&
          changePassword !== 'twofactorEnable4' && (
            <div className="messageDiv">
              {message ? 'Note : ' + message : ''}
            </div>
          )}
        <div className="btn">
          {contentData?.btn1 && (
            <div
              onClick={nextStepChangePass}
              style={{
                pointerEvents: changePasswordLoading
                  ? 'none'
                  : contentData?.inputDiv !== 'pin'
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    changePasswordPin?.toString()?.split('')?.length >= 6
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    qr2FAPin?.toString()?.split('')?.length >= 6
                  ? ''
                  : 'none',
                opacity: changePasswordLoading
                  ? 'none'
                  : contentData?.inputDiv !== 'pin'
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    changePasswordPin?.toString()?.split('')?.length >= 6
                  ? '1'
                  : contentData?.inputDiv == 'pin' &&
                    qr2FAPin?.toString()?.split('')?.length >= 6
                  ? '1'
                  : '0.7',
              }}
              className="topBtn"
            >
              <div>{contentData ? contentData?.btn1 : ''}</div>
              {changePasswordLoading && (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          )}
          <div
            onClick={() => (contentData ? setChangePassword('') : '')}
            className="bottomBtn"
          >
            {contentData ? contentData?.btn2 : ''}
          </div>
        </div>
      </div>
    </div>
  );
};
