import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
// import fulllogo from '../../assets/images/player.svg';
import fulllogo from '../../static/images/sidebarFullLogos/transcript.svg';
import searchIcon from '../../static/images/search.svg';
import Trader from './Trader';
import web from '../../assets/images/web.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { Link, useHistory } from 'react-router-dom';
import Points from './Points';
import './Player.scss';
import Ads from './Ads';
import { BankContext } from '../../context/Context';
import TableContainer from '../../components/Transcript/TableContainer';
import axios from 'axios';

export default function Index() {
  const { academytab, setAcademytab, setTranscriptTab, UserIdWeb3TodayAccount, tabName, settabName } =
    useAppContextDetails();
  const history = useHistory();

  const {
    email,
    web3UserId,
    setAllArticleRead,
    setAllVideosWatched,
    transcriptSearch,
    setTranscriptSearch,
  } = useContext(BankContext);
  const [playertoogle, setplayertoogle] = useState(false);
  const [tablay, settablay] = useState('default');
  const [pointsDropdown, setpointsDropdown] = useState(false);
  const callFunction = () => {
    setplayertoogle(true);
    setTimeout(function () {
      setplayertoogle(false);
    }, 2000);
  };

  useEffect(() => {
    console.log(typeof localStorage.getItem("web3Account") , "PlayerPage")
    if (localStorage.getItem("web3Account") === "false") {
      history.push('/settings')
    }
    // console.log(web3UserId, 'web3UserId');
    if (web3UserId) {
      // console.log('working inside', 'web3UserId');
      axios
        .get(
          `https://publications.apimachine.com/action/user?user_id=${web3UserId ? web3UserId : ''
          }`
        )
        .then((response) => {
          // console.log(response?.data?.data, 'all article read');
          setAllArticleRead(response?.data?.data);
        })
        .catch((error) => {
          console.log(error?.message, 'all article read error');
        });
      axios
        .get(
          `https://publications.apimachine.com/videoaction/user?user_id=${web3UserId ? web3UserId : ''
          }`
        )
        .then((response) => {
          // console.log(response?.data?.data, 'all videos watched');
          setAllVideosWatched(response?.data?.data);
        })
        .catch((error) => {
          console.log(error?.message, 'all article read error');
        });
    }
  }, [email, web3UserId]);

  return (
    <>
      <Layout active="Play" className="player" hideFooter>
        <div style={{ width: '100%', height: '100%' }}>
          <div className="player-navbar">
            {/* <div>
            <img src={img1} alt="" />
          </div> */}
            <div className="player-inner">
              <div className="academy-icon">
                <img src={fulllogo} alt="" />
              </div>
              <div className="search-academy">
                {/* <div className="all">
              <p>All Videos</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
                <div className="im">
                  <input type="text" placeholder="Im Looking For..." />
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div
                className="spendDetail"
                onClick={() => setpointsDropdown(true)}
              >
                Convert
              </div>
            </div>
          </div>
          <div className="w-space">
            <div className="optionss" style={{ width: '30%' }}>
              <div
                className={`each-option
              
              
              ${tabName == 'Points' && 'each-option-selected'}`}
                onClick={() => {
                  settabName('Points');
                  settablay('default');
                }}
              >
                <div>
                  <p>Dashboard</p>
                </div>
              </div>
              <div
                className={`each-option
                ${tabName == 'Reading' && 'each-option-selected'}`}
                onClick={() => {
                  settabName('Reading');
                  settablay('Reading');
                  setTranscriptSearch('');
                  setTranscriptTab('reading');
                }}
              >
                <div>
                  <p>Reading</p>
                </div>
              </div>
              <div
                className={`each-option
                ${tabName == 'Watching' && 'each-option-selected'}`}
                onClick={() => {
                  settabName('Watching');
                  settablay('Watching');
                  setTranscriptSearch('');
                  setTranscriptTab('watching');
                }}
              >
                <div>
                  <p>Watching</p>
                </div>
              </div>
              {/* <div
                className={`each-option
              
              
                ${tabName == 'Trades' && 'each-option-selected'}`}
                onClick={() => settabName('Trades')}
              >
                <div>
                  <p>Trades</p>
                </div>
              </div> */}
              {/* <div
              className={`each-option`}

  
            >
              <div>
                <p>Airdrops</p>
              </div>
            </div> */}
            </div>
            <div className={`each-option`} onClick={() => settabName('Trades')}>
              <div>
                <p>Trades</p>
              </div>
            </div>
          </div>
          {tabName == 'Reading' || tabName == 'Watching' ? (
            <TableContainer />
          ) : tabName == 'Points' ? (
            <Points
              callFunction={callFunction}
              setpointsDropdown={setpointsDropdown}
              pointsDropdown={pointsDropdown}
              settablay={settablay}
              tablay={tablay}
            />
          ) : (
            ''
          )}
          {/* */}
          {tabName == 'Ads' && <Ads />}
          {tabName == 'Trades' && <Trader />}
        </div>
      </Layout>
      {playertoogle ? (
        <div className="modelData">
          <div className="innermodel">
            <img src={web} />
            <h5>This Feature Is Coming Soon</h5>
            <br/>
            <p>This popup will close in 2 seconds</p>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
